<template>
  <div class="row justify-content-center earningsummary" :class="isMobile ? 'rNApp pt-5' : ''">
    <div class="col-md-6 cont" v-if="staff">
      <div class="row top">
        <div class="col-12">
          <hr />
          <p class="title">Total Earnings</p>
          <p class="total">
            <span class="dollar">$</span>{{ staff.statistic.paid_total }}
          </p>
          <hr class="mt-0" />
          <div class="chart">
            <div class="row">
              <div class="col-3">
                <p
                  @click="changeDate(-7, 'day')"
                  class="period"
                  :class="{ active: isPeriodActive(-7, 'day') }"
                >
                  1w
                </p>
              </div>
              <div class="col-3">
                <p
                  @click="changeDate(-1, 'month')"
                  class="period"
                  :class="{ active: isPeriodActive(-1, 'month') }"
                >
                  1m
                </p>
              </div>
              <div class="col-3">
                <p
                  @click="changeDate(-6, 'month')"
                  class="period"
                  :class="{ active: isPeriodActive(-6, 'month') }"
                >
                  6m
                </p>
              </div>
              <div class="col-3">
                <p
                  @click="changeDate(-1, 'year')"
                  class="period"
                  :class="{ active: isPeriodActive(-1, 'year') }"
                >
                  1y
                </p>
              </div>
            </div>
            <GChart
              v-if="incomes && Object.keys(incomes).length > 1"
              type="LineChart"
              :data="incomes"
              :options="chartOptions"
            />
            <b-alert v-else show variant="danger"
              >There are no incomes yet</b-alert
            >
          </div>
        </div>
      </div>
      <div class="earnings">
        <p class="title">Current Balance</p>
        <p class="value">
          <span class="dollar">$</span>{{ staff.wallet.balance }}
        </p>
      </div>
      <div class="text-center">
        <button
          v-if="staff.wallet.balance > 0"
          @click="transfer"
          class="btn btn-primary black"
        >
          Transfer to Bank
        </button>
      </div>
      <p class="faqp">
        Have a problem with your account?<br />
        Tap
        <a href="#" @click.prevent="" v-b-modal.modal-send-support>here</a> for
        our FAQ and contact information.
      </p>
    </div>
    <b-modal
      id="modal-send-support"
      title="Contact us"
      @show="resetModal"
      @ok="handleOk"
      @hidden="resetModal"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row>
          <b-col>
            <b-form-input
              id="input-1"
              v-model="form.first_name"
              type="text"
              placeholder="FirstName"
              required
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              id="input-2"
              v-model="form.last_name"
              type="text"
              placeholder="LastName"
              required
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-input
              id="input-3"
              v-model="form.email"
              type="email"
              placeholder="Enter your Email"
              required
              class="mt-3"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-textarea
              class="mt-3"
              id="textarea"
              v-model="form.query"
              placeholder="Drop your Query"
              rows="4"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { ServerErrors } from "@/mixins/ServerErrors";
import { eventBus } from "@/main";
import { GChart } from "vue-google-charts";

export default {
  name: "Wallet",
  mixins: [ServerErrors],
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        query: "",
      },
      errors: [],
      staff: null,
      incomes: null,
      period: {
        amount: -7,
        unit: "day",
      },
      chartOptions: {
        fontSize: 13,
        legend: { position: "none" },
        chartArea: {
          left: 0,
          right: 0,
          top: "10px",
          width: "100%",
          height: "80%",
        },
        fontName: "Volte-Medium",
        pieSliceText: "none",
        colors: ["#fff"],
        backgroundColor: "#151618",
        vAxis: {
          format: "currency",
          textPosition: "none",
          title: "",
          gridlines: { color: "transparent" },
        },
        hAxis: { textStyle: { color: "#fff" } },
      },
    };
  },
  components: { GChart },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.form = {
        first_name: "",
        last_name: "",
        email: "",
        query: "",
      };
      this.$bvModal.hide("modal-send-support");
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.$store
        .dispatch("sendReport", this.form)
        .then((res) => {
          this.$bvModal.hide("modal-send-support");
          Swal.fire({
            title: "Your request has been submitted, our team will get back to you shortly",
            icon: "success",
          });
        })
        .catch((error) => {
          this.$bvModal.hide("modal-send-support");
        });
    },
    transfer() {
      if (!this.staff.wallet.has_transfer_data) {
        this.$router.push({ name: "staff.profile.transfer" });
        return;
      }
      let loader = this.$loading.show();
      this.errors = [];
      axios({
        method: "POST",
        url: "/staff/wallet/withdraw",
        data: {},
      })
        .then(() => {
          this.$store.dispatch("updateStaffData").then(() => {
            Swal.fire({
              title: "Requested",
              text: "",
              icon: "success",
            });
            this.staff = this.$store.state.staff;
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
    getIncomes() {
      let date = moment()
        .add(this.period.amount, this.period.unit)
        .format("YYYY-MM-DD");

      axios({
        method: "GET",
        url: "/staff/wallet/incomes?date=" + date,
      }).then((response) => {
        this.incomes = response.data.incomes;
      });
    },
    changeDate(amount, unit) {
      this.period.amount = amount;
      this.period.unit = unit;
      this.getIncomes();
    },
    isPeriodActive(amount, unit) {
      return amount === this.period.amount && unit === this.period.unit;
    },
  },
  beforeMount() {
    this.isMobile = this.$store.getters.isRNAppSession;
  },
  mounted() {
    this.staff = this.$store.state.staff;
    eventBus.$emit("changeTop", {
      title: "Earnings summary",
      simple: true,
      topLogo: false,
    });
    this.getIncomes();
  },
};
</script>