<template>
    <div class="row staffviewoffer">
        <div class="col-12">
            <div class="cont" :class="(isMobile ? 'offer-rNApp' : '')" v-if="offer && staff">
                <div class="container head" :class="(isMobile ? 'mt-3' : '')" v-if="this.isStarted">
                    <div class="row">
                        <div class="col-4">
                            <p class="title">Shift Timer</p>
                            <p class="subtitle">{{ formattedLeftToEnd }}</p>
                        </div>
                        <div class="col-4">
                            <p class="title">{{ !isEnded ? 'Next Break' : 'Final Pay' }}</p>
                            <p class="subtitle">{{ !isEnded ? this.formattedBreak : '$' + offer.cost }}</p>
                        </div>
                        <div class="col-4">
                            <p class="title">Status</p>
                            <p class="subtitle">{{ !isEnded ? 'Ongoing': 'Complete'}}</p>
                        </div>
                    </div>
                </div>
                <!-- <button @click="$router.push({name: 'staff.shift.offer.review', params: {id: offer.id}})" class="btn btn-primary blue review" v-if="shiftEndsInHours <= 1">Review</button> -->
                <button @click="$router.push({name: 'staff.shift.offer.review', params: {id: offer.id}})" class="btn btn-primary blue review" :class="(isMobile ? 'mt-3' : '')" v-if="isEnded">Review</button>
            </div>
            <search-staff-map
                v-if="offer && staff"
                :lat="offer.shift.restaurant.location.lat"
                :lng="offer.shift.restaurant.location.lng"
                :direction-coords="staff.location"
                :pulse="false"
                :reduceHeightByPixels="122"
                class="map"
            >
            </search-staff-map>
        </div>
        <div class="bottommanage" v-if="offer && staff">
            <a class="questions" v-if="!isEnded" @click="$router.push({name: 'staff.chat', params: {id: offer.shift.restaurant.id}})">Have any questions?</a>
            <div class="container whiteblock">
                <div class="row">
                    <div class="col-8">
                        <p class="type"><span v-for="industry in offer.shift.restaurant.industries">{{ industry + ' ' }}</span></p>
                    </div>
                    <div class="col-4">
                        <p class="reviews">{{ offer.shift.restaurant.statistic.reviews_total }} reviews</p>
                    </div>
                    <div class="col-12">
                        <p class="title">{{ offer.shift.restaurant.name }}</p>
                        <!-- <p class="title">{{ offer.shift.restaurant.phone }}</p> -->
                    </div>
                </div>
            </div>
            <div class="container whiteblock">
                <div class="row">
                    <div class="col-6">
                        <p class="type">Location</p>
                    </div>
                    <div class="col-6">
                        <p class="desc" v-if="offer.shift.restaurant.location.distance">{{ offer.shift.restaurant.location.distance }} miles away</p>
                    </div>
                    <div class="col-12">
                        <p class="title">{{ offer.shift.restaurant.address }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Offer} from "@/mixins/Offer";
import {eventBus} from "@/main";
import SearchStaffMap from "@/components/Shift/SearchStaffMap";

export default {
    props: ['id'],
    data () {
        return {
            staff: null,
            isMobile: false
        }
    },
    components: {'search-staff-map': SearchStaffMap},
    mixins: [Offer],
    methods: {
        updateLayout() {
            eventBus.$emit('changeTop', {
                title: this.isStarted  ? ' ' : 'Your shift starts in ...',
                desc:  this.isStarted  ? ' ' : this.formattedLeftToStart,
                simple: false,
                topLogo: true
            });
            setTimeout(() => {
                this.now = moment();
                this.updateLayout();
            }, 60 * 1000)
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        this.staff = this.$store.state.staff;
        this.getOffer()
            .then(() => {
                this.updateLayout();
            })
    }
}
</script>