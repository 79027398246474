<template>
    <div class="avatar-up-div">
        <div class="img-div">
            <img v-if="userAvatar || photoInit" :src="userAvatar ? userAvatar : photoInit">
            <i v-else class="fa fa-user-circle" aria-hidden="true"></i>
        </div>
        <i id="pick-avatar" class="fa fa-camera" aria-hidden="true"></i>
        <avatar-cropper
            trigger="#pick-avatar"
            :upload-url="uploadUrl"
            :labels="buttonsLabels"
            :output-options="outputOptions"
            :cropper-options="cropperOptions"
            :upload-headers="headers"
            @uploading="handleUploading"
            @uploaded="handleUploaded"
            @error="handlerError"
        ></avatar-cropper>
    </div>
</template>

<script>
import {ServerErrors} from "@/mixins/ServerErrors";

export default {
    name: "AvatarInput",
    props: [
        'uploadUrl',
        'photoInit'
    ],
    mixins: [ServerErrors],
    data () {
        return {
            userAvatar: this.photoInit, //is not working
            loader: null,
            uploadError: null
        }
    },
    methods: {
        handleUploading(form, xhr) {
            this.loader = this.$loading.show();
        },
        handleUploaded(response, form, xhr) {
            this.userAvatar = response.file;
            this.loader.hide();
        },
        handlerError(message, type, xhr) {
            this.uploadError = message;
            this.loader.hide();
        },
    },
    computed: {
        headers() {
            return {'Authorization': 'Bearer ' + this.$store.state.user.access_token};
        },
        cropperOptions() {
            return {
                minCropBoxWidth: 200,
                aspectRatio: 1,
                movable: true,
                zoomable: true
            }
        },
        outputOptions() {
            return {width: 200, height: 200};
        },
        buttonsLabels() {
            return { 'submit': 'submit', 'cancel': 'cancel'};
        }
    }
}
</script>