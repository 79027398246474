<template>
    <div class="row offer" :class="(isMobile ? 'offer-rNApp' : '')">
        <div class="col-md-12">
            <div class="cont" v-if="offer">
                <div class="descrow row">
                    <div class="col-8">
                        <p class="title">Venue Description</p>
                    </div>
                    <div class="col-4">
                        <p class="reviews">{{ offer.shift.restaurant.statistic.reviews_total }} reviews</p>
                    </div>
                    <div class="col-12">
                        <p class="desc">{{ offer.shift.restaurant.name + '. ' + offer.shift.restaurant.address }}</p>
                    </div>
                </div>
                <hr/>
                <div class="descrow row">
                    <div class="col-7">
                        <p class="title">
                            <span v-for="industry in offer.shift.restaurant.industries">{{ industry + ' ' }}</span> -
                        </p>
                        <p class="desc">{{ offer.period.start_formatted }} ({{ offer.period.length}}hours)</p>
                    </div>
                    <div class="col-5 text-right">
                        <p class="title">${{ offer.cost }}</p>
                        <p class="desc" v-if="offer.shift.restaurant.location.distance">{{ offer.shift.restaurant.location.distance }} miles away</p>
                    </div>
                </div>
                <hr/>
                <div class="descrow row">
                    <div class="col-12">
                        <p class="title">Dress</p>
                        <p class="desc">{{ offer.shift.dress_code ? offer.shift.dress_code : '- -' }}</p>
                    </div>
                </div>
                <div class="row terms-signup mb-2" v-if="offer.status !== 'accepted' && offer.status !== 'acceptance_accepted'">
                    <div class="col-6">
                        <b-form-checkbox
                            v-model="terms"
                            :value="1"
                            :unchecked-value="0"
                        ><span>I agree to the</span></b-form-checkbox>
                    </div>
                    <div class="col-6">
                        <a href="#" v-b-modal.terms-modal><span>terms</span></a>
                    </div>
                    <div class="col-12" v-if="termsError && !terms">
                        <span role="alert" class="invalid-feedback"><strong><i class="fa fa-exclamation-circle"></i>You must read and agree to terms to proceed.</strong></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 text-center" v-if="offer.status !== 'accepted' && offer.status !== 'acceptance_accepted'">
                        <button class="btn btn-primary blue" @click="accept" >Accept Request</button>
                    </div>
                    <div class="col-12" v-if="offer.status === 'accepted'">
                        <p class="desc mb-0">Please wait for manager confirmation.</p>
                    </div>
                    <div class="col-6 text-center">
                        <button class="btn nobtn" @click="decline" v-if="(shiftStartsInHours > 1) || (offer.status !== 'accepted' && offer.status !== 'acceptance_accepted')">No Thanks</button>
                    </div>
                </div>
            </div>
            <b-modal id="terms-modal" class="terms-modal" size="lg" centered :hide-footer="true">
                <iframe src="/terms"></iframe>
            </b-modal>
            <search-staff-map
                v-if="offer"
                :lat="offer.shift.restaurant.location.lat"
                :lng="offer.shift.restaurant.location.lng"
                :pulse="false"
                class="map"
            >
            </search-staff-map>
        </div>
    </div>

</template>

<script>
import {ServerErrors} from "@/mixins/ServerErrors";
import {eventBus} from "@/main";
import SearchStaffMap from "@/components/Shift/SearchStaffMap";
import {Offer} from "@/mixins/Offer";

export default {
    name: "ViewInvite",
    props: ['id'],
    data () {
        return {
            terms: 0,
            termsError: false,
            isMobile: false
        }
    },
    components: {'search-staff-map': SearchStaffMap},
    mixins: [ServerErrors, Offer],
    methods: {
        accept() {
            if (!this.terms) {
                this.termsError = true;
                return;
            }
            let loader = this.$loading.show();
            axios
                .post('/shift/offer/' + this.id + '/accept')
                .then((response) => {
                    Swal.fire({
                        title: 'Accepted',
                        text: 'Please wait for manager confirmation.',
                        icon: 'success'
                    })
                    this.$set(this.offer, 'status', 'accepted');
                    this.$router.push({name: 'staff.home'});
                })
                .finally(() => {loader.hide()});
        },
        decline() {
            let loader = this.$loading.show();
            axios
                .post('/shift/offer/' + this.id + '/decline')
                .then((response) => {
                    Swal.fire({
                        title: 'Declined',
                        icon: 'success'
                    })
                    this.$router.push({name: 'staff.home'});
                })
                .finally(() => {loader.hide()});
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
        console.log(this.isMobile);
    },
    mounted() {
        eventBus.$emit('changeTop', {
            title: 'Let\'s find the perfect shift!',
            desc: 'You have 15 minutes to respond to the available shifts.',
            simple: true,
            topLogo: true
        });
        this.getOffer()
            .then(() => {
                if (this.offer.status === "acceptance_accepted") {
                    this.$router.push({name: 'staff.shift.offer.details'});
                }
            })
    }
}
</script>