<template>
  <div class="mainback">
    <div v-if="!isMobile">
      <b-navbar
        toggleable
        class="mainmenu"
        :class="{ long: title === 'Let\'s find the perfect fit.' }"
        v-if="loaded && !simple"
      >
        <b-navbar-toggle
          target="navbar-toggle-collapse"
          :class="{ 'w-30': expanded }"
          @click="expanded = !expanded"
        >
          <template #default="{ expanded }">
            <b-icon v-if="expanded" icon="x"></b-icon>
            <i class="fa fa-bars" v-else></i>
          </template>
        </b-navbar-toggle>
        <div class="maintop">
          <p class="address" :class="{ 'mt-0': title === '' }">
            {{ restaurant ? restaurant.name : "" }}
            <i class="fa fa-map-marker"></i>
          </p>
          <p class="name">
            {{
              title !== null
                ? title
                : "Good " +
                  greeting +
                  (restaurateur.name.first
                    ? ", " + restaurateur.name.first
                    : "") +
                  "."
            }}
          </p>
          <p class="desc">{{ desc !== null ? desc : "How can we serve you?" }}</p>
          <div class="img" v-if="logo">
            <img
              class="avatar"
              :src="'https://boonb.s3.amazonaws.com/ubar/ubarlogo.png'"
              @click="toMain()"
            />
          </div>
        </div>

        <b-collapse id="navbar-toggle-collapse" is-nav>
          <div class="top">
            <img
              v-if="restaurateur.photo"
              class="avatar"
              :src="restaurateur.photo"
              @click="toMain()"
            />
            <div v-else class="img">
              <img
                class="avatar"
                :src="'https://boonb.s3.amazonaws.com/ubar/ubarlogo.png'"
                @click="toMain()"
              />
            </div>
            <p class="name">
              {{
                restaurateur.name.first
                  ? restaurateur.name.first + " " + restaurateur.name.last
                  : ""
              }}
            </p>
            <p class="desc">{{ restaurant ? restaurant.name : "" }}</p>
            <b-link
              v-if="
                userData &&
                userData.totalRoles &&
                userData.totalRoles.length === 1
              "
              v-b-modal.modal-add-staff
              class="nav-link grey fs-5"
              >Add Account as Staff?</b-link
            >
            <b-link
              v-if="
                userData &&
                userData.totalRoles &&
                userData.totalRoles.length === 2
              "
              class="nav-link grey fs-5"
              @click="switchAccount"
              >Switch Account as Staff?</b-link
            >
            <hr />
            <div class="row">
              <div class="col-6">
                <star-rating
                  :read-only="true"
                  :show-rating="false"
                  active-color="#0070ff"
                  :star-size="21"
                  :rating="restaurant ? restaurant.statistic.average_stars : 0"
                  :star-points="[
                    23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                    46, 19, 31, 17,
                  ]"
                  :padding="5"
                ></star-rating>
              </div>
              <div class="col-6">
                <p class="ratingval">
                  {{ restaurant ? restaurant.statistic.reviews_total : 0 }} total
                  reviews
                </p>
              </div>
            </div>
            <hr />
          </div>
          <b-navbar-nav class="ml-auto">
            <li class="nav-item">
              <router-link
                :to="{ name: 'restaurant.shift.create' }"
                v-if="isAllowed"
                class="nav-link -inverse"
                ><i class="fa fa-search"></i> Shift Finder</router-link
              >
              <router-link :to="{ name: 'restaurant.home' }" class="nav-link"
                ><i class="fa fa-desktop"></i> Dashboard</router-link
              >
              <router-link
                :to="{ name: 'restaurant.shift.calendar' }"
                class="nav-link"
                v-if="isAllowed"
                ><i class="fa fa-calendar"></i> Calendar</router-link
              >
              <router-link
                :to="{ name: 'restaurant.notifications' }"
                class="nav-link"
                ><i class="fa fa-users"></i> Notifications</router-link
              >
              <router-link
                :to="{ name: 'restaurant.profile.payment' }"
                :key="2"
                class="nav-link"
                ><i class="fa fa-money"></i> Payment Info</router-link
              >
              <router-link
                :to="{ name: 'restaurant.shift.summary' }"
                class="nav-link"
                v-if="isAllowed"
                ><i class="fa fa-list-ul"></i> Shift Summary</router-link
              >
              <router-link
                :to="{ name: 'restaurant.onboarding.profile' }"
                class="nav-link grey"
                ><i class="fa fa-cog"></i> My Settings
              </router-link>
              <router-link
                :to="{ name: 'restaurant.user.delete' }"
                class="nav-link grey"
                ><i class="fa fa-ban"></i> Delete My Account </router-link
              >
              <a
                href="#"
                @click.prevent.stop="logout"
                class="nav-link grey router-link-active"
                ><i class="fa fa-sign-out"></i> Sign Out</a
              >
            </li>
          </b-navbar-nav>
          <div class="bottom">
            <hr />
            <p class="pterms">
              <a href="/terms" target="_blank" class="terms">Terms of Use</a>
              {{ restaurateur.date }}
            </p>
          </div>
        </b-collapse>
      </b-navbar>
      <div v-else class="simpleback" :class="{ long: long }">
        <router-link
          :to="!backUrl ? { name: 'restaurant.home' } : backUrl"
          :key="1"
          class="back-link"
          ><b-icon icon="x"></b-icon
        ></router-link>
        <p v-if="address" class="address">
          {{ restaurant.name }} <i class="fa fa-map-marker"></i>
        </p>
        <h1 class="h1withimg">{{ title }}</h1>
        <p class="shortDesc" v-if="shortDesc">{{ shortDesc }}</p>
      </div>
    </div>
    <div class="container">
      <router-view v-if="loaded" class="vh100"></router-view>
    </div>
    <b-modal
      id="modal-add-staff"
      title="Please Confirm"
      ok-title="Yes, Continue"
      @ok="AddStaffAccount"
    >
      <h5 class="my-4">Do you really want to register as a staff?</h5>
    </b-modal>
  </div>
</template>

<script>
import { eventBus } from "@/main";

export default {
  name: "Restaurateur",
  data() {
    return {
      loaded: false,
      expanded: false,
      restaurateur: null,
      title: null,
      simple: false,
      desc: null,
      logo: null,
      long: false,
      address: null,
      backUrl: null,
      shortDesc: null,
      isMobile: false,
    };
  },
  methods: {
    AddStaffAccount() {
      this.$store
        .dispatch("userAddAccount")
        .then(async () => {
          await this.$store.dispatch("updateUserData").then(() => {
            this.$router.push({ name: "staff.onboarding.success" });
          });
        })
        .catch((e) => {
          return e;
        });
    },
    switchAccount() {
      this.$router.push({ name: "staff.home" });
    },
    subscribeToWebSocketNotification() {
      if (!this.restaurant) {
        return;
      }
      let url = window.location.origin + process.env.VUE_APP_CENTRIFUGO_URL;
      let id = this.restaurateur.restaurant.id;
      let token = this.restaurateur.socket_token;
      let centrifuge = new Centrifuge(url, { sockjs: SockJS });
      centrifuge.setToken(token);
      centrifuge.subscribe("notifications#" + id, (message) => {
        this.toast();
        eventBus.$emit("notification", { message: message.data.message });
      });
      centrifuge.connect();
    },
    toast() {
      this.$bvToast.toast(`check it`, {
        href: "/restaurant/notifications",
        title: "New notification",
        autoHideDelay: 5000,
      });
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "home" });
      });
    },
    toMain() {
      this.$router.push({ name: "restaurant.home" }).catch(() => {});
    },
    mandatoryReviews() {
      axios
        .get(
          "/restaurant/" +
            this.$store.state.restaurateur.restaurant.id +
            "/uncompleted-reviews"
        )
        .then((response) => {
          localStorage.mandatoryReviewsCount = response.data.count;
          localStorage.offersList = JSON.stringify(response.data.offers);
          this.redirectToReview();
        })
    },
    redirectToReview() {
      if (
        localStorage.mandatoryReviewsCount > 0 &&
        !(
          this.$route.name === "restaurant.shift.manager" ||
          this.$route.name === "restaurant.shift.offer.review"
        )
      ) {
        let mandatoryReview = JSON.parse(localStorage.offersList)[0];
        this.$router.push({
          name: "restaurant.shift.offer.review",
          params: {
            shiftId: mandatoryReview.shift_id,
            id: mandatoryReview.offer_id,
          },
        });
      }
    },
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    isAllowed() {
      return (
        this.$store.getters.isRestaurantApproved &&
        this.$store.getters.isRestaurantHasPaymentInfo
      );
    },
    restaurant() {
      return this.restaurateur.restaurant;
    },
    greeting() {
      const hour = moment().hour();
      if (hour > 16) {
        return "evening";
      }
      if (hour > 11) {
        return "afternoon";
      }
      return "morning";
    },
  },
  updated() {
    this.mandatoryReviews();
  },
  beforeMount() {
    this.isMobile = this.$store.getters.isRNAppSession;
  },
  mounted() {
    eventBus.$on("changeTop", (data) => {
      this.title = data.title !== undefined ? data.title : null;
      this.desc = data.desc !== undefined ? data.desc : null;
      this.logo = data.logo !== undefined ? data.logo : null;
      this.simple = data.simple === true;
      this.address = data.address !== undefined ? data.address : null;
      this.long = data.long !== undefined ? data.long : false;
      this.backUrl = data.backUrl !== undefined ? data.backUrl : null;
      this.shortDesc = data.shortDesc !== undefined ? data.shortDesc : null;
    });
    let loader = this.$loading.show();
    this.$store
      .dispatch("updateRestaurateurData")
      .then(async () => {
        await this.$store.dispatch("updateUserData").then(() => {});
        this.restaurateur = this.$store.state.restaurateur;
        this.loaded = true;
        this.subscribeToWebSocketNotification();
      })
      .finally(() => {
        loader.hide();
        this.mandatoryReviews();
      });
  },
};
</script>