<template>
    <div>
        <topheader v-if="!isMobile"></topheader>
        <div class="container">
            <router-view/>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Layout/Header";

export default {
    data() {
        return {
            isMobile: false,
        }
    },
    components: {'topheader': Header},
    methods: {
        logout() {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push({name: 'login'});
                })
        },
        isActive(str) {
            return this.$router.currentRoute.name ? this.$router.currentRoute.name.includes(str) : false;
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
}
</script>