<template>
    <div class="container notification-w" v-if="unreadAmount !== null" :class="{'blue': role === 'staff'}">
        <div class="row" @click="toNotifications()">
            <div class="col-8">
                <p class="ntitle">{{ last ? last.title : '' }}</p>
                <p class="desc">{{ last ? getTime(last.date) : '' }}</p>
            </div>
            <div class="col-4">
                <p class="amount text-right">+{{unreadAmount}}</p>
                <p class="desc text-right">new</p>
            </div>
            <div class="col-7">
                <p class="title"><i class="fa fa-list"></i> Notifications</p>
            </div>
            <div class="col-5 text-right">
                <router-link :to="{name: role === 'restaurant' ? 'restaurant.notifications' : 'staff.notifications'}" class="view">View All</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
    name: "Notification",
    props: ['role'],
    data () {
        return {
            unreadAmount: null,
            last: null,
            tz: null
        }
    },
    methods: {
        getData() {
            axios
                .get('/data/notifications/notifications-info')
                .then((response) => {
                    this.unreadAmount = response.data.unread_amount;
                    this.last = response.data.last;
                });
        },
        getTime(time) {
            let mTime = moment.tz(time, 'UTC').clone().tz(this.tz);
            if (mTime.format('YYYY-MM-DD') === this.today) {
                let minutesDiff = moment().diff(mTime, 'minutes');
                if (minutesDiff < 60) {
                    return minutesDiff + " minutes ago";
                }
                return "Today at " + mTime.format('h:mm a');
            }
            if (moment(time).format('YYYY-MM-DD') === this.yesterday) {
                return "Yesterday at " + mTime.format('h:mm a');
            }
            if (mTime.format('YYYY-MM-DD') > moment().subtract(7, 'days').format('YYYY-MM-DD')) {
                return mTime.format('dddd');
            }

            return mTime.format('MMM Do');
        },
        toNotifications() {
            this.$router.push({ name: this.role === 'restaurant' ? 'restaurant.notifications' : 'staff.notifications' });
        }
    },
    computed: {
        today() {
            return moment().format('YYYY-MM-DD');
        },
        yesterday() {
            return moment().subtract(1, 'days').format('YYYY-MM-DD');
        }
    },
    mounted() {
        this.tz = moment.tz.guess();
        this.getData();
        eventBus.$on('notification', data => {
           this.getData();
        });
    }
}
</script>

<style scoped>

</style>