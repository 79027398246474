<template>
    <div class="row calendar">
        <div class="col-12">
            <div class="cont" :class="(isMobile ? 'cal-rNApp mt-5' : '')">
                <div class="dcalendar">
                    <b-calendar
                        id="calendarel"
                        :hide-header="true"
                        v-model="selectedDate"
                        locale="en-US"
                        :min="new Date()"
                        :show-decade-nav="false"
                        @selected="clickDate()"
                        @context="onContext"
                    >
                    </b-calendar>
                </div>
                <div>
                    <p class="selectedDate">{{ formattedSelectedDate }}</p>
                    <hr/>
                    <div class="container shift" v-for="shift in shifts" :class="{'dark':  dateTimeFromString(shift.period_start_time).format('A') === 'PM'}">
                        <div class="row">
                            <div class="col-7">
                                <p class="desc" v-if="shift.speciality_title == 'Bartender'">{{ dateTimeFromString(shift.period_start_time).format('A') }} Bartender Shift</p>
                                <p class="desc" v-else>{{ dateTimeFromString(shift.period_start_time).format('A') }} {{ shift.speciality_title }} Shift</p>
                                <p class="desc">{{ dateTimeFromString(shift.period_start_time).format('MMMM Do [at] h:mm a') }}</p>
                            </div>
                            <div class="col-5">
                                <p class="desc">{{ shift.name }}</p>
                                <p class="desc">{{ offersAmountByShift(shift) >= shift.vacancies_number ? 'Full' : 'Incomplete' }} {{ offersAmountByShift(shift) }}/{{ shift.vacancies_number }}</p>
                            </div>
                            <div class="col-12">
                                <span class="bartender" v-for="index in 2" v-if="shift.offers[index - 1] !== undefined">
                                    {{ shift.offers[index - 1].name_first + ' ' + shift.offers[index - 1].name_last.charAt(0) }}
                                </span>
                                <span v-if="offersAmountByShift(shift) > 3">+{{ offersAmountByShift(shift) - 3}}</span>
                                <button class="btn btn-secondary" @click="showDetails(shift)">Details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
    name: "Calendar",
    data () {
        return {
            selectedDate: new Date(),
            dates: [],
            shifts: [],
            isMobile: false
        }
    },
    methods: {
        setShiftToDates() {
            this.dates.forEach(date => {
                let elem = document.getElementById('calendarel__cell-' + date + '_');
                if (elem) {
                    elem.classList.add("dotted");
                }
            });
        },
        clickDate() {
            this.getShifts();
        },
        onContext() {
            setTimeout(() => {this.setShiftToDates();}, 1000);
        },
        getDates() {
            axios
                .get('/shift/dates')
                .then((response) => {
                    this.dates = response.data.dates;
                })
                .catch(() => {})
                .finally(() => {
                    this.onContext()
                });
        },
        getShifts() {
            axios
                .get('/shift/shifts-by-date/' + this.formattedSelectedDateSystem)
                .then((response) => {
                    this.shifts = response.data.shifts;
                })
                .catch(() => {})
                .finally(() => {
                    this.onContext()
                });
        },
        offersAmountByShift(shift) {
            return Object.keys(shift.offers).length;
        },
        dateTimeFromString(datetime) {
            return moment(datetime);
        },
        showDetails(shift) {
            if (this.offersAmountByShift(shift) >= shift.vacancies_number) {
                this.$router.push({name: 'restaurant.shift.manager', 'params': {'id': shift.id}})
                return;
            }
            this.$router.push({name: 'restaurant.shift.search-results', 'params': {'id': shift.id}})
        }
    },
    computed: {
        formattedSelectedDate() {
            return moment(this.selectedDate).format('dddd, MMMM Do');
        },
        formattedSelectedDateSystem() {
            return moment(this.selectedDate).format('YYYY-MM-DD');
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        eventBus.$emit('changeTop', {
            title: '',
            desc: '',
            address: true
        });
        this.getDates();
        this.getShifts();
    }
}
</script>