<template>
    <div class="row notifications">
        <notifications
            notifications-url="/data/notifications/all"
            view-url="/data/notifications/view-several"
            :dark="false"
        ></notifications>
    </div>
</template>

<script>
import {eventBus} from "@/main";
import Notifications from "@/components/Notification/Notifications";

export default {
    name: "Notifications",
    data () {
        return {

        }
    },
    components: {'notifications': Notifications},
    methods: {
    },
    computed: {
    },
    mounted() {
        let restaurant = this.$store.state.restaurateur.restaurant;
        eventBus.$emit('changeTop', {title: restaurant.name + ' Notifications', simple: true, topLogo: true});
    }
}
</script>