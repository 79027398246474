export const Shift = {
    data() {
        return {
            shift: null,
            tz: null
        }
    },
    computed: {
        formattedStartDate() {
            return moment(this.shift.period.start).format('dddd, MMMM Do');
        },
        offersAmount() {
            return Object.keys(this.shift.offers).length;
        },
        emptySlotsNumber() {
            return this.shift.vacancies_number - this.offersAmount;
        },
        shiftStartsInMinutes() {
            return moment(this.shift.period.start).diff(moment(), 'minutes');
        },
        shiftStartsInHours() {
            return moment(this.shift.period.start).diff(moment(), 'hours', true);
        },
        shiftEndsInHours() {
            return moment(this.shift.period.end).diff(moment(), 'hours', true);
        },
        isMatchedStatus() {
            return this.shift.status === 'matched';
        },
        canManageVacancies() {
            if (!this.isMatchedStatus && this.shift.status !== 'matching') {
                return false;
            }
            return this.shiftStartsInMinutes > 5;
        },
        canSetDidntShowUp() {
            return this.isMatchedStatus && this.shiftStartsInMinutes <= 5 && this.shiftEndsInHours >= 0;
        },
        canLeaveReview() {
            return this.shiftStartsInHours <= -1;
        }
    },
    methods: {
        getShift(withOffers) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/shift/' + this.id + '/view/' + (withOffers ? '1' : ''))
                    .then((response) => {
                        this.shift = response.data.shift;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
    },
    mounted() {
        this.tz = moment.tz.guess();
    }
};