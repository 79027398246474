<template>
    <div class="row summary black">
        <div class="col-12 cont" :class="(isMobile ? 'cont-rNApp' : '')" v-if="offers && offers.length > 0">
            <div class="row oneoffer" v-for="offer in offers" @click="click(offer)" >
                <div class="col-2">
                    <img class="avatar" :src="offer.restaurant_photo" v-if="offer.restaurant_photo"/>
                    <!-- <div class="avatar" v else>U</div> -->
                    <div class="img shift-summary-logo" v-else><img src="https://boonb.s3.amazonaws.com/ubar/ubarlogo.png"></div>
                </div>
                <div class="col-8">
                    <p class="name">{{ offer.restaurant_name }}</p>
                    <p class="date">{{ dateTimeFromString(offer.period_start_time).format('MM/DD/YYYY [at] h:mm a') }}</p>
                </div>
                <div class="col-2">
                    <p class="price">${{ offer.cost }}</p>
                </div>
                <div class="col-12 p-0">
                    <div class="mapimg"></div>
                </div>
            </div>
        </div>
        <div class="col-12 cont" v-else>
            <b-alert show variant="danger">There are no shifts yet.</b-alert>
        </div>
    </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
    name: "Summary",
    data () {
        return {
            offers: null
        }
    },
    methods: {
        getOffers() {
            let loader = this.$loading.show();
            axios
                .get('/shift/offer/all-success')
                .then((response) => {
                    this.offers = response.data.offers;
                })
                .finally(() => {loader.hide()});
        },
        dateTimeFromString(datetime) {
            return moment(datetime);
        },
        click(offer) {
            this.$router.push({name: 'staff.shift.offer.details', params: {id: offer.id}})
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
        console.log(this.isMobile);
    },
    mounted() {
        eventBus.$emit('changeTop', {
            title: 'UBAR Shifts',
            desc: '',
            simple: true
        });
        this.getOffers();
    }
}
</script>