<template>
    <div class="row availability-modal">
        <div class="col-12 mt-3">Select time range for single day
        </div>
        <div class="col-6">
            <label class="required">From</label>
            <b-form-timepicker v-model="form.from" locale="en" minutes-step="30" label-close-button="OK" :hide-header="true"></b-form-timepicker>
        </div>
        <div class="col-6">
            <label class="required">To</label>
            <b-form-timepicker v-model="form.to" locale="en" minutes-step="30" label-close-button="OK" :hide-header="true"></b-form-timepicker>
        </div>
        <div class="col-12 mt-3">
            <label class="required">Days</label>
            <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                    v-model="form.days"
                    :aria-describedby="ariaDescribedby"
                >
                    <b-form-checkbox v-for="(day, index) in days" :key="index" :value="index">{{ day }}</b-form-checkbox>
                </b-form-checkbox-group>
            </b-form-group>
            <hr/>
        </div>
    </div>
</template>

<script>

export default {
    name: "TimeRow",
    props: ['form', 'days'],
    mounted() {
    }
}
</script>