<template>
    <div>
        <div class="dcalendar black">
            <b-calendar
                id="calendarel"
                :hide-header="true"
                v-model="selectedDate"
                :date-info-fn="dateClass"
                :min="new Date()"
                locale="en-US"
                :show-decade-nav="false"
                @selected="clickDate()"
            >
            </b-calendar>
        </div>
        <div>
            <div class="selectedDate row" v-if="selectedDate">
                <div class="col">
                    {{ formattedSelectedDate }}
                </div>
                <div class="col text-right" v-if="offers.length === 0">
                    <a href="#" class="ulink d-inline" v-if="isAvailable(selectedDate)" @click.prevent="askAddBusyness()">Busy on this day?</a>
                    <a href="#" class="ulink d-inline" v-else-if="offers.length === 0" v-b-modal.av>Available on this day?</a>
                    <button @click="selectedDate=null" class="close-button">close <i class="fa fa-times"></i></button>
                </div>
            </div>
            <div v-else>
                <av-list @updated="availabilityUpdated()"></av-list>
            </div>
            <hr/>
            <div class="container-fluid">
                <b-modal id="av" ref="av" size="md" title="Availability" :hide-footer="true">
                    <div class="container availability-modal">
                        <div class="row">
                            <div class="col-6">
                                <label class="mt-2">From</label>
                                <b-form-timepicker v-model="avForm.from" locale="en" minutes-step="30" label-close-button="OK" :hide-header="true"></b-form-timepicker>
                            </div>
                            <div class="col-6">
                                <label class="mt-2">To</label>
                                <b-form-timepicker v-model="avForm.to" locale="en" minutes-step="30" label-close-button="OK" :hide-header="true"></b-form-timepicker>
                            </div>
                        </div>
                        <p class="text-center mb-0 mt-3">
                            <button @click="addSpecificAvailability()" class="btn btn-primary">Save</button>
                        </p>
                    </div>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>

import List from "@/components/Shift/Staff/Availability/List";

export default {
    name: "ScheduleCalendar",
    props: ['selectedDateParent', 'offers'],
    components: {'av-list': List},
    data () {
        return {
            selectedDate: this.selectedDateParent,
            dates: [],
            availableDays: [],
            avForm: {
                from: '07:00',
                to: '18:00'
            },
            businesses: [],
            specificAvailableDays: [],
            availabilities: [],
            availabilitiesLimit: {weeks: null, date: null}
        }
    },
    methods: {
        dateClass(ymd, date) {
            const day = moment(date).format('YYYY-MM-DD');
            let classNames = '';
            if (this.dates.indexOf(day) !== -1) {
                classNames += 'dotted ';
            }
            if (this.isAvailable(day)) {
                classNames += 'available ';
            }
            return classNames;
        },
        isAvailable(date) {
            const day = moment(date).format('YYYY-MM-DD');
            return (this.isAvailableBasic(day) || this.isSpecificAvailable(day)) && !this.isBusy(day);
        },
        isAvailableBasic(date) {
            const day = moment(date);
            return (this.availableDays.indexOf(parseInt(day.format('e'))) !== -1)
                && (this.availabilitiesLimit.date ? day.format('YYYY-MM-DD') <= this.availabilitiesLimit.date : true);
        },
        isSpecificAvailable(date) {
            return this.specificAvailableDays.filter((sa) => sa.date === date).length > 0;
        },
        isBusy(date) {
            return this.businesses.indexOf(date) !== -1;
        },
        clickDate() {
            this.$emit('date-changed', this.selectedDate);
        },
        getDates() {
            axios
                .get('/shift/offer/dates')
                .then((response) => {
                    this.dates = response.data.dates;
                })
                .catch(() => {})
                .finally(() => {});
        },
        findAvailableDays() {
            const staff = this.$store.state.staff;
            this.availableDays = [];
            this.availabilities = staff.availabilities;
            this.availabilitiesLimit = staff.availabilitiesLimit;
            staff.availabilities.forEach((av) => {
                this.availableDays = this.availableDays.concat(av.days);
            });
            this.specificAvailableDays = staff.specific_availabilities;
            this.businesses = staff.busynesses;
        },
        askAddBusyness() {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able get shifts at this day.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0070ff',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.addBusyness();
                }
            })
        },
        addBusyness() {
            let loader = this.$loading.show();
            axios
                .post('/staff/add-busyness', {date: this.selectedDate})
                .then((response) => {
                    this.businesses.push(this.selectedDate);
                    this.specificAvailableDays = this.specificAvailableDays.filter((date) => date.date !== this.selectedDate);
                })
                .catch(() => {})
                .finally(() => {
                    loader.hide();
                });
        },
        addSpecificAvailability() {
            let loader = this.$loading.show();
            const data = {
                date: this.selectedDate,
                from: this.avForm.from.split(':').slice(0, 2).join(':'),
                to: this.avForm.to.split(':').slice(0, 2).join(':')
            };
            axios
                .post('/staff/add-specific-availability', data)
                .then((response) => {
                    this.$refs['av'].hide();
                    this.specificAvailableDays.push(data);
                    this.businesses = this.businesses.filter((date) => date !== this.selectedDate);
                })
                .catch(() => {})
                .finally(() => {
                    loader.hide();
                });
        },
        availabilityUpdated() {
            this.findAvailableDays();
        },
        convertMilitaryTime(time) {
            return moment(time, 'HH:mm').format('h:mm a');
        }
    },
    computed: {
        formattedSelectedDate() {
            if (!this.selectedDate) {
                return '';
            }
            const mSelectedDate = moment(this.selectedDate);
            const base = mSelectedDate.format('dddd, MMMM Do');
            const weekDay = parseInt(mSelectedDate.format('e'));
            const day = mSelectedDate.format('YYYY-MM-DD');
            let time = '';
            try {
                if (this.isSpecificAvailable(this.selectedDate) && !this.isBusy(day)) {
                    let date = this.specificAvailableDays.filter((date) => date.date === this.selectedDate)[0];
                    time = this.convertMilitaryTime(date['from']) + ' - ' + this.convertMilitaryTime(date['to']);
                } else if(
                    (this.availableDays.indexOf(weekDay) !== -1)
                    && !this.isBusy(day)
                    && (this.availabilitiesLimit.date ? day <= this.availabilitiesLimit.date : true)
                ) {
                    const av = this.availabilities.filter((a) => a.days.indexOf(weekDay) !== -1)[0];
                    time = this.convertMilitaryTime(av['from']) + ' - ' + this.convertMilitaryTime(av['to']);
                }
            } catch (e) {}

            return base + (time ? ' (' + time + ')' : '');
        }
    },
    mounted() {
        this.getDates();
        this.findAvailableDays();
    }
}
</script>