<template>
    <div class="row chat">
        <messages
            v-if="restaurateur"
            :author-id="restaurateur.restaurant.id"
            :recipient-id="id"
            input-placeholder="Enter a custom question"
            :socket_token="restaurateur.socket_token"
            own-color-class="blue"
            interlocutor-color-class="black"
        >

        </messages>
    </div>
</template>

<script>
import {eventBus} from "@/main";
import Messages from "@/components/Chat/Messages";

export default {
    components: {Messages},
    props: ['id'],
    name: "Chat",
    data() {
        return {
            staff: null,
            restaurateur: null
        }
    },
    mounted() {
        this.restaurateur = this.$store.state.restaurateur;
        eventBus.$emit('changeTop', {title: 'Ask Questions', simple: true, topLogo: false});
        axios
            .get('/staff/' + this.id + '/short-view')
            .then((response) => {
                this.staff = response.data;
                eventBus.$emit('changeTop', {title: 'Ask Questions', shortDesc: this.staff.name.full, simple: true, topLogo: false});
            });
    }
}
</script>