<template>
    <div class="row paymentinfo justify-content-center">
        <div class="col-md-6 cont">
            <div class="rowaction" v-if="hasPaymentData && !showCardForm">
                <p class="title">Payment</p>
                <div class="whiteb">
                    <p class="title" @click="showCardForm = true">Change card</p>
                </div>
            </div>
            <div v-if="!hasPaymentData || showCardForm">
                <div class="cardblock" :class="(isMobile ? 'cont-rNApp' : '')">
                    <img src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png" class="chip">
                    <img class="typeimg" v-if="typeImg" :src="typeImg">
                    <div class="number">
                        <b-form-input
                            v-model="form.number"
                            type="text"
                            placeholder="####  ####  ####  ####"
                            class="mb-0"
                            v-mask="'####  ####  ####  ####'"
                        ></b-form-input>
                        <div class="invalid-feedback" v-if="getError('card.number')" v-text="getError('card.number')"></div>
                    </div>
                    <div class="exp">
                        <p class="expires">Expires</p>
                        <b-form-select
                            class="month"
                            v-model="form.expMonth"
                            :options="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']"
                        >
                            <template #first>
                                <b-form-select-option :value="null">##</b-form-select-option>
                            </template>
                        </b-form-select>
                        <span>/</span>
                        <b-form-select
                            class="year"
                            v-model="form.expYear"
                            :options="years"
                        >
                            <template #first>
                                <b-form-select-option :value="null">####</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback" v-if="getError('card.expYear')" v-text="getError('card.expYear')"></div>
                        <div class="invalid-feedback" v-if="getError('card.expMonth')" v-text="getError('card.expMonth')"></div>
                    </div>
                    <div class="holder">
                        <p class="label">Card Holder</p>
                        <b-form-input
                            v-model="name"
                            type="text"
                            placeholder=""
                            class="mb-2"
                            v-mask=""
                        ></b-form-input>
                    </div>
                </div>
                <div class="cardback" :class="(isMobile ? 'cont-rNApp' : '')" v-if="form.number && form.expMonth && form.expYear">
                    <div class="cvc">
                        <label>CVC</label>
                        <b-form-input
                            v-model="form.cvc"
                            type="text"
                            placeholder="####"
                            class="mb-0"
                            v-mask="'####'"
                        ></b-form-input>
                        <div class="invalid-feedback" v-if="getError('card.cvc')" v-text="getError('card.cvc')"></div>
                    </div>
                </div>
                <div class="text-center mt-3" v-if="form.number && form.expMonth && form.expYear && form.cvc">
                    <b-button variant="primary" class="blue" @click="create()">Submit</b-button>
                </div>
            </div>
            <div class="rowaction">
                <p class="title">Promotions</p>
                <input class="couponinput" type="text" placeholder="Enter Promo Code" v-model="coupon" />
                <div class="text-center mt-3">
                    <b-button v-if="coupon" variant="primary" class="blue" @click="applyCoupon()">Apply</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ServerErrors} from "@/mixins/ServerErrors";
import {eventBus} from "@/main";

export default {
    name: "PaymentInfo",
    mixins: [ServerErrors],
    data() {
        return {
            errors: [],
            hasPaymentData: false,
            showCardForm: false,
            form: {
                number: null,
                expMonth: null,
                expYear: null,
                cvc: null,
            },
            name: null,
            coupon: null,
            showCouponForm: false,
            isMobile: false
        }
    },
    methods: {
        create() {
            let loader = this.$loading.show();
            this.errors = [];
            axios({
                method: 'POST',
                url: '/restaurant/onboarding/save-payment-info',
                data: {
                    card: {
                        number: this.form.number ? this.form.number.replace(/ /g, '') : '',
                        expMonth: this.form.expMonth,
                        expYear: this.form.expYear ? parseInt(this.form.expYear) : null,
                        cvc: this.form.cvc,
                    }
                }
            })
                .then(() => {
                    this.$store.dispatch('updateRestaurateurData')
                    .then(() => {
                        this.hasPaymentData = true;
                        this.showCardForm = false;
                        Swal.fire({
                            title: 'Attached',
                            text: '',
                            icon: 'success'
                        });
                        //this.$router.push({ name: 'restaurant.home' });
                    });
                })
                .finally(() => {
                    loader.hide();
                });
        },
        applyCoupon() {
            let loader = this.$loading.show();
            this.errors = [];
            axios({
                method: 'POST',
                url: '/restaurant/onboarding/apply-coupon',
                data: {
                    code: this.coupon
                }
            })
                .then(() => {
                    this.$store.dispatch('updateRestaurateurData').then(() => {});
                    Swal.fire({
                        title: 'Applied',
                        text: '',
                        icon: 'success'
                    })
                })
                .finally(() => {
                    loader.hide();
                });
        }
    },
    computed: {
        getCardType () {
            let number = this.form.number;
            if (!number) {
                return null;
            }
            let re = new RegExp("^4");
            if (number.match(re) != null) return "visa";
            re = new RegExp("^(34|37)");
            if (number.match(re) != null) return "amex";
            re = new RegExp("^5[1-5]");
            if (number.match(re) != null) return "mastercard";
            re = new RegExp("^6011");
            if (number.match(re) != null) return "discover";
            re = new RegExp('^9792')
            if (number.match(re) != null) return 'troy'
            return null;
        },
        typeImg () {
            if (!this.getCardType) {
                return null;
            }
            return 'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + this.getCardType + '.png';
        },
        years() {
            let years = [];
            let currentYear = new Date().getFullYear();
            for (let i = currentYear; i <= currentYear + 12; i++) {
                years.push(i);
            }
            return years;
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        let restaurateur = this.$store.state.restaurateur;
        if (!restaurateur.email || !restaurateur.name.first) {
            Swal.fire({
                title: '',
                text: 'Please add profile info first',
                icon: 'info'
            })
            this.$router.push({name: 'restaurant.onboarding.profile'});
        }
        this.hasPaymentData = restaurateur.restaurant.wallet.has_payment_data;
        this.coupon = restaurateur.restaurant.coupon;
        this.name = restaurateur.name.full;
        eventBus.$emit('changeTop', {title: 'Payment Info', simple: true});
    }
}
</script>