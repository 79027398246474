<template>
    <div class="row home-dashboard">
        <div class="col-md-6" :class="(isMobile ? 'cont-rNApp' : 'cont')" >
            <div class="action-required mb-4" v-if="!hasTransferData || !isApproved">
                <p class="title">Action Required!</p>
                <p class="desc" v-if="!hasTransferData">To find new shifts and take full advantage of UBAR, finish filling out your profile.</p>
                <router-link v-if="!hasTransferData" :to="{name: 'staff.onboarding.profile'}" class="btn actionbtn">Finish profile</router-link>
                <p class="desc" v-else>To find new shifts and take full advantage of UBAR, please wait for profile approvement.</p>
            </div>
            <shift-widget v-if="isApproved" class="mt-2"></shift-widget>
            <calendar-widget v-if="!offer" :class="(isApproved && this.$store.getters.isRNAppSession) ? 'mt-2':''"></calendar-widget>
            <div class="find-shifts" v-if="isApproved">
                <p class="title">Pick up a shift in minutes with</p>
                <p class="desc">U+BAR Shift Finder</p>
                <router-link :to="{name: 'staff.shift.map'}" class="btn btn-primary blue">Check it out <i class="fa fa-long-arrow-right"></i></router-link>
            </div>
            <notification role="staff"></notification>
        </div>
    </div>
</template>

<script>
import {eventBus} from "@/main";
import Notification from "@/components/Notification/Notification";
import ShiftWidget from "@/components/Shift/Staff/ShiftWidget";
import CalendarWidget from "@/components/Calendar/Widget";

export default {
    name: "Home",
    data() {
        return {
            hasTransferData: false,
            offer: null
        }
    },
    components: {'calendar-widget': CalendarWidget, 'shift-widget': ShiftWidget, 'notification': Notification},
    computed: {
        isApproved() {
            return this.$store.getters.isStaffApproved;
        }
    },
    methods: {
        getOffer() {
            axios
                .get('/shift/offer/closest-offer')
                .then((response) => {
                    this.offer = response.data.offer;
                })
                .catch(() => {});
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        this.getOffer();
        // console.log('---------------------', this.$store.state.staff)
        eventBus.$emit('changeTop', {'logo': true, 'topLogo': false});
        this.hasTransferData = this.$store.state.staff.wallet.has_transfer_data;
    }
}
</script>