<template>
    <div class="row staff-profile justify-content-center">
        <div class="col-md-6 cont" :class="(isMobile ? 'cont-rNApp' : '')" v-if="showForm || !isSsnSet">
            <div class="input-group">
                <input type="text" v-mask="'###-##-####'" v-model="ssn" class="form-control inp" placeholder="SSN">
                <div class="invalid-feedback" v-if="getError('ssn')" v-text="getError('ssn')"></div>
            </div>
            <div class="text-center">
                <button class="btn btn-primary blue" @click="submit">Finish</button>
            </div>
        </div>
        <div class="col-md-6 cont" :class="(isMobile ? 'cont-rNApp' : '')" v-if="isSsnSet && !showForm">
            <div class="rowaction">
                <p class="title">SSN is already set</p>
                <div class="whiteb">
                    <p class="title" @click="showForm = true">Change</p>
                </div>
            </div>
            <div class="text-center">
                <router-link :to="{name: 'staff.profile.transfer'}" class="btn btn-primary black">Skip</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {ServerErrors} from "@/mixins/ServerErrors";

export default {
    name: "Ssn",
    data () {
        return {
            ssn: '',
            isSsnSet: false,
            showForm: false,
            isMobile: false,
        }
    },
    mixins: [ServerErrors],
    methods: {
        syncData() {
            let data = this.$store.state.staff;
            if (!data || Object.keys(data).length === 0) {
                return;
            }
            this.isSsnSet = !!data.ssn;
        },
        submit() {
            if (!this.ssn && this.isSsnSet) {
                this.$router.push({name: 'staff.profile.transfer'});
            }
            let loader = this.$loading.show();
            axios({
                method: 'POST',
                url: '/staff/dl/ssn',
                data: {ssn: this.ssn}
            })
            .then(response => {
                this.$store.dispatch('updateStaffData')
                    .then(() => {
                        this.$router.push({name: 'staff.profile.transfer'});
                    });
            })
            .finally(() => {
                loader.hide();
            });
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        this.$store.commit('changePageData', {title: 'Account information', back: this.$router.resolve({name: 'staff.onboarding.driver'}).href});
        this.syncData();
    }
}
</script>