<template>
    <div class="row justify-content-center signupphone">
        <div class="col-md-6 cont">
            <b-form @submit="submit">
                <div class="input-group">
                    <b-form-input class="inp" placeholder="New password" id="password" type="password" v-model="password" required></b-form-input>
                </div>
                <div class="invalid-feedback" v-if="getError('password')" v-text="getError('password')"></div>
                <b-button type="submit" variant="primary">Save</b-button>
            </b-form>
        </div>
    </div>
</template>

<script>
import {ServerErrors} from "@/mixins/ServerErrors";

export default {
    name: "Reset",
    props: ['token'],
    mixins: [ServerErrors],
    data() {
        return {
            password: ''
        }
    },
    methods: {
        submit(event) {
            event.preventDefault();
            let loader = this.$loading.show();
            axios
                .post('/reset/' + this.token, {password: this.password})
                .then((response) => {
                    this.$router.push({name: 'login'});
                })
                .catch(error => {
                    //caught in the mixin
                })
                .finally(() => {
                    loader.hide();
                });
        }
    },
    mounted() {
        this.$store.commit('changePageData', {title: 'Reset password'});
    }
}
</script>