<template>
    <div v-if="term">
        <h3>Last Revised:  {{ term.date }}</h3>
        <div v-html="term.text"></div>
    </div>
</template>

<script>
export default {
    name: "Terms",
    data () {
        return {
            term: null
        }
    },
    mounted() {
        axios
            .get('/data/terms/terms')
            .then((response) => {
                this.term = response.data.term;
            });
    }
}
</script>