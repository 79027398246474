<template>
  <div class="mainback black">
    <div v-if="!isMobile">
      <b-navbar
        toggleable
        class="mainmenu"
        :class="{ long: title === 'Let\'s find the perfect shift.' }"
        v-if="loaded && !simple"
      >
        <b-navbar-toggle
          target="navbar-toggle-collapse"
          :class="{ 'w-30': expanded }"
          @click="expanded = !expanded"
        >
          <template #default="{ expanded }">
            <b-icon v-if="expanded" icon="x"></b-icon>
            <i class="fa fa-bars" v-else></i>
          </template>
        </b-navbar-toggle>
        <div class="maintop">
          <div class="topLogo" v-if="topLogo">
            <img
              class="avatar"
              :src="'https://boonb.s3.amazonaws.com/ubar/ubarlogo.png'"
              @click="toMain()"
            />
          </div>
          <p class="address" v-else></p>
          <p class="name">
            {{
              title
                ? title
                : "Good " +
                  greeting +
                  (staff.name.first ? ", " + staff.name.first : "") +
                  "."
            }}
          </p>
          <p class="desc">{{ desc !== null ? desc : "How can we serve you?" }}</p>
          <div class="img" v-if="logo">
            <img
              class="avatar"
              :src="'https://boonb.s3.amazonaws.com/ubar/ubarlogo.png'"
              @click="toMain()"
            />
          </div>
        </div>

        <b-collapse id="navbar-toggle-collapse" is-nav>
          <div class="top">
            <img
              v-if="staff.photo"
              class="avatar"
              :src="staff.photo"
              @click="toMain()"
            />
            <div v-else class="img border-0">
              <img
                class="avatar"
                :src="'https://boonb.s3.amazonaws.com/ubar/ubarlogo.png'"
                @click="toMain()"
              />
            </div>
            <p class="name">
              {{
                staff.name.first ? staff.name.first + " " + staff.name.last : ""
              }}
            </p>
            <p class="desc">
              {{ staff.speciality ? staff.speciality.title : "" }}
            </p>
            <b-link
              v-if="
                userData &&
                userData.totalRoles &&
                userData.totalRoles.length === 1
              "
              class="nav-link grey fs-3"
              v-b-modal.modal-add-restaurateur
              ><h6>Add Account as Restaurateur?</h6></b-link
            >
            <b-link
              v-if="
                userData &&
                userData.totalRoles &&
                userData.totalRoles.length === 2
              "
              class="nav-link grey fs-3"
              @click="switchAccount"
              ><h6>Switch Account as Restaurateur?</h6></b-link
            >
            <hr />
            <div class="row">
              <div class="col-6">
                <star-rating
                  :read-only="true"
                  :show-rating="false"
                  active-color="#000"
                  :star-size="21"
                  :rating="staff.statistic.average_stars"
                  :star-points="[
                    23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                    46, 19, 31, 17,
                  ]"
                  :padding="5"
                ></star-rating>
              </div>
              <div class="col-6">
                <p class="ratingval">
                  {{ staff.statistic.reviews_total }} total reviews
                </p>
              </div>
            </div>
            <hr />
          </div>
          <b-navbar-nav class="ml-auto">
            <li class="nav-item" v-if="$store.state.staff">
              <router-link
                :to="{ name: 'staff.shift.map' }"
                v-if="isAllowed"
                class="nav-link -inverse"
                ><i class="fa fa-search"></i> Shift Finder</router-link
              >
              <router-link :to="{ name: 'staff.home' }" class="nav-link"
                ><i class="fa fa-desktop"></i> Dashboard</router-link
              >
              <router-link
                :to="{ name: 'staff.shift.calendar' }"
                v-if="isAllowed"
                class="nav-link"
                ><i class="fa fa-calendar"></i> Calendar</router-link
              >
              <router-link :to="{ name: 'staff.notifications' }" class="nav-link"
                ><i class="fa fa-users"></i> Notifications</router-link
              >
              <router-link
                :to="{ name: 'staff.wallet' }"
                v-if="isAllowed"
                class="nav-link"
                ><i class="fa fa-line-chart"></i> Earnings Summary</router-link
              >
              <router-link
                :to="{ name: 'staff.profile.transfer' }"
                v-if="$store.state.staff.email"
                class="nav-link"
                ><i class="fa fa-money"></i> Payment Info</router-link
              >
              <router-link
                :to="{ name: 'staff.shift.offer.summary' }"
                v-if="isAllowed"
                class="nav-link"
                ><i class="fa fa-list-ul"></i> Shift Summary</router-link
              >
              <router-link
                :to="{ name: 'staff.onboarding.profile' }"
                class="nav-link grey"
                ><i class="fa fa-cog"></i> My Settings</router-link
              >
              <router-link
                :to="{ name: 'staff.user.delete' }"
                class="nav-link grey"
                ><i class="fa fa-ban"></i> Delete My Account </router-link
              >
              <!-- <a
                href="#"
                @click.prevent.stop="logout"
                class="nav-link grey router-link-active"
                ><i class="fa fa-sign-out"></i> Sign Out</a
              > -->
              <router-link
                :to="{ name: 'logout' }"
                class="nav-link grey router-link-active"
                ><i class="fa fa-sign-out"></i> Sign Out</router-link
              >
            </li>
          </b-navbar-nav>
          <div class="bottom">
            <hr />
            <p class="pterms">
              <a href="/terms" target="_blank" class="terms">Terms of Use</a>
              {{ staff.date }}
            </p>
          </div>
        </b-collapse>
      </b-navbar>
      <div v-else class="simpleback black">
        <router-link
          :to="!backUrl ? { name: 'staff.home' } : backUrl"
          :key="1"
          class="back-link"
          ><b-icon icon="x"></b-icon
        ></router-link>
        <div class="h1withimg">
          <div class="topLogo" v-if="topLogo">
            <img
              class="avatar"
              :src="'https://boonb.s3.amazonaws.com/ubar/ubarlogo.png'"
              @click="toMain()"
            />
          </div>
          <h1>{{ title }}</h1>
        </div>
        <p class="desc" v-if="desc">{{ desc }}</p>
        <p class="shortDesc" v-if="shortDesc">{{ shortDesc }}</p>
      </div>
    </div>
      <div class="container">
      <router-view v-if="loaded" class="vh100"></router-view>
    </div>
    <b-modal
      id="modal-add-restaurateur"
      title="Please Confirm"
      ok-title="Yes, Continue"
      @ok="addRestaurateurAccount"
    >
      <h5 class="my-4">Do you really want to register as a restaurateur?</h5>
      <div></div>
    </b-modal>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import DeviceDetector from 'vue-device-detector';

export default {
  mixins: [DeviceDetector],
  name: "Staff",
  data: function () {
    return {
      loaded: false,
      expanded: false,
      staff: null,
      title: null,
      simple: false,
      desc: null,
      logo: null,
      topLogo: true,
      backUrl: null,
      shortDesc: null,
      isMobile: false,
    };
  },
  methods: {
    switchAccount() {
      this.$router.push({ name: "restaurant.home" });
    },
    addRestaurateurAccount() {
      let loader = this.$loading.show();
      this.$store
        .dispatch("userAddAccount")
        .then(async () => {
          loader.hide();
          await this.$store.dispatch("updateUserData").then(() => {
            this.$router.push({ name: "restaurant.onboarding.success" });
          });
        })
        .catch((e) => {
          loader.hide();
          return e;
        });
    },
    subscribeToWebSocketNotification() {
      let url = window.location.origin + process.env.VUE_APP_CENTRIFUGO_URL;
      let id = this.$store.state.staff.id;
      let token = this.$store.state.staff.socket_token;
      let centrifuge = new Centrifuge(url, { sockjs: SockJS });
      centrifuge.setToken(token);
      centrifuge.subscribe("notifications#" + id, (message) => {
        //console.log(message.data.message);
        this.toast();
        eventBus.$emit("notification", { message: message.data.message });
      });
      centrifuge.connect();
    },
    toast() {
      this.$bvToast.toast(`check it`, {
        href: "/staff/notifications",
        title: "New notification",
        autoHideDelay: 5000,
      });
    },
    logout() {
      /* this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "home" });
      }); */
      this.$router.push({path: "logout"});
    },
    toMain() {
      this.$router.push({ name: "staff.home" }).catch(() => {});
    },
    mandatoryReviews() {
      axios
        .get("/staff/" + this.$store.state.staff.id + "/uncompleted-reviews")
        .then((response) => {
          localStorage.mandatoryReviewsCount = response.data.count;
          localStorage.offersList = JSON.stringify(response.data.offers);
          this.redirectToReview();
        })
    },
    redirectToReview() {
      if (
        localStorage.mandatoryReviewsCount > 0 &&
        !(
          this.$route.name === "staff.shift.offer.details" ||
          this.$route.name === "staff.shift.offer.review"
        )
      ) {
        this.$router.push({
          name: "staff.shift.offer.details",
          params: { id: JSON.parse(localStorage.offersList)[0] },
        });
      }
    },
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },

    isAllowed() {
      return this.$store.getters.isStaffApproved;
    },
    greeting() {
      const hour = moment().hour();
      if (hour > 16) {
        return "evening";
      }
      if (hour > 11) {
        return "afternoon";
      }
      return "morning";
    },
  },
  updated() {
    this.mandatoryReviews();
  },
  /* created() {
    this.isMobile = this.isMobileDevice;
  }, */
  beforeMount() {
    this.isMobile = this.$store.getters.isRNAppSession;
  },
  mounted() {
    eventBus.$on("changeTop", (data) => {
      this.title = data.title !== undefined ? data.title : null;
      this.desc = data.desc !== undefined ? data.desc : null;
      this.shortDesc = data.shortDesc !== undefined ? data.shortDesc : null;
      this.logo = data.logo !== undefined ? data.logo : null;
      this.topLogo = data.topLogo !== false;
      this.simple = data.simple === true;
      this.backUrl = data.backUrl !== undefined ? data.backUrl : null;
    });
    let loader = this.$loading.show();
    this.$store
      .dispatch("updateStaffData")
      .then(async () => {
        await this.$store.dispatch("updateUserData").then(() => {});
        this.loaded = true;
        this.staff = this.$store.state.staff;
        this.subscribeToWebSocketNotification();
      })
      .finally(() => {
        loader.hide();
        this.mandatoryReviews();
      });
  },
};
</script>