<template>
  <div class="row">
      <div class="col-md-12 text-center mt-5">
          <p class="text-center">
              <router-link :to="{name: 'signup.phone'}">Signup / Login</router-link>
          </p>
      </div>
  </div>
</template>

<script>

export default {
    name: 'Home',
    mounted() {
        this.$store.commit('changePageData', {title: 'Home'});
        let user = this.$store.state.userData;
        let restaurant = this.$store.state.restaurateur;
        let staff = this.$store.state.staff;
        if (!user && !staff && !restaurant) {
            return;
        }
        if (restaurant || (user && user.role === 'ROLE_RESTAURANT')) {
            this.$router.push({'name': 'restaurant.home'});
            return;
        }
        if (staff || (user && user.role === 'ROLE_STAFF')) {
            this.$router.push({'name': 'staff.home'});
        }
    }
}
</script>
