<template>
    <router-view></router-view>
</template>

<script>

export default {
    name: "Shift",
    data: function () {
        return {
        }
    },
    mounted() {
    }
}
</script>