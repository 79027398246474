<template>
    <div>
        <p class="change" v-b-modal.availability>Set availability</p>
        <b-modal id="availability"  ref="availability" size="md" title="Availability" :hide-footer="true">
            <div class="container">
                <div class="small">
                    Availability is based on the date the shift starts. Some shifts may run into the next day.
                    You will have the opportunity to accept all shifts before you start!
                </div>
                <div v-if="(forms.length === 0) || (forms.length === deleted.length)">
                    <b-badge variant="primary">Available all time</b-badge>
                    <p class="mt-3">OR</p>
                </div>
                <div class="row" v-for="(form, index) in forms" :key="index" v-if="deleted.indexOf(index) === -1">
                    <div class="col-md-12">
                        <span @click="remove(index)"><i class="fa fa-trash fa-2x" style='color: red' aria-hidden="true"></i> Delete Set Availability</span>
                        <time-row :form="form" :days="days"></time-row>
                    </div>
                </div>
                <p @click="add()" class="new-interval"><i class="fa fa-plus"></i> Add Availability</p>
                <div class="row justify-content-center">
                    <div class="col-4 mb-2">
                        <div class="input-group">
                            <label>Number of weeks</label>
                            <input class="form-control" type="number" v-model="weeks"/>
                        </div>
                    </div>
                </div>
                <p class="text-center mb-0">
                    <button @click="submit()" class="btn btn-primary" v-if="forms.length">Save</button>
                </p>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import {ServerErrors} from "@/mixins/ServerErrors";
import TimeRow from "@/components/Shift/Staff/Availability/TimeRow";

export default {
    name: "List",
    data () {
        return {
            deleted: [],
            forms: [],
            weeks: null
        }
    },
    mixins: [ServerErrors],
    components: {'time-row': TimeRow},
    methods: {
        add() {
            this.forms.push(this.dummyForm());
        },
        remove(i) {
            this.deleted.push(i);
        },
        dummyForm() {
            return {from: '07:00', to: '18:00', days: [], id: Math.random().toString(36).substring(7)};
        },
        submit() {
            let forms = this.forms.filter((elem, index) => {
                return this.deleted.indexOf(index) === -1;
            });

            forms = forms.map(elem => {
                return {
                    from: elem.from.split(':').slice(0, 2).join(':'),
                    to: elem.to.split(':').slice(0, 2).join(':'),
                    days: elem.days.map(day => parseInt(day))
                }
            });
            let loader = this.$loading.show();
            axios({
                method: 'POST',
                url: '/staff/set-availability',
                data: {periods: forms, weeks: this.weeks ? parseInt(this.weeks) : null}
            })
                .then(response => {
                    this.$store.dispatch('updateStaffData')
                        .then(() => {
                            this.$emit('updated');
                            loader.hide();
                            this.$bvModal.hide('availability');
                        });
                })
                .catch(error => {
                    loader.hide();
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.error ? error.response.data.error.message : 'Something went wrong'
                    });
                })
        }
    },
    computed: {
        days() {
            return {0: 'Sun', 1: 'Mon', 2: 'Tue', 3: 'Wed', 4: 'Thu', 5: 'Fri', 6: 'Sat'};
        }
    },
    mounted() {
        let availabilities = this.$store.state.staff.availabilities;
        availabilities.forEach((av) => this.forms.push(av));
    }
}
</script>