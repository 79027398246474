<template>
    <div class="row shiftdetails" :class="(isMobile ? 'cont-rnApp' : '')">
        <div class="cont" >
            <div class="row">
                <div class="col-7">
                    <div class="search">Searching...</div>
                </div>
                <div class="col-5">
                    <button class="btn btn-primary black" @click="cancel">Cancel <i class="fa fa-times"></i></button>
                </div>
            </div>

        </div>
        <div class="col-md-12">
            <search-staff-map
                v-if="office"
                :lat="office.address.lat"
                :lng="office.address.lng"
                :pulse="true"
                :class="(isMobile ? 'map h-100' : 'map')"
            >
            </search-staff-map>
        </div>
    </div>
</template>

<script>
import SearchStaffMap from "@/components/Shift/SearchStaffMap";
import {ServerErrors} from "@/mixins/ServerErrors";
import {eventBus} from "@/main";

export default {
    name: "Details",
    props: ['id'],
    data () {
        return {
            shift: {},
            restaurateur: null,
            office: null,
            centrifuge: null,
            isMobile: false
        }
    },
    components: {'search-staff-map': SearchStaffMap},
    mixins: [ServerErrors],
    methods: {
        cancel() {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0070ff',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, cancel it',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.cancelAction();
                }
            })
        },
        cancelAction() {
            let loader = this.$loading.show();
            axios
                .post('/shift/' + this.id + '/cancel', {})
                .then((response) => {
                    Swal.fire({
                        title: 'Canceled',
                        text: '',
                        icon: 'success'
                    })
                    this.$router.push({name: 'restaurant.home'});
                })
                .catch(error => {/*caught in the mixin*/})
                .finally(() => {loader.hide();});
        },
        subscribeToWebSocketNotification() {
            this.centrifuge = new Centrifuge(window.location.origin + process.env.VUE_APP_CENTRIFUGO_URL, {sockjs: SockJS});
            this.centrifuge.setToken(this.restaurateur.socket_token);
            this.centrifuge.subscribe('offer-accepted#' + this.restaurateur.restaurant.id, (message) => {
                let data = message.data;
                Swal.fire({
                    title: data.title,
                    text: 'Check it?',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push({name: 'restaurant.shift.search-results', params: {id: this.id}});
                    }
                });
            });
            this.centrifuge.connect();
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        eventBus.$emit('changeTop', {title: 'Finding the perfect match for you.', desc: ''});
        this.restaurateur = this.$store.state.restaurateur;
        this.office = this.restaurateur.restaurant.offices[0];
        axios
            .get('/shift/' + this.id + '/view')
            .then((response) => {
                this.shift = response.data.shift;
            });
        this.subscribeToWebSocketNotification();
    },
    destroyed() {
        this.centrifuge.disconnect();
    }
}
</script>