<template>
    <div class="row shiftmanager">
        <div class="col-md-12">
            <div class="cont" :class="(isMobile ? 'cont-rnApp' : '')" v-if="shift">
                <p class="title">{{ formattedStartDate }}</p>
                <hr>
                <div class="row headbottom">
                    <div class="col-6">
                        <p class="desc">{{ dateTimeFromString(shift.period.start).format('A') }} {{ shift.speciality.title }} Shift</p>
                        <p class="desc">
                            {{dateTimeFromString(shift.period.start).format('h:mm a') }}
                             -
                            {{dateTimeFromString(shift.period.start).add(shift.period.hours, 'hours').format('h:mm a') }}
                        </p>
                    </div>
                    <div class="col-6 text-right">
                        <p class="desc">{{ shift.office.name }}</p>
                        <p class="desc">{{ offersAmount === shift.vacancies_number ? 'Full' : 'Incomplete' }} {{ offersAmount }}/{{ shift.vacancies_number }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <p class="shifts_title">Shifts</p>
                    </div>
                    <div class="col-4 text-right" v-if="emptySlotsNumber >= 1">
                        <p class="empty">{{ emptySlotsNumber }} empty</p>
                    </div>
                </div>
                <div class="empty_offers" v-if="emptySlotsNumber >= 1">
                    <div class="container offer" v-for="index in emptySlotsNumber">
                        <div class="row">
                            <div class="col-4">
                                <p class="empty_name">Empty</p>
                            </div>
                            <div class="col-8 text-right">
                                <span class="speciality">{{ shift.speciality.title }}</span>
                                <span class="time">
                                 {{dateTimeFromString(shift.period.start).format('h:mm a') }}
                                 -
                                {{dateTimeFromString(shift.period.start).add(shift.period.hours, 'hours').format('h:mm a') }}
                            </span>
                            </div>
                            <div class="col-5">
                                <button class="btn btn-secondary" v-if="canManageVacancies" @click="addStaff()">Details</button>
                            </div>
                            <div class="col-7">
                                <button class="btn btn-primary blue" v-if="canManageVacancies" @click="addStaff()">Add {{ shift.speciality.title }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container offer" v-for="offer in shift.offers">
                    <div class="row">
                        <div class="col-4">
                            <p class="name">{{ offer.name_first + ' ' + offer.name_last }}</p>
                        </div>
                        <div class="col-8 text-right">
                            <span class="speciality">{{ offer.offer_speciality_title }}</span>
                            <span class="time">
                                {{ dateTimeFromString(offer.period_start_time).format('h:mm a') }}
                                 -
                                {{ dateTimeFromString(offer.period_end_time).format('h:mm a') }}
                            </span>
                        </div>
                        <div class="col-6 text-left">
                            <span class="time">Emergency Contact : </span>
                        </div>
                        <div class="col-6 text-right">
                            <p class="name">{{ offer.emergency_phone }}</p>
                        </div>
                        <div class="col-5">
                            <button class="btn btn-primary blue"  @click="showDetailsModal(offer)">Details</button>
                        </div>
                        <div class="col-7">
                            <button class="btn btn-secondary" v-if="canManageVacancies" v-b-modal.modal-1>Change {{ offer.offer_speciality_title }}</button>
                            <button class="btn btn-secondary mb-1" v-if="canSetDidntShowUp" @click="didntShowUp(offer.id)">Didn't show up</button>
                            <button class="btn btn-secondary" v-if="canLeaveReview" @click="leaveReview(offer.id)">Review</button>
                        </div>
                        <div class="col-12 text-center" v-if="offer.status === 'time off request'">
                            <button @click="confirmTimeOff(offer)" class="btn btn-danger">Confirm time off request</button>
                        </div>
                    </div>
                    <b-modal id="modal-1" :title="`Change ${offer.offer_speciality_title}`"  @ok="changeStaff(offer.id)">
                        <p class="my-4">
                            This will cancel the shift for accepted bartender! are you sure you want to change the bartender?
                        </p>
                    </b-modal>
                </div>
                <div class="container slot" v-if="canManageVacancies">
                    <div class="row">
                        <div class="col-6">
                            <p class="title">Add Slot</p>
                        </div>
                        <div class="col-6 text-right">
                            <i class="fa fa-plus" @click="addSlot"></i>
                        </div>
                    </div>
                </div>
                <b-modal id="modal-details" ref="modal-details" modal-class="modal-details" :hide-header="true" :hide-footer="true" content-class="shadow" title="">
                    <div class="white-block container">
                        <div class="row">
                            <div class="col-6">
                                <p class="title">Shift Position</p>
                            </div>
                            <div class="col-6">
                                <p class="subtitle">Select One</p>
                            </div>
                            <div class="col-12">
                                <bubble-input
                                :bubbles="types"
                                v-model="form.speciality"
                                :multiple="false"
                                ></bubble-input>
                                <div class="invalid-feedback" v-if="getError('speciality')" v-text="getError('speciality')"></div>
                            </div>
                        </div>
                    </div>
                    <div class="white-block container">
                        <div class="row">
                            <div class="col-6">
                                <p class="title">Shift Start</p>
                            </div>
                            <div class="col-6">
                                <p class="subtitle">Select shift Start</p>
                            </div>
                            <div class="col-12">
                                <b-form-input v-model="form.start_time" type="range" min="0" max="23" step="1"></b-form-input>
                                <p class="rangevalue">{{ valueFromRangeToTime(form.start_time) }}</p>
                                <div class="invalid-feedback" v-if="getError('start_time')" v-text="getError('start_time')"></div>
                            </div>
                        </div>
                    </div>
                    <div class="white-block container">
                        <div class="row">
                            <div class="col-6">
                                <p class="title">Shift End</p>
                            </div>
                            <div class="col-6">
                                <p class="subtitle">Select shift End</p>
                            </div>
                            <div class="col-12">
                                <b-form-input v-model="form.end_time" type="range" min="0" max="23" step="1"></b-form-input>
                                <p class="rangevalue">{{ valueFromRangeToTime(form.end_time) }}</p>
                                <div class="invalid-feedback" v-if="getError('end_time')" v-text="getError('end_time')"></div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-7">
                                <button class="btn btn-primary blue" @click="confirmDetails()">Confirm Details</button>
                            </div>
                            <div class="col-5">
                                <button class="btn btn-secondary" @click="hideDetailsModal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
import {eventBus} from "@/main";
import {Shift} from "@/mixins/Shift";
import {ServerErrors} from "@/mixins/ServerErrors";
import BubbleInput from "@/components/FormElement/BubbleInput";

export default {
    name: "ShiftManager",
    props: ['id'],
    data() {
        return {
            types: [],
            form: {
                id: null,
                speciality: null,
                start_time: null,
                end_time: null
            }
        }
    },
    mixins: [Shift, ServerErrors],
    components: {'bubble-input': BubbleInput},
    methods: {
        confirmTimeOff(offer) {
            let loader = this.$loading.show();
            axios
                .post('/shift/offer/' + offer.id + '/accept-time-off', {})
                .then((response) => {
                    Swal.fire({
                        title: 'Confirmed',
                        text: '',
                        icon: 'success'
                    });
                    this.getShift(true);
                })
                .catch(error => {/*caught in the mixin*/loader.hide();})
                .finally(error => {loader.hide();});
        },
        showDetailsModal(offer) {
            this.form.id = offer.id;
            this.form.speciality = offer.offer_speciality_id;
            this.form.start_time = moment(offer.period_start_time).format('H');
            this.form.end_time = moment(offer.period_end_time).format('H');
            this.$refs['modal-details'].show()
        },
        hideDetailsModal() {
            this.$refs['modal-details'].hide()
        },
        dateTimeFromString(datetime) {
            return moment(datetime);
        },
        addSlot() {
            let loader = this.$loading.show();
            axios
                .post('/shift/' + this.id + '/add-vacancy')
                .then((response) => {
                    Swal.fire({
                        title: 'Added',
                        text: '',
                        icon: 'success'
                    });
                    this.getShift(true);
                })
                .catch(error => {})
                .finally(error => {loader.hide();});
        },
        addStaff() {
            this.$router.push({name: 'restaurant.shift.search-results', params: {'id': this.id}})
        },
        changeStaff(offerId) {
            let loader = this.$loading.show();
            axios
                .post('/shift/offer/' + offerId + '/decline-acceptance')
                .then((response) => {
                    this.addStaff();
                })
                .finally(error => {loader.hide();});
        },
        didntShowUp(offerId) {
            let loader = this.$loading.show();
            axios
                .post('/shift/offer/' + offerId + '/didnt-show-up')
                .then((response) => {
                    this.addStaff();
                })
                .finally(error => {loader.hide();});
        },
        valueFromRangeToTime(value) {
            return moment(value, 'H').format('ha');
        },
        confirmDetails() {
            let loader = this.$loading.show();
            axios
                .post('/shift/offer/' + this.form.id + '/change',
                    {
                        speciality: this.form.speciality,
                        start_time: moment(this.form.start_time, 'H').format('HH:mm:ss'),
                        end_time: moment(this.form.end_time, 'H').format('HH:mm:ss')
                    })
                .then((response) => {
                    this.getShift(true);
                    this.hideDetailsModal()
                })
                .finally(error => {loader.hide();});
        },
        leaveReview(offerId) {
            this.$router.push({name: 'restaurant.shift.offer.review', params: {'shiftId': this.id, 'id': offerId}});
        }
    },
    computed: {
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        eventBus.$emit('changeTop', {
            title: 'SHIFT MANAGER',
            desc: '',
            simple: true
        });
        this.getShift(true);
        axios
            .get('/data/specialities')
            .then((response) => {
                this.types = response.data;
            });
    }
}
</script>