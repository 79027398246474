<template>
    <div class="row justify-content-center transferinfo">
        <div class="col-md-6 cont" :class="(isMobile ? 'cont-rNApp' : '')" v-if="!hasTransferData || showCardForm">
            <div class="input-group">
                <b-form-input
                    v-model="form.routingNumber"
                    type="text"
                    class="inp"
                    v-mask="'##########'"
                    placeholder="Routing Number"
                ></b-form-input>
                <div class="invalid-feedback" v-if="getError('routingNumber')" v-text="getError('routingNumber')"></div>
            </div>
            <div class="input-group">
                <b-form-input
                    v-model="form.accountNumber"
                    type="text"
                    class="inp"
                    v-mask="'################'"
                    placeholder="Account Number"
                ></b-form-input>
                <div class="invalid-feedback" v-if="getError('accountNumber')" v-text="getError('accountNumber')"></div>
            </div>
            <div class="text-center">
                <b-button variant="primary" class="black" type="submit" @click.prevent.stop="create()">Attach</b-button>
            </div>
        </div>
        <div class="col-md-6 cont notop" :class="(isMobile ? 'cont-rNApp' : '')" v-if="hasTransferData && !showCardForm">
            <div class="rowaction">
                <p class="title">Bank data to get paid</p>
                <div class="whiteb">
                    <p class="title" @click="showCardForm = true">Change</p>
                </div>
            </div>
            <div class="text-center mt-3">
                <router-link :to="{name: 'staff.home'}" class="btn btn-primary black">Go to the Dashboard</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {ServerErrors} from "@/mixins/ServerErrors";
import {eventBus} from "@/main";
export default {
    name: "TransferInfo",
    mixins: [ServerErrors],
    data() {
        return {
            errors: [],
            hasTransferData: false,
            showCardForm: false,
            form: {
                routingNumber: null,
                accountNumber: null
            },
            isMobile: false
        }
    },
    methods: {
        create() {
            let loader = this.$loading.show();
            this.errors = [];
            axios({
                method: 'POST',
                url: '/staff/onboarding/save-transfer-info',
                data: {
                    routingNumber: this.form.routingNumber ?? '',
                    accountNumber: this.form.accountNumber ?? ''
                }
            })
                .then(() => {
                    this.$store.dispatch('updateStaffData')
                        .then(() => {
                            this.showCardForm = false;
                            this.hasTransferData = true;
                            Swal.fire({
                                title: 'Attached',
                                text: '',
                                icon: 'success'
                            });
                            this.$router.push({ name: 'staff.home' });
                        });
                })
                .finally(() => {
                    loader.hide();
                });
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        let staff = this.$store.state.staff;
        if (!staff.email || !staff.name.first) {
            Swal.fire({
                title: '',
                text: 'Please add profile info first',
                icon: 'info'
            })
            this.$router.push({name: 'staff.onboarding.profile'});
        }
        eventBus.$emit('changeTop', {title: 'Payment Info', simple: true, topLogo: false});
        this.hasTransferData = staff.wallet.has_transfer_data;
    }
}
</script>