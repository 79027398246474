<template>
    <div class="row offersresult">
        <div class="col-md-12">
            <div class="cont" :class="(isMobile ? 'cont-rNApp' : '')">
                <div class="descrow row" v-for="offer in offers" v-if="offers && (acceptedIds.indexOf(offer.id) === -1)">
                    <div class="col-12">
                        <p class="title"><span>{{ offer.speciality }}</span> <span>${{ offer.cost }}</span> {{ offer.experience_value }}  </p>
                        <p class="name">{{ offer.staff_name }} </p>
                    </div>
                    <div class="col-12">
                        <button class="btn btn-primary blue" @click="confirmRequest(offer.id)">Confirm Work Request</button>
                    </div>
                </div>
                <div class="row" v-if="shift && shift.status === 'matching'">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary black" @click="cancel">Cancel Shift <i class="fa fa-times"></i></button>
                    </div>
                </div>
            </div>
            <search-staff-map
                v-if="office"
                :lat="office.address.lat"
                :lng="office.address.lng"
                :pulse="false"
                class="map"
                :reduceHeightByPixels="(isMobile ? '0' : '')"
                >
            </search-staff-map>
        </div>
    </div>
</template>

<script>
import SearchStaffMap from "@/components/Shift/SearchStaffMap";
import {eventBus} from "@/main";
import {Shift} from "@/mixins/Shift";

export default {
    name: "SearchResult",
    props: ['id'],
    mixins: [Shift],
    data () {
        return {
            offers: null,
            office: null,
            acceptedIds: []
        }
    },
    components: {'search-staff-map': SearchStaffMap},
    methods: {
        confirmRequest(id) {
            let loader = this.$loading.show();
            axios
                .post('/shift/offer/' + id + '/accept-acceptance')
                .then((response) => {
                    this.acceptedIds.push(id);
                    Swal.fire({
                        title: 'Accepted',
                        icon: 'success'
                    });
                    if (this.shift.vacancies_number <= (this.acceptedIds.length + this.offersAmount)) {
                        this.redirectToManager();
                        return;
                    }
                    if (Object.keys(this.offers).length - this.acceptedIds.length < 1) {
                        this.askGoToManager();
                    }
                    //this.redirectToManager();
                })
                .finally(() => {loader.hide()});
        },
        getOffers(loader) {
            axios
                .get('/shift/' + this.id + '/accepted-offers')
                .then((response) => {
                    this.offers = response.data.offers;
                    if (Object.keys(this.offers).length < 1) {
                        if (this.shift.status === 'matching') {
                            this.askGoToManager();
                        } else {
                            this.redirectToManager();
                        }
                    }
                })
                .catch(() => {
                    this.redirectToManager();
                })
                .finally(() => {loader.hide()});
        },
        redirectToManager() {
            this.$router.push({name: 'restaurant.shift.manager', params: {id: this.id}});
        },
        askGoToManager() {
            Swal.fire({
                title: '',
                text: 'Please be patient as we finalize our search. Do you still want to go to the shift manager?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.redirectToManager();
                }
            });
        },
        cancel() {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0070ff',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, cancel it',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.cancelAction();
                }
            })
        },
        cancelAction() {
            let loader = this.$loading.show();
            axios
                .post('/shift/' + this.id + '/cancel', {})
                .then((response) => {
                    Swal.fire({
                        title: 'Canceled',
                        text: '',
                        icon: 'success'
                    })
                    this.$router.push({name: 'restaurant.home'});
                })
                .catch(error => {/*caught in the mixin*/})
                .finally(() => {loader.hide();});
        },
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
        console.log(this.isMobile);
    },
    mounted() {
        eventBus.$emit('changeTop', {
            title: 'Heres what we found.',
            desc: '',
            simple: true,
            address: true,
            long: true
        });
        this.office = this.$store.state.restaurateur.restaurant.offices[0];
        let loader = this.$loading.show();
        this.getShift(true)
            .then(() => {
                if (this.shift.status !== 'matching' && this.shiftStartsInMinutes < 1) {
                    /*Swal.fire({
                        title: '',
                        text: 'Search inactive.',
                        icon: 'error'
                    });*/
                    this.redirectToManager();
                }
                this.getOffers(loader);
            })
            .catch(() => {
                loader.hide();
            })
    }
}
</script>