<template>
    <div class="row role">
        <div class="col-12">
            <p class="label">How can we serve you?</p>
        </div>
        <div class="col-md-6">
            <div class="card" @click="submit('staff')">
                <div class="card-header">
                    <p class="title">Bartender/Staff</p>
                    <p class="subtitle">Get shifts, get paid</p>
                </div>
                <div class="card-body">
                    <p class="card-text"><i class="fa fa-check-circle" aria-hidden="true"></i> Find shifts in your area</p>
                    <p class="card-text"><i class="fa fa-check-circle" aria-hidden="true"></i> Trade shifts with your team</p>
                    <p class="card-text"><i class="fa fa-check-circle" aria-hidden="true"></i> Get bartender certified</p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card" @click="submit('restaurant')">
                <div class="card-header">
                    <p class="title">Bar/Restaurant</p>
                    <p class="subtitle">On-Demand Scheduling & Staffing</p>
                </div>
                <div class="card-body">
                    <p class="card-text"><i class="fa fa-check-circle" aria-hidden="true"></i> Find bartenders to fill shifts</p>
                    <p class="card-text"><i class="fa fa-check-circle" aria-hidden="true"></i> Manage your current team</p>
                    <p class="card-text"><i class="fa fa-check-circle" aria-hidden="true"></i> Schedule shifts in-app</p>
                </div>
            </div>
        </div>
        <div class="col-12">
            <p class="desc">
                Selecting one of the options above will lock your account to that type. This cannot be undone.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Role.vue",
    data() {
        return {
            role: ''
        }
    },
    methods: {
        submit(role) {
            this.role = role
            this.$store.commit('changeSignUpRole', role)
            this.$router.push({'name': 'signup.password'})
        }
    },
    mounted() {
        this.$store.commit(
            'changePageData',
            {title: 'Create your account', back: this.$router.resolve({name: 'signup.phone'}).href}
            );
        if (!this.$store.state.signup.phone) {
            this.$router.push({'name': 'signup.phone'})
        }
    }
}
</script>