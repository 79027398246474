<template>
    <div class="row calendar">
        <div class="col-12">
            <div class="cont">
                <div class="dcalendar black">
                    <b-calendar
                        id="calendarel"
                        :hide-header="true"
                        v-model="selectedDate"
                        :date-info-fn="dateClass"
                        :min="new Date()"
                        locale="en-US"
                        :show-decade-nav="false"
                        @selected="clickDate()"
                        @context="onContext"
                    >
                    </b-calendar>
                </div>
                <div>
                    <div class="selectedDate row">
                        <div class="col-6">
                            {{ formattedSelectedDate }}
                        </div>
                        <div class="col-6 text-right">
                            <a href="#" class="ulink d-inline" v-if="isAvailable(new Date(selectedDate))" @click.prevent="addBusyness()">Busy on this day?</a>
                            <a href="#" class="ulink d-inline" v-else-if="offers.length === 0" v-b-modal.av>Available on this day?</a>
                        </div>
                    </div>
                    <hr/>
                    <div class="container-fluid">
                        <b-modal id="av" ref="av" size="md" title="Availability" :hide-footer="true">
                            <div class="container availability-modal">
                                <div class="row">
                                    <div class="col-6">
                                        <label class="mt-2">From</label>
                                        <b-form-timepicker v-model="avForm.from" locale="en" minutes-step="30" label-close-button="OK" :hide-header="true"></b-form-timepicker>
                                    </div>
                                    <div class="col-6">
                                        <label class="mt-2">To</label>
                                        <b-form-timepicker v-model="avForm.to" locale="en" minutes-step="30" label-close-button="OK" :hide-header="true"></b-form-timepicker>
                                    </div>
                                </div>
                                <p class="text-center mb-0 mt-3">
                                    <button @click="addSpecificAvailability()" class="btn btn-primary">Save</button>
                                </p>
                            </div>
                        </b-modal>
                    </div>
                    <div class="container shift" :class="{'black': offer.status !== 'time off request', 'grey': offer.status === 'time off request'}" v-for="offer in offers">
                        <div class="row" v-if="offer.status === 'time off request'">
                            <div class="col-12">
                                <p class="switch_title">Pending Approval</p>
                                <p class="switch_desc">Your time off request has been sent to the team manager for approval.</p>
                                <button class="btn btn-secondary disabled" disabled >Pending time off approval...</button>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-7">
                                <p class="desc">{{ dateTimeFromString(offer.period_start_time).format('A') }} {{ offer.offer_speciality_title }} Shift</p>
                                <p class="desc">{{ dateTimeFromString(offer.period_start_time).format('MMMM Do [at] h:mm a') }}</p>
                            </div>
                            <div class="col-5">
                                <p class="desc">{{ offer.name }}</p>
                                <p class="desc">{{ offersAmountByShift(offer) === offer.vacancies_number ? 'Full' : 'Incomplete' }} {{ offersAmountByShift(offer) }}/{{ offer.vacancies_number }}</p>
                            </div>
                            <div class="col-12">
                                <span class="bartender" v-for="index in 2" v-if="offer.offers[index - 1] !== undefined">
                                    {{ offer.offers[index - 1].name_first + ' ' + offer.offers[index - 1].name_last.charAt(0) }}
                                </span>
                                <span v-if="offersAmountByShift(offer) > 3">+{{ offersAmountByShift(offer) - 3}}</span>
                                <button class="btn btn-secondary" @click="showDetails(offer.id)">Details</button>
                                <button class="btn btn-danger" v-if="offer.status !== 'time off request'" @click="requestSwitch(offer)">Request Switch</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
    name: "Calendar",
    data () {
        return {
            selectedDate: moment().format('YYYY-MM-DD'),
            dates: [],
            offers: [],
            availableDays: [],
            avForm: {
                from: '07:00',
                to: '18:00'
            },
            businesses: [],
            specificAvailableDays: []
        }
    },
    methods: {
        dateClass(ymd, date) {
            const day = moment(date).format('YYYY-MM-DD');
            let classNames = '';
            if (this.dates.indexOf(day) !== -1) {
                classNames += 'dotted ';
            }
            if (this.isAvailable(date)) {
                classNames += 'available ';
            }
            return classNames;
        },
        isAvailable(date) {
            const day = moment(date).format('YYYY-MM-DD');
            return (this.isAvailableBasic(date) || this.isSpecificAvailable(day)) && !this.isBusy(day);
        },
        isAvailableBasic(date) {
            return this.availableDays.indexOf(date.getDay()) !== -1;
        },
        isSpecificAvailable(date) {
            return this.specificAvailableDays.indexOf(date) !== -1;
        },
        isBusy(date) {
            return this.businesses.indexOf(date) !== -1;
        },
        clickDate() {
            this.getOffers();
        },
        onContext() {},
        getDates() {
            axios
                .get('/shift/offer/dates')
                .then((response) => {
                    this.dates = response.data.dates;
                })
                .catch(() => {})
                .finally(() => {
                    this.onContext()
                });
        },
        getOffers() {
            axios
                .get('/shift/offer/offers-by-date/' + this.formattedSelectedDateSystem)
                .then((response) => {
                    this.offers = response.data.offers;
                })
                .catch(() => {})
                .finally(() => {
                    this.onContext()
                });
        },
        offersAmountByShift(offer) {
            return Object.keys(offer.offers).length;
        },
        dateTimeFromString(datetime) {
            return moment(datetime);
        },
        showDetails(id) {
            this.$router.push({name: 'staff.shift.offer.details', 'params': {'id': id}})
        },
        requestSwitch(offer) {
            let loader = this.$loading.show();
            axios
                .post('/shift/offer/' + offer.id + '/request-time-off', {reason: '-'})
                .then((response) => {
                    Swal.fire({
                        title: 'Requested',
                        text: 'Please wait for manager confirmation.',
                        icon: 'success'
                    })
                    this.getOffers();
                })
                .catch(() => {})
                .finally(() => {
                    loader.hide();
                });
        },
        findAvailableDays() {
            const staff = this.$store.state.staff;
            staff.availabilities.forEach((av) => {
                this.availableDays = this.availableDays.concat(av.days);
            });
            this.specificAvailableDays = staff.specific_availabilities;
            this.businesses = staff.busynesses;
        },
        addBusyness() {
            let loader = this.$loading.show();
            axios
                .post('/staff/add-busyness', {date: this.selectedDate})
                .then((response) => {
                    this.businesses.push(this.selectedDate);
                    this.specificAvailableDays = this.specificAvailableDays.filter((date) => date !== this.selectedDate);
                })
                .catch(() => {})
                .finally(() => {
                    loader.hide();
                });
        },
        addSpecificAvailability() {
            let loader = this.$loading.show();
            axios
                .post('/staff/add-specific-availability', {
                    date: this.selectedDate,
                    from: this.avForm.from.split(':').slice(0, 2).join(':'),
                    to: this.avForm.to.split(':').slice(0, 2).join(':')
                })
                .then((response) => {
                    this.$refs['av'].hide();
                    this.specificAvailableDays.push(this.selectedDate);
                    this.businesses = this.businesses.filter((date) => date !== this.selectedDate);
                })
                .catch(() => {})
                .finally(() => {
                    loader.hide();
                });
        }
    },
    computed: {
        formattedSelectedDate() {
            return moment(this.selectedDate).format('dddd, MMMM Do');
        },
        formattedSelectedDateSystem() {
            return moment(this.selectedDate).format('YYYY-MM-DD');
        }
    },
    mounted() {
        eventBus.$emit('changeTop', {
            title: ' ',
            desc: ''
        });
        this.getDates();
        this.getOffers();
        this.findAvailableDays();
    }
}
</script>