<template>
    <div class="container shift-w" v-if="shift" :class="{'dark':  dateTimeFromString().format('A') === 'PM'}">
        <div class="row">
            <div class="col-7">
                <p class="desc" v-if="shift.speciality_title == 'Bartender'">{{ dateTimeFromString().format('A') }} Bartender Shift</p>
                <p class="desc" v-else>{{ dateTimeFromString().format('A') }} {{ shift.speciality_title }} Shift</p>
                <p class="desc">{{ getTime }}</p>
                <span class="bartender" v-for="index in 3" v-if="shift.offers[index - 1] !== undefined">
                    {{ shift.offers[index - 1].name_first + ' ' + shift.offers[index - 1].name_last.charAt(0) }}
                </span>
                <span v-if="offersAmount > 2">+{{ offersAmount - 2}}</span>
            </div>
            <div class="col-5 text-right">
                <p class="big">{{ dateTimeFromString().format('D') }}</p>
                <p class="day">{{ dateTimeFromString().format('dddd') }}</p>
            </div>
            <div class="col-6">
                <p class="calendar-p" @click="$router.push({name: 'restaurant.shift.calendar'})"><i class="fa fa-calendar"></i> Calendar</p>
            </div>
            <div class="col-6 text-right">
                <p class="details" @click="showDetails()">View Details</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ShiftWidget",
    data () {
        return {
            shift: null
        }
    },
    methods: {
        getShift() {
            axios
                .get('/shift/closest-shift')
                .then((response) => {
                    this.shift = response.data.shift;
                })
                .catch(() => {});
        },
        dateTimeFromString() {
            return moment(this.shift.period_start_time);
        },
        showDetails() {
            if (this.offersAmount >= this.shift.vacancies_number) {
                this.$router.push({name: 'restaurant.shift.manager', 'params': {'id': this.shift.id}})
                return;
            }
            this.$router.push({name: 'restaurant.shift.search-results', 'params': {'id': this.shift.id}})
        }
    },
    computed: {
        offersAmount() {
            return Object.keys(this.shift.offers).length;
        },
        getTime() {
            let mTime = moment(this.shift.period_start_time);
            if (mTime.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                return "Today at " + mTime.format('h:mm a');
            }
            return mTime.format('MMMM Do [at] h:mm a');
        },
    },
    mounted() {
        this.getShift();
    }
}
</script>