<template>
  <div class="col-12 cont" :class="(isMobile ? 'cont-rnApp' : '')" v-if="notifications !== {}">
    <div class="notif-bubble" v-if="unreadAmount !== null">
      <span>{{ unreadAmount }}</span>
    </div>
    <div class="container searchblock" :class="{ dark: dark }">
      <div class="row">
        <div class="col-10">
          <input type="text" v-model="search" placeholder="Search you feed" />
        </div>
        <div class="col-2">
          <i class="fa fa-search" @click="searchSubmit"></i>
        </div>
      </div>
    </div>
    <div class="alert alert-warning" role="alert">
      <b-form-checkbox
        v-model="optOut"
        name="check-button"
        switch
        @change="optOutText()"
      >
        opt out of text messages
      </b-form-checkbox>
    </div>
    <b-tabs v-model="tabIndex">
      <b-tab
        title="Staff"
        active
        :title-link-class="linkClass(0)"
        v-if="roles.includes('ROLE_STAFF')"
      >
        <div v-if="Object.keys(staffNotificationArr).length > 0">
          <div
            v-for="(notificationList, index) in staffNotificationArr"
            :key="index"
          >
            <p class="label">{{ index }}</p>
            <div
              class="container"
              v-for="(notification, index2) in notificationList"
              :key="index + '-' + index2"
            >
              <div class="row" @click="clickNotification(notification)">
                <div class="col-1">
                  <p class="parrow"><i class="fa fa-long-arrow-right"></i></p>
                  <p class="parrow bottom">
                    <i class="fa fa-long-arrow-left"></i>
                  </p>
                </div>
                <div class="col-9">
                  <p class="title">{{ notification.title }}</p>
                  <p class="desc">{{ notification.text }}</p>
                  <div v-if="!notification.is_action_done">
                    <button
                      v-for="(action, ind) in notification.actions"
                      class="actionbtn btn"
                      v-bind:key="ind"
                      :class="actionButtonClass(action)"
                      @click="doAction(action)"
                    >
                      {{ action.title }}
                    </button>
                  </div>
                  <p class="date">{{ getTime(notification.date) }}</p>
                </div>
                <div class="col-1 d-flex align-items-center">
                  <a
                    class="link"
                    ><i class="fa fa-angle-right"></i
                  ></a>
                </div>
                <div class="col-1"></div>
              </div>
            </div>
          </div>

          <div class="text-center mt-2">
            <button
              class="btn btn-primary black"
              v-if="staffPage * staffPerPage < staffTotal"
              @click="getStaffNotifications"
            >
              Load more
            </button>
          </div>
        </div>
        <div v-else>
          <p class="mt-3">No Notifications Available!!</p>
        </div>
      </b-tab>
      <b-tab
        title="Restaurant"
        :title-link-class="linkClass(1)"
        v-if="roles.includes('ROLE_RESTAURANT')"
      >
        <div v-if="Object.keys(restaurantNotificationArr).length > 0">
          <div
            v-for="(notificationList, index) in restaurantNotificationArr"
            :key="index"
          >
            <p class="label">{{ index }}</p>
            <div
              class="container"
              v-for="(notification, index2) in notificationList"
              :key="index + '-' + index2"
            >
              <div class="row" @click="clickNotification(notification)">
                <div class="col-1">
                  <p class="parrow"><i class="fa fa-long-arrow-right"></i></p>
                  <p class="parrow bottom">
                    <i class="fa fa-long-arrow-left"></i>
                  </p>
                </div>
                <div class="col-9">
                  <p class="title">{{ notification.title }}</p>
                  <p class="desc">{{ notification.text }}</p>
                  <div v-if="!notification.is_action_done">
                    <button
                      v-for="(action, ind) in notification.actions"
                      class="actionbtn btn"
                      v-bind:key="ind"
                      :class="actionButtonClass(action)"
                      @click="doAction(action)"
                    >
                      {{ action.title }}
                    </button>
                  </div>
                  <p class="date">{{ getTime(notification.date) }}</p>
                </div>
                <div class="col-1 d-flex align-items-center">
                  <a
                    class="link"
                    ><i class="fa fa-angle-right"></i
                  ></a>
                </div>
                <div class="col-1"></div>
              </div>
            </div>
          </div>

          <div class="text-center mt-2">
            <button
              class="btn btn-primary black"
              v-if="restaurantPage * restaurantPerPage < restaurantTotal"
              @click="getRestaurantNotifications"
            >
              Load more
            </button>
          </div>
        </div>
        <div v-else>
          <p class="mt-3">No Notifications Available!!</p>
        </div>
      </b-tab>
    </b-tabs>
  </div>
  <div class="col-12 cont" v-else>
    <b-alert show variant="danger">There are no notifications yet</b-alert>
  </div>
</template>

<script>
import { eventBus } from "@/main";
export default {
  name: "Notifications",
  props: ["notificationsUrl", "viewUrl", "dark"],
  data() {
    return {
      roles:
        this.$store.state.userData && this.$store.state.userData.totalRoles,
      staffNotifications: {},
      restaurantNotifications: {},
      tabIndex: 0,
      notifications: {},
      staffPage: 0,
      restaurantPage: 0,
      staffTotal: null,
      restaurantTotal: null,
      staffPerPage: null,
      restaurantPerPage: null,
      tz: null,
      unreadAmount: null,
      times: [],
      search: "",
      lastSearch: "",
      optOut: false,
      restaurantNotificationArr: {},
      staffNotificationArr: {},
      isMobile: false
    };
  },
  methods: {
    clickNotification(notification) {
      if (notification.link == null || (notification.link && notification.link.length == 0)) {
        return false;
      }
      window.open(notification && notification.link, '_self')
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-primary", "text-light"];
      } else {
        return ["bg-light", "text-info"];
      }
    },
    getTime(time) {
      let mTime = moment.tz(time, "UTC").clone().tz(this.tz);
      if (mTime.format("YYYY-MM-DD") === this.today) {
        return "Today at " + mTime.format("h:mm a");
      }
      if (moment(time).format("YYYY-MM-DD") === this.yesterday) {
        return "Yesterday at " + mTime.format("h:mm a");
      }
      if (
        mTime.format("YYYY-MM-DD") >
        moment().subtract(7, "days").format("YYYY-MM-DD")
      ) {
        return mTime.format("dddd");
      }
      return mTime.format("MMM Do");
    },
    getTimeTitle(time) {
      time = moment(time).format("YYYY-MM-DD");
      if (time === this.today) {
        return "Today";
      }
      if (moment(time).isSame(new Date(), "week")) {
        return "This Week";
      }
      let weekStart = moment().startOf("week").format("YYYY-MM-DD");
      let previousWeekStart = moment()
        .startOf("week")
        .subtract(1, "week")
        .format("YYYY-MM-DD");
      if (
        moment(time).format("YYYY-MM-DD") < weekStart &&
        moment(time).format("YYYY-MM-DD") >= previousWeekStart
      ) {
        return "Last Week";
      }
      if (moment(time).isSame(new Date(), "month")) {
        return "This Month";
      }
      if (moment(time).isSame(new Date(), "year")) {
        return "This Year";
      }
      return "More than year";
    },
    async getRestaurantNotifications() {
      await axios
        .get(
          this.notificationsUrl +
            "?page=" +
            (this.restaurantPage + 1) +
            "&search=" +
            this.search +
            "&notification_type=" +
            "Restaurant"
        )
        .then((response) => {
          console.log(response);
          let notificationsData = response.data.notifications;
          notificationsData.forEach((notification) => {
            this.addRestaurantNotification(notification);
          });
          this.restaurantPage = response.data.page;
          this.restaurantTotal = response.data.total;
          this.restaurantPerPage = response.data.per_page;
          this.view(notificationsData);
        });
    },
    async getStaffNotifications() {
      await axios
        .get(
          this.notificationsUrl +
            "?page=" +
            (this.staffPage + 1) +
            "&search=" +
            this.search +
            "&notification_type=" +
            "Staff"
        )
        .then((response) => {
          let notificationsData = response.data.notifications;
          notificationsData.forEach((notification) => {
            this.addStaffNotification(notification);
          });
          this.staffPage = response.data.page;
          this.StaffTotal = response.data.total;
          this.staffPerPage = response.data.per_page;
          this.view(notificationsData);
        });
    },
    addStaffNotification(notification) {
      let title = this.getTimeTitle(notification.date);
      if (this.staffNotifications[title] === undefined) {
        this.staffNotifications[title] = [];
      }
      this.staffNotifications[title].push(notification);
      this.staffNotificationArr = this.staffNotifications;
    },
    addRestaurantNotification(notification) {
      let title = this.getTimeTitle(notification.date);
      if (this.restaurantNotifications[title] === undefined) {
        this.restaurantNotifications[title] = [];
      }
      this.restaurantNotifications[title].push(notification);
      this.restaurantNotificationArr = this.restaurantNotifications;
    },
    view(notifications) {
      let ids = [];
      notifications.forEach((notification) => {
        ids.push(notification.id);
      });
      if (ids.length === 0) {
        return;
      }
      axios.post(this.viewUrl, { ids: ids }).then((response) => {
        setTimeout(() => {
          this.getUnreadAmount();
        }, 3000);
      });
    },
    getUnreadAmount() {
      axios.get("/data/notifications/unread-amount").then((response) => {
        this.unreadAmount = response.data.amount;
      });
    },
    searchSubmit() {
      if (this.tabIndex === 0) {
        if (this.search !== this.lastSearch) {
          this.staffPage = 0;
          this.staffNotifications = {};
          this.staffTotal = null;
          this.staffPerPage = null;
          this.lastSearch = this.search;
          this.getStaffNotifications();
        }
      } else {
        if (this.search !== this.lastSearch) {
          this.restaurantPage = 0;
          this.restaurantNotifications = {};
          this.staffTotal = null;
          this.staffPerPage = null;
          this.lastSearch = this.search;
          this.getRestaurantNotifications();
        }
      }
    },
    doAction(action) {
      if (["view_shift_staff"].indexOf(action.type) !== -1) {
        this.markActionDone(action.notification_id, () => {
          location.href = action.link;
        });
        return;
      }
      let loader = this.$loading.show();
      axios
        .post(action.link, {})
        .then((response) => {
          Swal.fire({
            title: "Done",
            icon: "success",
          });
          this.markActionDone(action.notification_id);
          let notifications = this.notifications;
          Object.keys(notifications).forEach((index) => {
            for (let i = 0; i < notifications[index].length; i++) {
              if (notifications[index][i].id !== action.notification_id) {
                continue;
              }
              notifications[index][i]["is_action_done"] = true;
              this.notifications = {};
              this.$nextTick(() => {
                this.notifications = notifications;
              });
              return;
            }
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
    markActionDone(notificationId, callback) {
      let loader = this.$loading.show();
      axios
        .post("/data/notifications/" + notificationId + "/mark-done", {})
        .then((response) => {
          if (callback) {
            callback();
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    actionButtonClass(action) {
      if (
        ["decline_shift_staff", "decline_time_off"].indexOf(action.type) !== -1
      ) {
        return "btn1";
      }
      return "btn0";
    },
    optOutText() {
      let loader = this.$loading.show();
      axios
        .post("/user/opt-out-text", {})
        .then((response) => {
          Swal.fire({
            title: "Done",
            icon: "success",
          });
          this.$store.dispatch("updateUserData", {}).then(() => {});
        })
        .catch(() => {
          this.optOut = !this.optOut;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  computed: {
    isNoNotifications() {
      return Object.keys(this.notifications).length === 0;
    },
    today() {
      return moment().format("YYYY-MM-DD");
    },
    yesterday() {
      return moment().subtract(1, "days").format("YYYY-MM-DD");
    },
  },
  async created() {
    await this.getStaffNotifications();
    await this.getRestaurantNotifications();
  },
  beforeMount() {
    this.isMobile = this.$store.getters.isRNAppSession;
  },
  mounted() {
    this.getUnreadAmount();
    // await this.getStaffNotifications();
    // await this.getRestaurantNotifications();
    this.tz = moment.tz.guess();
    let user = this.$store.state.userData;
    this.optOut =
      user && user.isOptTextMessages
        ? this.$store.state.userData.isOptTextMessages
        : false;
    eventBus.$on("notification", (data) => {
      this.getUnreadAmount();
      this.getStaffNotifications();
      this.getRestaurantNotifications();
    });
  },
};
</script>
<style>
.nav-tabs {
  border-bottom: 0px !important;
}
</style>