import Vue from 'vue'
import VueRouter from 'vue-router'
import SignupLayout from "@/views/User/SignupLayout";
import SilentSignupLayout from "@/views/User/SilentSignupLayout";
import Home from '../views/Home.vue'
import ResetRequest from "@/views/User/Reset/Request";
import ResetReset from "@/views/User/Reset/Reset";
import Terms from "@/views/Terms/Terms";
import Privacy from "@/views/Terms/Privacy";
//signup
import Phone from "@/views/User/SignUp/Phone"
//silent signup page for access through App
import PhoneSilentForApp from "@/views/User/SignUp/PhoneSilentForApp"
import Role from "@/views/User/SignUp/Role"
import Password from "@/views/User/SignUp/Password"
//onboarding staff
import StaffOnboardingLayout from "@/views/Onboarding/Staff/StaffLayout";
import StaffOnboardingProfile from "@/views/Onboarding/Staff/Profile"
import DriverLicense from "@/views/Onboarding/Staff/DriverLicense";
import Ssn from "@/views/Onboarding/Staff/Ssn";
import StaffOnboardingSuccess from "@/views/Onboarding/Staff/Success";
//onboarding restaurant
import RestaurateurOnboardingLayout from "@/views/Onboarding/Restaurant/RestaurateurLayout"
import RestaurantProfile from "@/views/Onboarding/Restaurant/Profile"
import RestaurantOnboardingSuccess from "@/views/Onboarding/Restaurant/Success";
//logout
import Logout from "@/views/Logout";
//staff
import TransferInfo from "@/views/Staff/Profile/TransferInfo";
import Staff from "@/views/Staff/Staff";
import StaffHome from "@/views/Staff/Home"
import StaffDelete from "@/views/Staff/Delete"
import StaffNotifications from "@/views/Staff/Profile/Notifications";
import StaffChat from "@/views/Staff/Chat";
import StaffWallet from "@/views/Staff/Profile/Wallet";
//restaurateur
import PaymentInfo from "@/views/Restaurant/Profile/PaymentInfo";
import Restaurateur from "@/views/Restaurant/Restaurateur";
import RestaurateurHome from "@/views/Restaurant/Home";
import RestaurateurDelete from "@/views/Restaurant/Delete"
import RestaurantNotifications from "@/views/Restaurant/Profile/Notifications";
import RestaurantChat from "@/views/Restaurant/Chat";
//shift restaurant
import CreateBaseShift from "@/views/Restaurant/Shift/Create/CreateBase";
import Shift from "@/views/Restaurant/Shift/Shift";
import ShiftsList from "@/views/Restaurant/Shift/List";
import ShiftDetails from "@/views/Restaurant/Shift/Details";
import SearchResult from "@/views/Restaurant/Shift/SearchResult";
import RestaurantCalendar from "@/views/Restaurant/Shift/Calendar";
import ShiftManager from "@/views/Restaurant/Shift/ShiftManager";
import RestaurantReview from "@/views/Restaurant/Shift/Review";
import RestaurantShiftSummary from "@/views/Restaurant/Shift/Summary";
// shift staff
import ViewInvite from "@/views/Staff/Shift/Offer/ViewInvite";
import StaffViewOffer from "@/views/Staff/Shift/Offer/View";
import StaffShiftSettings from "@/views/Staff/Shift/Settings";
import StaffShift from "@/views/Staff/Shift/Shift";
import StaffReview from "@/views/Staff/Shift/Offer/Review";
import StaffShiftCalendar from "@/views/Staff/Shift/Offer/Calendar";
import StaffShiftCalendar2 from "@/views/Staff/Shift/Offer/Calendar2";
import StaffShiftSummary from "@/views/Staff/Shift/Summary";

Vue.use(VueRouter)

const routes = [
    {
        component: SignupLayout,
        path: '',
        children: [
            {
                path: '/',
                name: 'home',
                component: Phone,
                meta: {title: 'SignUp'}
            },
            {
                path: '/login',
                name: 'login',
                redirect: '/signup/phone'
                //component: Login,
                //meta: {title: 'Login', requiresNoAuth: true}
            },
            {
                path: '/reset/request',
                name: 'reset.request',
                component: ResetRequest,
                meta: {title: 'Reset', requiresNoAuth: true}
            },
            {
                path: '/reset/:token',
                name: 'reset',
                props: true,
                component: ResetReset,
                meta: {title: 'Reset', requiresNoAuth: true}
            },
            {
                path: '/signup',
                redirect: '/signup/phone'
            },
            {
                path: '/signup/phone',
                name: 'signup.phone',
                component: Phone,
                meta: {title: 'SignUp', requiresNoAuth: true}
            },
            {
                path: '/signup/role',
                name: 'signup.role',
                component: Role,
                meta: {title: 'Role', requiresNoAuth: true}
            },
            {
                path: '/signup/password',
                name: 'signup.password',
                component: Password,
                meta: {title: 'Password', requiresNoAuth: true}
            },
            {
                path: '/staff/',
                component: StaffOnboardingLayout,
                meta: {title: 'Account information'},
                children: [
                    {
                        path: 'onboarding/profile',
                        name: 'staff.onboarding.profile',
                        component: StaffOnboardingProfile,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'onboarding/driver',
                        name: 'staff.onboarding.driver',
                        component: DriverLicense,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'onboarding/ssn',
                        name: 'staff.onboarding.ssn',
                        component: Ssn,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'onboarding/success',
                        name: 'staff.onboarding.success',
                        component: StaffOnboardingSuccess,
                        meta: {requiresAuth: true}
                    }
                ]
            },
            {
                path: '/restaurant/',
                component: RestaurateurOnboardingLayout,
                children: [
                    {
                        path: 'onboarding/profile',
                        name: 'restaurant.onboarding.profile',
                        component: RestaurantProfile,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'onboarding/success',
                        name: 'restaurant.onboarding.success',
                        component: RestaurantOnboardingSuccess,
                        meta: {requiresAuth: true}
                    }
                ]
            },
        ]
    },
    {
        path: '/appUser/',
        component: SilentSignupLayout,
        meta: {title: 'Silent Login', requiresNoAuth: true, requiresAuth: false},
        children: [
            {
                path: 'userPassthru/:phoneNum/:salted/:passKey/:isAppSession?',
                name: 'app.userSilentLogin',
                props: true,
                component: PhoneSilentForApp,
                meta: {requiresNoAuth: true, requiresAuth: false}
            }
        ]
    },
    {
        path: '/logout',
        component: Logout,
        name: 'logout',
        meta: {title: 'Logout', requiresAuth: true},
    },
    {
        path: '/staff/',
        component: Staff,
        meta: {title: 'Account information'},
        children: [
            {
                path: 'chat/:id',
                name: 'staff.chat',
                props: true,
                component: StaffChat,
                meta: {requiresAuth: true}
            },
            {
                path: 'wallet',
                name: 'staff.wallet',
                props: true,
                component: StaffWallet,
                meta: {requiresAuth: true}
            },
            {
                path: 'profile/transfer',
                name: 'staff.profile.transfer',
                component: TransferInfo,
                meta: {requiresAuth: true}
            },
            {
                path: 'notifications',
                name: 'staff.notifications',
                component: StaffNotifications,
                meta: {requiresAuth: true}
            },
            {
                path: 'home',
                name: 'staff.home',
                component: StaffHome
            },
            {
                path: 'delete',
                name: 'staff.user.delete',
                component: StaffDelete
            },
            {
                path: 'shift/',
                component: StaffShift,
                children: [
                    {
                        path: 'map',
                        name: 'staff.shift.map',
                        component: StaffShiftSettings,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'calendar',
                        name: 'staff.shift.calendar',
                        component: StaffShiftCalendar2,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'offer/:id/view',
                        name: 'staff.shift.offer.view',
                        component: ViewInvite,
                        props: true,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'offer/:id/details',
                        name: 'staff.shift.offer.details',
                        component: StaffViewOffer,
                        props: true,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'offer/:id/review',
                        name: 'staff.shift.offer.review',
                        component: StaffReview,
                        props: true,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'summary',
                        name: 'staff.shift.offer.summary',
                        component: StaffShiftSummary,
                        meta: {requiresAuth: true}
                    },
                ]
            },
        ]
    },
    {
        path: '/restaurant/',
        component: Restaurateur,
        children: [
            {
                path: 'chat/:id',
                name: 'restaurant.chat',
                props: true,
                component: RestaurantChat,
                meta: {requiresAuth: true}
            },
            {
                path: 'profile/payment',
                name: 'restaurant.profile.payment',
                component: PaymentInfo,
                meta: {requiresAuth: true}
            },
            {
                path: 'notifications',
                name: 'restaurant.notifications',
                component: RestaurantNotifications,
                meta: {requiresAuth: true}
            },
            {
                path: 'home',
                name: 'restaurant.home',
                component: RestaurateurHome
            },
            {
                path: 'delete',
                name: 'restaurant.user.delete',
                component: RestaurateurDelete
            },
            {
                path: 'shift/',
                component: Shift,
                children: [
                    {
                        path: 'create',
                        name: 'restaurant.shift.create',
                        component: CreateBaseShift,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'all',
                        name: 'restaurant.shift.all',
                        component: ShiftsList,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: ':id/view',
                        name: 'restaurant.shift.view',
                        component: ShiftDetails,
                        props: true,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: ':id/search-results',
                        name: 'restaurant.shift.search-results',
                        component: SearchResult,
                        props: true,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'calendar',
                        name: 'restaurant.shift.calendar',
                        component: RestaurantCalendar,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: ':id/manager',
                        name: 'restaurant.shift.manager',
                        component: ShiftManager,
                        props: true,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: ':shiftId/offer/:id/review',
                        name: 'restaurant.shift.offer.review',
                        component: RestaurantReview,
                        props: true,
                        meta: {requiresAuth: true}
                    },
                    {
                        path: 'summary',
                        name: 'restaurant.shift.summary',
                        component: RestaurantShiftSummary,
                        meta: {requiresAuth: true}
                    },
                ]
            },
        ]
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/NotFound'),
        meta: {title: '404'}
    },
    {
        path: '*',
        redirect: '/404'
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
