<template>
    <div id="app">

        <router-view/>
    </div>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style lang="scss">
@import "assets/scss/app";
</style>
