<template>
    <div class="row signupphone" style="display:none;">
        <div class="col-md-12">
            <img src="https://boonb.s3.amazonaws.com/ubar/thinkman.png" alt="thinkman" />
        </div>
        <div class="col-md-12 cont">
            <label>Sign Up / Login</label>
            <vue-phone-number-input
                :translations="{countrySelectorLabel: '', phoneNumberLabel: 'Enter your phone number!!!'}"
                v-model="phone"
                default-country-code="US"
                :border-radius="15"
                @update="updated($event)"
                class="phoneinput"
            />
            <p class="desc">
                <b>Don't have an account?</b> Enter your phone number above and we’ll get you started.
            </p>

            <div class="input-group mt-2">
                <input type="password" v-model="password" class="form-control inp" placeholder="Password" required>
            </div>

            <button @click="login" :id="'loginBtn'" class="btn btn-primary blue">Login</button>
            <b-alert class="mt-2" variant="danger" v-if="error" show dismissible>{{ error.message ? error.message : error }}</b-alert>
            <router-link class="ulink" :to="{name: 'reset.request'}">Forgot password?</router-link>
        </div>
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
const CryptoJS = require('crypto-js');

export default { 
    name: "PhoneSilentForApp",
    //:phoneNum/:salted/:passkey
    props: ['phoneNum', 'salted', 'passKey', 'isAppSession'],
    components: {'vue-phone-number-input': VuePhoneNumberInput},
    data() {
        return {
            // phone: '',//this.phoneNum,
            phone: this.phoneNum,
            isValid: false,
            formattedPhone: '',
            password: '',
            showPassword: false,
            error: null,
            submittedOnce: false,
        }
    },
    methods: {
        updated(event) {
            this.isValid = event.isValid;
            this.formattedPhone = event.formattedNumber;
        },
        submit() {
            console.log('submit ran');
            let loader = this.$loading.show();
            axios
                .get('/signup/check-phone/' + this.formattedPhone)
                .then((response) => {
                    if (response.data.exists) {
                        this.showPassword = true;
                    } else {
                        this.$store.commit('changeSignUpPhone', this.formattedPhone)
                        this.$router.push({'name': 'signup.role'})
                    }
                })
                .finally(() => {loader.hide();});
        },
        login() {
            let loader = this.$loading.show();
            this.error = null;
            this.$store.dispatch('login', {
                username: this.formattedPhone,
                password: this.password,
            })
                .then(() => {
                    this.$store.dispatch('updateUserData',{})
                        .then(() => {
                            let user = this.$store.state.userData;
                            let restaurant = this.$store.state.restaurateur;
                            let staff = this.$store.state.staff;
                            let isRNAppLogin = typeof this.isAppSession != 'undefined' && this.isAppSession == 'rNUApp';
                            this.$store.commit('changeRNAppSession', isRNAppLogin);
                            if (restaurant || (user && user.role === 'ROLE_RESTAURANT')) {
                                this.$router.push({'name': 'restaurant.home'});
                                return;
                            }
                            if (staff || (user && user.role === 'ROLE_STAFF')) {
                                this.$router.push({'name': 'staff.home'});
                            }
                            // this.$router.push({name: 'home'});
                        });
                })
                .catch(error => {
                    if (error.response) {
                        this.error = error.response.data.error;
                    } else {
                        console.log(error.message);
                    }
                })
                .finally(() => {loader.hide();});
        },
        decryptWithAES(cipherText, passphrase) {
            cipherText = cipherText.replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
            passphrase = passphrase.toString();
            const bytes = CryptoJS.AES.decrypt(cipherText, passphrase);
            const originalText = bytes.toString(CryptoJS.enc.Utf8);
            return originalText;
        }
    },
    mounted() {
        this.$store.commit('changePageData', {'title': 'Lets make this sh*ft fun'});
        
        let user = this.$store.state.userData;
        let restaurant = this.$store.state.restaurateur;
        let staff = this.$store.state.staff;
        
        if (this.phoneNum) {
            this.phone = decodeURI(this.phoneNum);
            setTimeout(150);
        }
        if (this.salted && this.passKey) {
            this.password = this.decryptWithAES(this.salted, this.passKey);
            setTimeout(150);
        }
        if (!staff && !restaurant) {
            return;
        }
        if (restaurant || (user && user.role === 'ROLE_RESTAURANT')) {
            this.$router.push({'name': 'restaurant.home'});
            return;
        }
        if (staff || (user && user.role === 'ROLE_STAFF')) {
            this.$router.push({'name': 'staff.home'});
        }
    },
    updated: function () {
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been re-rendered
            if (!this.submittedOnce && this.phone.length > 0 && this.password.length > 0) {
                this.login();
                this.submittedOnce = true;
            }
        })
    }
}
</script>