<template>
    <div class="container shift-w black" v-if="offer">
        <div class="row">
            <div class="col-7">
                <p class="desc">{{ dateTimeFromString().format('A') }} {{ offer.offer_speciality_title }} Shift</p>
                <p class="date">{{ getTime }}</p>
            </div>
            <div class="col-5 text-right">
                <p class="big">{{ dateTimeFromString().format('D') }}</p>
                <p class="day">{{ dateTimeFromString().format('dddd') }}</p>
            </div>
            <div class="col-6">
                <p class="calendar-p" @click="$router.push({name: 'staff.shift.calendar'})"><i class="fa fa-calendar"></i> Calendar</p>
            </div>
            <div class="col-6 text-right">
                <p class="name">{{ offer.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ShiftWidget",
    data () {
        return {
            offer: null
        }
    },
    methods: {
        getOffer() {
            axios
                .get('/shift/offer/closest-offer')
                .then((response) => {
                    this.offer = response.data.offer;
                })
                .catch(() => {});
        },
        dateTimeFromString() {
            return moment(this.offer.period_start_time);
        }
    },
    computed: {
        getTime() {
            let mTime = moment(this.offer.period_start_time);
            if (mTime.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                return "Today at " + mTime.format('h:mm a');
            }
            return mTime.format('MMMM Do [at] h:mm a');
        },
    },
    mounted() {
        this.getOffer();
    }
}
</script>