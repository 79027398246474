<template>
    <div>
        <div class="bubbles">
            <span
                v-for="bubble in bubbles"
                @click="selectBubble(bubble.id)"
                class="bubble"
                :class="{'active' : isFieldExists(bubble.id)}"
            >{{ bubble.title }}</span>
            <input type="hidden" v-model="value" :name="name" />
        </div>
        <span class="invalid-feedback" role="alert" v-if="error">
            <strong>{{ error }}</strong>
        </span>
    </div>
</template>

<script>
    export default {
        props: [
            'bubbles',
            'name',
            'value',
            'error',
            'multiple'
        ],
        data() {
            return {
                value: this.value
            }
        },
        computed: {
        },
        methods: {
            selectBubble(field) {
                if (this.multiple === true) {
                    if (!this.isFieldExists(field)) {
                        this.value.push(field);
                    } else {
                        this.value = _.remove(this.value, (n) => {
                            return n !== field;
                        });
                    }
                } else {
                    this.value = field;
                }

                this.$emit('input', this.value)
            },
            isFieldExists(field) {
                if (this.multiple === true) {
                    return this.value.indexOf(field) !== -1;
                }
                return this.value === field;
            }
        },
        mounted() {
        }
    }
</script>