<template>
    <div class="col-12 cont" :class="(isMobile ? 'cont-rnApp' : '')" >
        <div class="chatblock" ref="cbl" v-if="messages.length">
            <div class="text-center mt-2">
                <button class="btn btn-primary black" v-if="page * perPage < total" @click="getMessages">Load more</button>
            </div>
            <div class="container" v-for="(message, index) in messages.slice().reverse()" :key="index + '-' + index">
                <div class="row">
                    <div class="col-3" v-if="isOwnMessage(message)"></div>
                    <div class="col-9 messagebl" >
                        <p class="label">{{ getTimeTitle(message.date) }} <span>{{ getTime(message.date) }}</span></p>
                        <div class="messagebl" :class="message.text ? (isOwnMessage(message) ? ownColorClass : interlocutorColorClass) : ''">
                            <p class="desc" v-if="message.text">{{ message.text }}</p>
                            <p class="desc" v-else><img class="media-image" :src="message.media_link" /></p>
                        </div>
                    </div>
                    <div class="col-3" v-if="!isOwnMessage(message)"></div>
                </div>
            </div>
        </div>
        <div>
            <div class="input-group newmessage">
                <input ref="image" type="file" name="image" accept="image/*" class="custom-file-input" @change="onUpload()">
                <i class="fa fa-camera"></i>
                <input type="text" class="form-control" v-model="form.text" v-on:keyup.enter="create" :placeholder="inputPlaceholder" aria-describedby="mtext">
                <span @click="create" class="input-group-text" id="mtext"><i class="fa fa-paper-plane"></i></span>
            </div>
        </div>
    </div>
</template>

<script>

import Compressor from "compressorjs";

export default {
    name: "Messages",
    props: [
        "messagesUrl",
        "viewUrl",
        "authorId",
        "recipientId",
        "inputPlaceholder",
        'socket_token',
        'ownColorClass',
        'interlocutorColorClass'
    ],
    data () {
        return {
            messages: [],
            page: 0,
            total: null,
            perPage: null,
            tz: null,
            times: [],
            form: {
                text: '',
                mediaLink: ''
            },
            centrifuge: null,
            newMessagesNumber: 0,
            isMobile: false
        }
    },
    methods: {
        getTime(time) {
            let mTime = moment.tz(time, 'UTC').clone().tz(this.tz);
            return mTime.format('h:mm a');
        },
        getTimeTitle(time) {
            let mTime = moment.tz(time, 'UTC').clone().tz(this.tz);
            if (mTime.format('YYYY-MM-DD') === this.today) {
                return "Today at";
            }
            if (moment(time).format('YYYY-MM-DD') === this.yesterday) {
                return "Yesterday at";
            }
            if (mTime.format('YYYY-MM-DD') > moment().subtract(7, 'days').format('YYYY-MM-DD')) {
                return mTime.format('dddd');
            }

            return mTime.format('MMM Do');
        },
        getMessages() {
            axios
                .get('/chat/all/' + this.recipientId + '?page=' + (this.page + 1))
                .then((response) => {
                    let messagesData = response.data.messages;
                    messagesData.forEach((message) => {
                        if (this.newMessagesNumber === 0) {
                            this.messages.push(message);
                        } else {
                            this.newMessagesNumber = this.newMessagesNumber > 0 ? this.newMessagesNumber - 1 : 0;
                        }
                    });
                    this.page = response.data.page;
                    this.total = response.data.total;
                    this.perPage = response.data.per_page;
                    this.view(messagesData);
                    this.scrollDown();
                });
        },
        scrollDown(important = false) {
            this.$nextTick(() => {
                if (this.page === 1 || important) {
                    this.$refs.cbl.scrollTo(0, 3000);
                }
            });
        },
        view(messages) {
            let ids = []
            messages.forEach((message) => {
                ids.push(message.id);
            });
            if (ids.length === 0) {
                return;
            }
            axios
                .post('/chat/view-several', {'ids': ids})
                .then((response) => {
                    /*setTimeout(() => {
                        this.getUnreadAmount();
                    }, 3000)*/
                });
        },
        viewOne(id) {
            axios
                .post('/chat/' + id + '/view')
                .then(() => {});
        },
        create() {
            if (!this.form.text && !this.form.mediaLink) {
                return;
            }
            let text = this.form.text;
            let mediaLink = this.form.mediaLink;
            axios
                .post('/chat/create', {recipient_id: this.recipientId, text: text, mediaLink: mediaLink})
                .then((response) => {
                    let el = {
                        id: response.data.id,
                        date: moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss'),
                        recipient_id: this.recipientId,
                        author_id: this.authorId,
                        text: text,
                        media_link: mediaLink
                    };
                    this.messages.unshift(el);
                    this.scrollDown(true);
                    this.newMessagesNumber++;
                });
            this.form.text = '';
            this.form.mediaLink = '';
        },
        onUpload() {
            let loader = this.$loading.show();
            let file = this.$refs.image.files[0];
            let self = this;
            new Compressor(file, {
                quality: 0.8,
                maxWidth: 500,
                success(result) {
                    const formData = new FormData();
                    formData.append('image', result, result.name);
                    self.uploadImage(loader, formData);
                },
                error(err) {
                    loader.hide();
                },
            });
        },
        uploadImage(loader, formData) {
            axios
                .post('/chat/attach-image', formData)
                .then((response) => {
                    this.form.mediaLink = response.data.link;
                    this.create();
                }).finally(() => {loader.hide();});
        },
        isOwnMessage(message) {
            return this.authorId === message.author_id
        },
        subscribeToWebSocketNotification() {
            let url = window.location.origin + process.env.VUE_APP_CENTRIFUGO_URL
            this.centrifuge = new Centrifuge(url, {sockjs: SockJS});
            this.centrifuge.setToken(this.socket_token);
            this.centrifuge.subscribe('chat-' + this.recipientId + '#' + this.authorId, (message) => {
                let data = message.data;
                let el = {
                    id: data.extra.id,
                    date: data.title,
                    recipient_id: this.authorId,
                    author_id: this.recipientId,
                    text: data.text,
                    media_link: data.link
                };
                this.messages.unshift(el);
                this.viewOne(el.id);
                this.scrollDown(true);
                this.newMessagesNumber++;
            });
            this.centrifuge.connect();
        }
    },
    computed: {
        today() {
            return moment().format('YYYY-MM-DD');
        },
        yesterday() {
            return moment().subtract(1, 'days').format('YYYY-MM-DD');
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        this.getMessages();
        this.tz = moment.tz.guess();
        this.subscribeToWebSocketNotification()
    },
    destroyed() {
        this.centrifuge.disconnect();
    }
}
</script>