<template>
    <div class="row calendar">
        <div class="col-12">
            <div class="cont"  :class="(isMobile ? 'cal-rNApp mt-5' : '')">
                <schedule-calendar
                    :selected-date-parent="selectedDate"
                    :offers="offers"
                    @date-changed="clickDate($event)"
                ></schedule-calendar>
                <div class="container shift" :class="{'black': offer.status !== 'time off request', 'grey': offer.status === 'time off request'}" v-for="offer in offers">
                    <div class="row" v-if="offer.status === 'time off request'">
                        <div class="col-12">
                            <p class="switch_title">Pending Approval</p>
                            <p class="switch_desc">Your time off request has been sent to the team manager for approval.</p>
                            <button class="btn btn-secondary disabled" disabled >Pending time off approval...</button>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-7">
                            <p class="desc">{{ dateTimeFromString(offer.period_start_time).format('A') }} {{ offer.offer_speciality_title }} Shift</p>
                            <p class="desc">{{ dateTimeFromString(offer.period_start_time).format('MMMM Do [at] h:mm a') }}</p>
                        </div>
                        <div class="col-5">
                            <p class="desc">{{ offer.name }}</p>
                            <!-- <p class="desc">{{ offersAmountByShift(offer) === offer.vacancies_number ? 'Full' : 'Incomplete' }} {{ offersAmountByShift(offer) }}/{{ offer.vacancies_number }}</p> -->
                        </div>
                        <div class="col-12">
                            <span class="bartender" v-for="index in 2" v-if="offer.offers[index - 1] !== undefined && offer.offers[index - 1].id == userData.id">
                                {{ offer.offers[index - 1].name_first + ' ' + offer.offers[index - 1].name_last.charAt(0) }}
                            </span>
                            <span v-if="offersAmountByShift(offer) > 3">+{{ offersAmountByShift(offer) - 3}}</span>
                            <button class="btn btn-secondary" @click="showDetails(offer.id)">Details</button>
                            <button class="btn btn-danger" v-if="offer.status !== 'time off request'" @click="requestSwitch(offer)">Request Switch</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {eventBus} from "@/main";
import ScheduleCalendar from "@/components/Calendar/ScheduleCalendar";

export default {
    name: "Calendar",
    components: {ScheduleCalendar},
    data () {
        return {
            selectedDate: moment().format('YYYY-MM-DD'),
            offers: [],
            isMobile: false
        }
    },
    methods: {
        clickDate(date) {
            this.selectedDate = date;
            this.getOffers();
        },
        getOffers() {
            axios
                .get('/shift/offer/offers-by-date/' + this.formattedSelectedDateSystem)
                .then((response) => {
                    this.offers = response.data.offers;
                })
                .catch(() => {})
                .finally(() => {});
        },
        offersAmountByShift(offer) {
            return Object.keys(offer.offers).length;
        },
        dateTimeFromString(datetime) {
            return moment(datetime);
        },
        showDetails(id) {
            this.$router.push({name: 'staff.shift.offer.details', 'params': {'id': id}})
        },
        requestSwitch(offer) {
            let loader = this.$loading.show();
            axios
                .post('/shift/offer/' + offer.id + '/request-time-off', {reason: '-'})
                .then((response) => {
                    Swal.fire({
                        title: 'Requested',
                        text: 'Please wait for manager confirmation.',
                        icon: 'success'
                    })
                    this.getOffers();
                })
                .catch(() => {})
                .finally(() => {
                    loader.hide();
                });
        }
    },
    computed: {
        userData() {
            return this.$store.state.userData;
        },
        formattedSelectedDateSystem() {
            return moment(this.selectedDate).format('YYYY-MM-DD');
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        eventBus.$emit('changeTop', {
            title: ' ',
            desc: ''
        });
        this.getOffers();
    }
}
</script>