<template>
    <div class="topblock" :class="[{'splash': splashUrl}, background]" @click="tap()">
        <div class="divback">
            <i v-if="backUrl" @click="back(backUrl)" class="fa fa-angle-left" aria-hidden="true"></i>
        </div>
        <img src="https://boonb.s3.amazonaws.com/ubar/ubarlogo.png" alt="logo" />
        <h1>{{ $store.state.page.title }}</h1>
        <h2 v-if="$store.state.page.desc">{{ $store.state.page.desc }}</h2>
    </div>
</template>

<script>
export default {
    name: "Header",
    methods: {
        back(path) {
            this.$router.push({path: path})
        },
        tap() {
            if (!this.splashUrl) {
                return;
            }
            this.$router.push({path: this.splashUrl})
        }
    },
    computed: {
        backUrl() {
            return this.$store.state.page.back;
        },
        splashUrl() {
            return this.$store.state.page.splashUrl;
        },
        background() {
            if (this.$store.state.staff) {
                return 'black';
            }
            let role = this.$store.state.signup.role;
            if (role === 'staff') {
                return 'black';
            }
            return 'blue';
        }
    }
}
</script>