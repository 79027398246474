import Vue from 'vue'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Swal from "sweetalert2";
import Loading from 'vue-loading-overlay'
import AvatarCropper from "vue-avatar-cropper"
import { VueMaskDirective } from 'v-mask'
import vSelect from 'vue-select-checkbox'
import * as VueGoogleMaps from 'vue2-google-maps'
import StarRating from 'vue-star-rating'

window.axios = require('axios');
window.Swal = require('sweetalert2');
window._ = require('lodash');
//require('moment');
window.moment = require('moment-timezone');

window.Centrifuge = require('centrifuge');
window.SockJS = require('sockjs-client');
axios.defaults.baseURL = process.env.VUE_APP_API_URL
const user = JSON.parse(localStorage.getItem('user'));
if (user) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token;
}
axios.interceptors.response.use(null, error => {
    if (error.response.status === 400) {
        Swal.fire({
            icon: 'error',
            title: error.response.data.error.message
        });
        return Promise.reject(error);
    }
    if (error.response.status === 403) {
        Swal.fire({
            icon: 'error',
            title: 'Access denied.'
        });
        return Promise.reject(error);
    }
    if (error.response.status === 422) {
        return Promise.reject(error);
    }
    if (!error.response || error.response.status !== 401) {
        Swal.fire({
            icon: 'error',
            title: 'Something went wrong.'
        });
        return Promise.reject(error);
    }
    const request = error.config;
    if (request.data) {
        let data = JSON.parse(request.data);
        if (data && data.grant_type) {
            return Promise.reject(error);
        }
    }
    return store.dispatch('refresh')
        .then(() => {
            return new Promise((resolve) => {
                request.headers['Authorization'] = 'Bearer ' + store.state.user.access_token;
                resolve(axios(request))
            })
        })
        .catch(() => {
            router.push({name: 'login'});
            return Promise.reject(error)
        });
});
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Loading);
Vue.component('avatar-cropper', AvatarCropper);
Vue.component('star-rating', StarRating);
Vue.directive('mask', VueMaskDirective);
Vue.component('v-select', vSelect)
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyC4NUSy6uSiyoaCejEHIpsHEN8pIZf5V0g',
        libraries: 'places,drawing,visualization',
    },
});

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title + ' - Ubar';
    }
    
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isLoggedIn) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresNoAuth)) {
        if (store.getters.isLoggedIn) {
            if (!store.state.userData && !store.state.restaurateur && !store.state.staff) {
                next();
            }
            if (!from.name) {
                router.push({name: 'home'});
            } else {
                router.go(0);
            }
        } else {
            next()
        }
    } else {
        next()
    }
})
export const eventBus = new Vue();

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')