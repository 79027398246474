<template>
    <div class="calendar-widget">
        <schedule-calendar
            :selected-date-parent="selectedDate"
            :offers="offers"
            @date-changed="clickDate($event)"
        ></schedule-calendar>
    </div>
</template>

<script>
import ScheduleCalendar from "@/components/Calendar/ScheduleCalendar";

export default {
    name: "Widget",
    components: {ScheduleCalendar},
    data () {
        return {
            selectedDate: null,
            offers: []
        }
    },
    methods: {
        clickDate(date) {
            this.selectedDate = date;
            this.getOffers();
        },
        getOffers() {
            axios
                .get('/shift/offer/offers-by-date/' + moment(this.selectedDate).format('YYYY-MM-DD'))
                .then((response) => {
                    this.offers = response.data.offers;
                })
                .catch(() => {})
                .finally(() => {});
        },
    }
}
</script>