<template>
    <div class="row chat">
        <messages
            v-if="staff"
            :author-id="staff.id"
            :recipient-id="id"
            input-placeholder="Enter a custom question"
            :socket_token="staff.socket_token"
            own-color-class="black"
            interlocutor-color-class="blue"
        >

        </messages>
    </div>
</template>

<script>
import {eventBus} from "@/main";
import Messages from "@/components/Chat/Messages";

export default {
    components: {Messages},
    props: ['id'],
    name: "Chat",
    data() {
        return {
            staff: null,
            restaurant: null
        }
    },
    mounted() {
        this.staff = this.$store.state.staff;
        eventBus.$emit('changeTop', {title: 'Ask Questions', simple: true, topLogo: false});
        axios
            .get('/restaurant/' + this.id + '/short-view')
            .then((response) => {
                this.restaurant = response.data;
                eventBus.$emit('changeTop', {title: 'Ask Questions', shortDesc: this.restaurant.name, simple: true, topLogo: false});
            });
    }
}
</script>