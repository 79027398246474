<template>
    <div class="row home-dashboard">
        <div class="col-md-6" :class="(isMobile ? 'cont-rNApp' : 'cont')" >
            <div class="action-required mb-4" v-if="!hasPaymentData || !isApproved">
                <p class="title">Action Required!</p>
                <p class="desc" v-if="!hasPaymentData">To find staff and take full advantage of UBAR, finish filling out your profile.</p>
                <router-link v-if="!hasPaymentData" :to="{name: 'restaurant.onboarding.profile'}" class="btn actionbtn">Finish profile</router-link>
                <p class="desc" v-else>To find new shifts and take full advantage of UBAR, please wait for profile approvement.</p>
            </div>
            <shift-widget v-if="isApproved && hasPaymentData"></shift-widget>
            <div class="find-shifts" v-if="isApproved">
                <p class="title">Find a professional in minutes.</p>
                <p class="desc">U+BAR Shift Finder</p>
                <router-link :to="{name: 'restaurant.shift.create'}" class="btn btn-primary blue">Check it out <i class="fa fa-long-arrow-right"></i></router-link>
            </div>
            <notification role="restaurant"></notification>
        </div>
    </div>
</template>

<script>
import {eventBus} from "@/main";
import Notification from "@/components/Notification/Notification";
import ShiftWidget from "@/components/Shift/Restaurant/ShiftWidget";

export default {
    name: "Home",
    data() {
        return {
        }
    },
    components: {'shift-widget': ShiftWidget, 'notification': Notification},
    computed: {
        isApproved() {
            return this.$store.getters.isRestaurantApproved;
        },
        hasPaymentData() {
            console.log('================', this.$store.getters.isRestaurantHasPaymentInfo)
            return this.$store.getters.isRestaurantHasPaymentInfo;
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        let restaurateur = this.$store.state.restaurateur;
        eventBus.$emit('changeTop', {'logo': true});
    }
}
</script>