import Vue from "vue";
import Vuex from "vuex";
import qs from "qs";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    signup: {
      phone: null,
      role: null,
    },
    user: JSON.parse(localStorage.getItem("user")),
    userData: JSON.parse(localStorage.getItem("user-data")),
    staff: JSON.parse(localStorage.getItem("staff")),
    restaurateur: JSON.parse(localStorage.getItem("restaurateur")),
    isRNAppSession: JSON.parse(localStorage.getItem("isRNAppSession")),
    page: {
      title: "",
      desc: null,
      back: null,
      splashUrl: null,
    },
    recordUserData: null,
  },
  getters: {
    isLoggedIn(state) {
      return !!state.user;
    },
    isStaffApproved(state) {
      if (!state.staff) {
        return false;
      }
      return state.staff.status === "approved";
    },
    isRestaurantApproved(state) {
      if (!state.restaurateur || !state.restaurateur.restaurant) {
        return false;
      }
      return state.restaurateur.restaurant.status === "approved";
    },
    isRestaurantHasPaymentInfo(state) {
      if (!state.restaurateur || !state.restaurateur.restaurant) {
        return false;
      }
      return state.restaurateur.restaurant.wallet.has_payment_data;
    },
    isRNAppSession(state) {
      return state.isRNAppSession == true;
    }
  },
  mutations: {
    changeSignUpPhone(state, phone) {
      state.signup.phone = phone;
    },
    changeSignUpRole(state, role) {
      state.signup.role = role;
    },
    login(state, user) {
      state.user = user;
    },
    logout(state) {
      state.user = null;
      state.staff = null;
      state.restaurateur = null;
    },
    changeRNAppSession(state, data) {
      //tracking login using our RN App
      state.isRNAppSession = data;
      localStorage.setItem("isRNAppSession", data);
    },
    recordStaffData(state, data) {
      state.staff = data;
    },
    recordUserData(state, data) {
      state.userData = data;
    },
    recordRestaurateurData(state, data) {
      state.restaurateur = data;
    },
    changePageData(state, values) {
      state.page.title = values.title !== undefined ? values.title : null;
      state.page.desc = values.desc !== undefined ? values.desc : null;
      state.page.back = values.back !== undefined ? values.back : null;
      state.page.splashUrl =
        values.splashUrl !== undefined ? values.splashUrl : null;
    },
  },
  actions: {
    sendReport(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "staff/sendsupport",
            {
              firstname: data.first_name,
              lastname: data.last_name,
              email: data.email,
              query: data.query,
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    userAddAccount() {
      return new Promise((resolve, reject) => {
        axios
          .post("user/add-account", {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    changeRNAppSession(context, data) {
      //boolean data value expected
      return new Promise((resolve) => {
        context.commit("changeRNAppSession", data);
        resolve();
      });
    },
    login(context, data, callback) {
      return new Promise((resolve, reject) => {
        context.commit("logout");
        axios
          .post(
            "/token",
            qs.stringify({
              grant_type: "password",
              username: data.username,
              password: data.password,
              client_id: "9184252522a0205aa8c36172b29862ce",
              client_secret: "5cecdbdebf16b96cd0daedcf1a6989e52f921c7248d6d9047e212047d3dc7fc55643dd08e1df565de2b8858410ec2b12eea6c14b3b5dff88124328901e1f3151",
              access_type: "offline",
            }),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((response) => {
            const user = response.data;
            localStorage.setItem("user", JSON.stringify(user));
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + user.access_token;
            context.commit("login", user);
            resolve(user);
            if (callback) {
              callback();
            }
          })
          .catch((error) => {
            context.commit("logout");
            localStorage.removeItem("user");
            reject(error);
          });
      });
    },
    logout(context) {
      return new Promise((resolve) => {
        localStorage.removeItem("user");
        localStorage.removeItem("user-data");
        localStorage.removeItem("staff");
        localStorage.removeItem("restaurateur");
        delete axios.defaults.headers.common["Authorization"];
        context.commit("logout");
        resolve();
      });
    },
    refresh(context) {
      return new Promise((resolve, reject) => {
        if (context.state.user) {
          delete axios.defaults.headers.common["Authorization"];
          return axios
            .post(
              "/token",
              qs.stringify({
                grant_type: "refresh_token",
                refresh_token: context.state.user.refresh_token,
                client_id: process.env.VUE_APP_API_CLIENT_ID,
                client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
              }),
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then((response) => {
              const user = response.data;
              localStorage.setItem("user", JSON.stringify(user));
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + user.access_token;
              context.commit("login", user);
              resolve(response);
            })
            .catch((error) => {
              context.dispatch("logout");
              reject(error);
            });
        }
        resolve();
      });
    },
    updateUserData(context) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/view")
          .then((response) => {
            context.commit("recordUserData", response.data);
            localStorage.setItem("user-data", JSON.stringify(response.data));
            resolve();
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              title: "Profile data loading error.",
              text: "Try to reload page",
            });
            reject();
          });
      });
    },
    updateStaffData(context) {
      return new Promise((resolve, reject) => {
        axios
          .get("/staff/view")
          .then((response) => {
            context.commit("recordStaffData", response.data);
            localStorage.setItem("staff", JSON.stringify(response.data));
            resolve();
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              title: "Profile data loading error.",
              text: "Try to reload page",
            });
            reject();
          });
      });
    },
    updateRestaurateurData(context) {
      return new Promise((resolve, reject) => {
        axios
          .get("/restaurateur/view")
          .then((response) => {
            context.commit("recordRestaurateurData", response.data);
            localStorage.setItem("restaurateur", JSON.stringify(response.data));
            resolve();
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              title: "Profile data loading error.",
              text: "Try to reload page",
            });
            reject();
          });
      });
    },
  },
  modules: {},
});
