<template>
    <div class="row staff-profile justify-content-center">
        <div class="col-md-5 cont" :class="(isMobile ? 'cont-rNApp' : 'cont')">
            <avatar-input
                :upload-url="uploadUrl"
                :photo-init="userAvatar"
            ></avatar-input>
            <p class="label">Tell us about yourself</p>
            <div class="input-group">
                <input type="text" v-model="form.first_name" class="form-control inp" placeholder="First name">
                <div class="invalid-feedback" v-if="getError('first_name')" v-text="getError('first_name')"></div>
            </div>
            <div class="input-group">
                <input type="text" v-model="form.last_name" class="form-control inp" placeholder="Last name">
                <div class="invalid-feedback" v-if="getError('last_name')" v-text="getError('last_name')"></div>
            </div>
            <div class="input-group">
                <input type="text" v-model="form.email" class="form-control inp" placeholder="Email">
                <div class="invalid-feedback" v-if="getError('email')" v-text="getError('email')"></div>
            </div>
             <div class="input-group">
               <vue-phone-number-input
                        :translations="{countrySelectorLabel: '', phoneNumberLabel: 'Emergency Phone'}"
                        v-model="form.emergencyPhone"
                        default-country-code="US"
                        :border-radius="15"
                        class="phoneinput"
                    />
                <div class="invalid-feedback" v-if="getError('emergencyPhone')" v-text="getError('emergencyPhone')"></div>
            </div>
            <p class="label">Add preference for the bar/restaurant type</p>
            <div class="input-group">
                <v-select
                    v-if="industries.length"
                    label="title"
                    v-model="form.industries"
                    multiple
                    checkboxed
                    placeholder="Select type"
                    :options="[{id: 'all', title: 'All'}].concat(industries)"
                    @input="industriesChange"
                    :closeOnSelect=false
                >
                </v-select>
                <div class="invalid-feedback" v-if="getError('industries')" v-text="getError('industries')"></div>
            </div>
            <p class="label">Select profile type</p>
            <bubble-input
                :bubbles="types"
                v-model="form.type"
                :multiple="false"
            ></bubble-input>
            <div class="invalid-feedback" v-if="getError('type')" v-text="getError('type')"></div>
            <div v-if="form.type">
                <p class="label">How much experience do you have?</p>
                <bubble-input
                    :bubbles="experiences"
                    v-model="form.experience"
                    :multiple="false"
                ></bubble-input>
                <div class="invalid-feedback" v-if="getError('experience')" v-text="getError('experience')"></div>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-primary blue mr-2" @click="onClickContinue">Continue</button>
                <button class="btn btn-primary blue" @click="onClickSave">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import BubbleInput from "@/components/FormElement/BubbleInput";
import {ServerErrors} from "@/mixins/ServerErrors";
import AvatarInput from "@/components/FormElement/AvatarInput";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    name: "Profile",
    components: {'bubble-input': BubbleInput, 'avatar-input': AvatarInput,'vue-phone-number-input': VuePhoneNumberInput},
    data() {
        return {
            userAvatar: null,
            form: {
                first_name: '',
                last_name: '',
                type: '',
                experience: '',
                email: '',
                emergencyPhone: '',
                industries: []
            },
            tz: '',
            types: [],
            experiences: [],
            industries: [],
            isMobile: false,
        }
    },
    mixins: [ServerErrors],
beforeMount() {
    this.isMobile = this.$store.getters.isRNAppSession;
  },
    methods: {
        industriesChange() {
            if (this.form.industries.length === 0) {
                return;
            }
            let isAllSelected = false;
            for (let i = 0; i < this.form.industries.length; i++) {
                if (this.form.industries[i].id !== 'all') {
                    continue;
                }
                isAllSelected = true;
                break;
            }
            if (isAllSelected) {
                this.form.industries = [].concat(this.industries);
            }
        },
        onClickSave() {
                    let loader = this.$loading.show();
            axios
                .post('/staff/onboarding/base', {
                    first_name: this.form.first_name ?? '',
                    last_name: this.form.last_name ?? '',
                    type: this.form.type ?? '',
                    experience: this.form.experience ?? '',
                    tz: this.tz ?? '',
                    email: this.form.email ?? '',
                    emergencyPhone: this.form.emergencyPhone ?? '',
                    industries: this.form.industries.map((industry) => industry.id)
                })
                .then((response) => {
                    this.$store.dispatch('updateStaffData')
                        .then(() => {
                            this.$router.push({'name': 'staff.home'});
                        });
                })
                .catch(error => {
                    //caught in the mixin
                })
                .finally(() => {
                    loader.hide();
                })
        },
        onClickContinue() {
            let loader = this.$loading.show();
            axios
                .post('/staff/onboarding/base', {
                    first_name: this.form.first_name ?? '',
                    last_name: this.form.last_name ?? '',
                    type: this.form.type ?? '',
                    experience: this.form.experience ?? '',
                    tz: this.tz ?? '',
                    email: this.form.email ?? '',
                    emergencyPhone: this.form.emergencyPhone ?? '',
                    industries: this.form.industries.map((industry) => industry.id)
                })
                .then((response) => {
                    this.$store.dispatch('updateStaffData')
                        .then(() => {
                            this.$router.push({'name': 'staff.onboarding.driver'});
                        });
                })
                .catch(error => {
                    //caught in the mixin
                })
                .finally(() => {
                    loader.hide();
                })
        },
        syncData() {
            let data = this.$store.state.staff;
            if (!data || Object.keys(data).length === 0) {
                return;
            }console.log(data);
            this.form.first_name = data.name ? data.name.first : '';
            this.form.last_name = data.name ? data.name.last : '';
            this.form.type = data.speciality ? data.speciality.id : '';
            this.form.experience = data.experience;
            this.form.email = data.email;
            this.form.emergencyPhone=data.emergencyPhone;
            this.userAvatar = data.photo;
            this.form.industries = data.industries
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    computed: {
        uploadUrl() {
            return axios.defaults.baseURL + 'staff/onboarding/upload-avatar';
        },
        profTitle() {
            if (!this.form.type) {
                return '';
            }
            let title = '';
            title = this.types.find((type) => { return type.id === this.form.type });
            if (title.title === 'Other') {
                return 'working';
            }
            return title.title.toLowerCase() + 'ing';
        }
    },
    mounted() {
        this.$store.commit('changePageData', {title: 'Account information', back: this.$router.resolve({name: 'staff.home'}).href});
        this.syncData()
        axios
            .get('/data/specialities')
            .then((response) => {
                this.types = response.data;
            });
        axios
            .get('/data/industries')
            .then((response) => {
                this.industries = response.data;
            });
        axios
            .get('/data/experiences')
            .then((response) => {
                this.experiences = response.data;
            });
        this.tz = moment.tz.guess();
    }
}
</script>
