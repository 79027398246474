<template>
    <div class="row">
        <gmap-map
                :center="findCenter"
                :zoom="zoom"
                :style="`width: 100%; min-height:calc(100vh - ${reduceHeight})`"
                :options="{gestureHandling: 'cooperative', mapTypeControl: false, streetViewControl: false}"
                ref="map"
        >

            <gmap-marker
                    :position="findCenter"
                    :z-index="2"
                    icon="/img/blue-marker-bar.png"

            />
            <gmap-marker
                v-if="directionCoords"
                :position="directionCoords"
                :z-index="2"
                icon="/img/dark-marker-bar.png"

            />
            <gmap-marker
                v-for="location in locations"
                :position="location"
                :z-index="2"
                icon="/img/dark-marker-bar-little.png"

            />
            <gmap-custom-marker
                    :marker="findCenter"
                    :z-index="2"
                    v-if="pulse"
            >
                <div class="div-pulse">
                    <span class="pulse"></span>
                </div>

            </gmap-custom-marker>
        </gmap-map>
    </div>
</template>

<script>
    import GmapCustomMarker from 'vue2-gmap-custom-marker';

    export default {
        props: [
            'lat',
            'lng',
            'pulse',
            'directionCoords',
            'locations',
            'reduceHeightByPixels'
        ],
        data () {
            return {
                directionsDisplay: null,
                reduceHeight: '186px'
            }
        },
        methods: {
            getDirection(coords) {
                let directionsService = new google.maps.DirectionsService;
                if (!this.directionsDisplay) {
                    this.directionsDisplay = new google.maps.DirectionsRenderer({polylineOptions:{strokeColor:"#4a4a4a",strokeWeight:5}, suppressMarkers:true });
                }
                this.directionsDisplay.setMap(this.$refs.map.$mapObject);
                this.calculateAndDisplayRoute(directionsService, this.directionsDisplay, coords, this.findCenter);
            },
            calculateAndDisplayRoute(directionsService, directionsDisplay, start, destination) {
                directionsService.route({
                    origin: start,
                    destination: destination,
                    travelMode: 'DRIVING'
                }, (response, status) => {
                    if (status === 'OK') {
                        directionsDisplay.setDirections(response);
                    }
                });
            },
        },
        components: {
            'gmap-custom-marker': GmapCustomMarker
        },
        computed: {
            zoom() {
                return 14;
            },
            findCenter() {
                return {lat: this.lat, lng: this.lng};
            }
        },
        mounted() {
            this.reduceHeight = (
                    (typeof this.reduceHeightByPixels != 'undefined') 
                    && this.reduceHeightByPixels.length > 0
                ) ? `${this.reduceHeightByPixels}px` : '88px';
            this.$gmapApiPromiseLazy().then(() => {
                if (this.directionCoords) {
                    this.getDirection(this.directionCoords);
                }
            });
        }
    }
</script>
