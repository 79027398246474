<template>
    <div v-if="privacy">
        <h3>Last Revised: {{ privacy.date }}</h3>
        <div v-html="privacy.text"></div>
    </div>
</template>

<script>
export default {
    name: "Terms",
    data () {
        return {
            privacy: null
        }
    },
    mounted() {
        axios
            .get('/data/terms/privacy')
            .then((response) => {
                this.privacy = response.data.privacy;
            });
    }
}
</script>