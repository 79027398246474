<template>
    <div class="row review">
        <div class="col-12">
            <div class="cont" :class="(isMobile ? 'rNApp' : '')" v-if="offer">
                <div class="head black">
                    <hr/>
                    <div class="container">
                        <div class="row">
                            <div class="col-4">
                                <p class="title">Shift Total</p>
                                <p class="subtitle">{{ formattedLeftToEnd }}</p>
                            </div>
                            <div class="col-4">
                                <p class="title">Final Pay</p>
                                <p class="subtitle">${{ offer.cost }}</p>
                            </div>
                            <div class="col-4">
                                <p class="title">Status</p>
                                <p class="subtitle">{{ review ? 'Finished': 'Done'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container create">
                    <div class="row">
                        <div class="col-12">
                            <p class="question" v-if="!review">Did {{ offer.shift.restaurant.name }} do well?</p>
                            <p class="question" v-else>Thank you for your feedback!</p>
                            <p class="questiondesc" v-if="!review">Rate them.</p>
                            <div class="stardiv">
                                <star-rating
                                    :read-only="!!review"
                                    :show-rating="false"
                                    active-color="#0070ff"
                                    inactive-color="transparent"
                                    :border-width="1"
                                    :star-size="30"
                                    :rating="form.score"
                                    @rating-selected ="setRating"
                                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                                    :padding="20"
                                ></star-rating>
                            </div>
                            <div class="invalid-feedback" v-if="getError('score')" v-text="getError('score')"></div>
                            <hr/>
                            <div v-if="questionTitle && this.form.score > 2">
                                <p class="question">{{ questionTitle }}</p>
                                <p class="questiondesc" v-if="!review">Select options below</p>
                                <bubble-input
                                    :bubbles="characteristics"
                                    v-model="form.characteristics"
                                    :multiple="true"
                                ></bubble-input>
                                <div class="invalid-feedback" v-if="getError('characteristics')" v-text="getError('characteristics')"></div>
                                <hr/>
                            </div>
                            <div class="row">
                                <div class="col-10" v-if="!review">
                                    <p class="leavetext">Leave an explanation</p>
                                </div>
                                <div class="col-2" v-if="!review">
                                    <i class="fa fa-pencil-square-o" @click="showTextArea = !showTextArea"></i>
                                </div>
                                <div class="col-12" v-if="showTextArea || review">
                                    <div class="input-group">
                                        <textarea rows="3" class="form-control" v-model="form.text" :readonly="!!review"></textarea>
                                        <div class="invalid-feedback" v-if="getError('text')" v-text="getError('text')"></div>
                                    </div>
                                </div>
                                <div class="col-12" v-if="!review">
                                    <button class="btn btn-primary blue" @click="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ServerErrors} from "@/mixins/ServerErrors";
import BubbleInput from "@/components/FormElement/BubbleInput";
import {eventBus} from "@/main";
import {Offer} from "@/mixins/Offer";

export default {
    name: "Review",
    props: ['id', 'shiftId'],
    data() {
        return {
            review: null,
            showTextArea: false,
            form: {
                score: 0,
                text: '',
                characteristics: []
            },
            questions: [
                'What went wrong?',
                'What went wrong?',
                'What areas could be improved?',
                'What was well done?',
                'What were the strengths?'
            ],
            characteristics: [
                {'id': 'Friendly team', 'title': 'Friendly team'},
                {'id': 'Cool place', 'title': 'Cool place'},
                {'id': 'Well Organized', 'title': 'Well Organized'}
            ],
            isMobile: false
        }
    },
    mixins: [Offer, ServerErrors],
    components: {'bubble-input': BubbleInput},
    computed: {
        questionTitle() {
            if (this.form.score === 0) {
                return null;
            }
            return this.questions[this.form.score - 1];
        }
    },
    methods: {
        setRating: function(rating){
            this.form.score = rating;
            if(this.form.score <= 2){
              this.form.characteristics = [];
            }
        },
        getReview() {
            let loader = this.$loading.show();
            axios
                .get('shift/offer/staff/' + this.id + '/review/view-by-sides')
                .then((response) => {
                    if (response.data.id) {
                        this.review = response.data;
                        this.form.text = this.review.text;
                        this.form.score = this.review.score;
                        this.form.characteristics = this.review.characteristics;
                    }
                })
                .catch(() => {})
                .finally(() => {loader.hide()});
        },
        submit() {
            let loader = this.$loading.show();
            axios
                .post('/shift/offer/staff/' + this.id + '/review/create', this.form)
                .then((response) => {
                    //this.getReview();
                  this.updateMandatoryReviewsInfo();
                  if(localStorage.mandatoryReviewsCount === '0'){
                    this.$router.push({'name': 'staff.home'})
                  }else{
                    this.$router.push({name: 'staff.shift.offer.details', 'params': {'id': JSON.parse(localStorage.offersList)[0]}})
                  }
                })
                .catch(() => {})
                .finally(() => {loader.hide()});
        },
      updateMandatoryReviewsInfo()
      {
        localStorage.mandatoryReviewsCount = localStorage.mandatoryReviewsCount - 1;
        let offers = JSON.parse(localStorage.offersList);
        offers.shift();
        localStorage.offersList = JSON.stringify(offers);
      }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        eventBus.$emit('changeTop', {
            title: 'Review',
            desc:  '',
            simple: true,
            backUrl: {name: 'staff.shift.offer.details', params: {'id': this.id}}
        });
        this.getOffer()
            .then(() => {
                this.getReview();
            });
        let loader = this.$loading.show();
        axios
            .get('/data/review/characteristics-for-type/restaurant')
            .then((response) => {
                this.characteristics = response.data;
            })
            .catch(() => {})
            .finally(() => {loader.hide()});
    }
}
</script>