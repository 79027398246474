<template>
</template>

<script>
export default {
    name: "Success",
    methods: {
        submit() {
            this.$router.push({'name': 'restaurant.home'})
        }
    },
    mounted() {
        this.$store.commit('changePageData', {
                title: 'Account created',
                desc: 'Tap anywhere to continue',
                splashUrl: this.$router.resolve({name: 'restaurant.home'}).href
            }
        );
    }
}
</script>