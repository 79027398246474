<template>
    <div>
        <div class="row" v-for="shift in shifts">
            <div class="col-4">{{ shift.period_start_time + ' - ' + shift.period_end_time }}</div>
            <div class="col-4">{{ shift.status }}</div>
            <div class="col-4"><button class="btn btn-primary" @click="click(shift.id)">Show</button></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "List",
    data () {
        return {
            shifts: []
        }
    },
    methods: {
        click(id) {
            this.$router.push({'name': 'restaurant.shift.view', 'params': {'id': id}});
        }
    },
    mounted() {
        axios
            .get('/shift/all')
            .then((response) => {
                this.shifts = response.data.shifts;
            });
    }
}
</script>