<template>
    <div class="row signupphone">
        <div class="col-md-12">
            <img src="https://boonb.s3.amazonaws.com/ubar/thinkman.png" alt="thinkman" />
        </div>
        <div class="col-md-12 cont">
            <label>Sign Up / Login</label>
            <vue-phone-number-input
                :translations="{countrySelectorLabel: '', phoneNumberLabel: 'Enter your phone number'}"
                v-model="phone"
                default-country-code="US"
                :border-radius="15"
                @update="updated($event)"
                class="phoneinput"
            />
            <p class="desc">
                <b>Don't have an account?</b> Enter your phone number above and we’ll get you started.
            </p>
            <div class="input-group mt-2" v-if="showPassword">
                <input type="password" v-model="password" class="form-control inp" placeholder="Password" required>
            </div>
            <button @click="submit" class="btn btn-primary blue" v-if="isValid && !showPassword">Continue</button>
            <button @click="login" class="btn btn-primary blue" v-if="showPassword && password">Login</button>
            <b-alert class="mt-2" variant="danger" v-if="error" show dismissible>{{ error.message ? error.message : error }}</b-alert>
            <router-link class="ulink" :to="{name: 'reset.request'}">Forgot password?</router-link>
        </div>
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    name: "Phone",
    components: {'vue-phone-number-input': VuePhoneNumberInput},
    data() {
        return {
            phone: '',
            isValid: false,
            formattedPhone: '',
            password: '',
            showPassword: false,
            error: null
        }
    },
    methods: {
        updated(event) {
            this.isValid = event.isValid;
            this.formattedPhone = event.formattedNumber;
        },
        submit() {
            let loader = this.$loading.show();
            axios
                .get('/signup/check-phone/' + this.formattedPhone)
                .then((response) => {
                    if (response.data.exists) {
                        this.showPassword = true;
                       /* Swal.fire({
                            title: 'Error!',
                            text: 'User is already exists.',
                            icon: 'error'
                        })*/
                    } else {
                        this.$store.commit('changeSignUpPhone', this.formattedPhone)
                        this.$router.push({'name': 'signup.role'})
                    }
                })
                .finally(() => {loader.hide();});
        },
        login() {
            let loader = this.$loading.show();
            this.error = null;
            this.$store.dispatch('login', {
                username: this.formattedPhone,
                password: this.password,
            })
                .then(() => {
                    this.$store.dispatch('updateUserData',{})
                        .then(() => {
                            console.log('after login attempt');
                            let user = this.$store.state.userData;
                            let restaurant = this.$store.state.restaurateur;
                            let staff = this.$store.state.staff;
                            if (restaurant || (user && user.role === 'ROLE_RESTAURANT')) {
                                this.$router.push({'name': 'restaurant.home'});
                                return;
                            }
                            if (staff || (user && user.role === 'ROLE_STAFF')) {
                                this.$router.push({'name': 'staff.home'});
                            }
                            // this.$router.push({name: 'home'});
                        });
                })
                .catch(error => {
                    if (error.response) {
                        this.error = error.response.data.error;
                    } else {
                        console.log(error.message);
                    }
                })
                .finally(() => {loader.hide();});
        }
    },
    mounted() {
        this.$store.commit('changePageData', {'title': 'Lets make this sh*ft fun'});

        let user = this.$store.state.userData;
        let restaurant = this.$store.state.restaurateur;
        let staff = this.$store.state.staff;
        if (!staff && !restaurant) {
            return;
        }
        if (restaurant || (user && user.role === 'ROLE_RESTAURANT')) {
            this.$router.push({'name': 'restaurant.home'});
            return;
        }
        if (staff || (user && user.role === 'ROLE_STAFF')) {
            this.$router.push({'name': 'staff.home'});
        }
    }
}
</script>