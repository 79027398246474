<template>
    <div class="row password justify-content-center">
        <div class="col-md-6 cont">
            <p class="label">Tell us about yourself</p>
            <div class="input-group">
                <input type="text" v-model="form.first_name" class="form-control inp" placeholder="First name">
                <div class="invalid-feedback" v-if="getError('first_name')" v-text="getError('first_name')"></div>
            </div>
            <div class="input-group">
                <input type="text" v-model="form.last_name" class="form-control inp" placeholder="Last name">
                <div class="invalid-feedback" v-if="getError('last_name')" v-text="getError('last_name')"></div>
            </div>
            <div class="input-group">
                <input type="text" v-model="form.email" class="form-control inp" placeholder="Email">
                <div class="invalid-feedback" v-if="getError('email')" v-text="getError('email')"></div>
            </div>
            <p class="label mt-3">Set a secure password</p>
            <div class="input-group">
                <input :type="type" class="form-control inp" placeholder="Enter your password" v-model="password">
                <span class="right-icon">
                <i
                    class="fa"
                    :class="{'fa-eye': isPasswordType, 'fa-eye-slash': !isPasswordType}"
                    aria-hidden="true"
                    @click="changeType"
                >
                </i>
            </span>
            </div>
            <div v-if="password" class="strength">
                <password-meter :password="password" @score="onScore" :style="'width: ' + strengthWidth + '%;'"/>
            </div>
            <div class="row" v-if="password">
                <div class="col-6">
                    <p class="strengthvalue">Password Strength:</p>
                </div>
                <div class="col-6 text-right">
                    <p class="strengthvalue">{{ score.strength }}</p>
                </div>
            </div>
            <div class="input-group">
                <input
                    type="password"
                    class="form-control inp"
                    :class="{'is-valid': password && isValid}"
                    placeholder="Confirm your password" v-model="repeat_password"
                >
            </div>
            <div class="col-12">
                <p class="desc">
                    A strong password consists of at least one capital letter, one number and one symbol.
                </p>
            </div>
            <div class="row terms-signup">
                <div class="col-5">
                    <b-form-checkbox
                        v-model="terms"
                        :value="1"
                        :unchecked-value="0"
                    ><span>I agree to the</span></b-form-checkbox>
                </div>
                <div class="col-7">
                    <a href="#" v-b-modal.terms-modal><span>terms and conditions</span></a>
                </div>
            </div>
            <div class="text-center submitbutton" v-if="isValid && terms">
                <button class="btn btn-primary blue mb-50" @click="submit">Continue</button>
            </div>
        </div>
        <b-modal id="terms-modal" class="terms-modal" size="lg" centered :hide-footer="true">
            <iframe src="/terms"></iframe>
        </b-modal>
    </div>
</template>

<script>
import passwordMeter from "vue-simple-password-meter";
import {ServerErrors} from "@/mixins/ServerErrors";

export default {
    name: "Password",
    data() {
        return {
            type: 'password',
            password: '',
            repeat_password: '',
            terms: 0,
            score: {
                score: 0,
                strength: 'risky'
            },
            form: {
                first_name: '',
                last_name: '',
                email: ''
            },
        }
    },
    components: {passwordMeter},
    mixins: [ServerErrors],
    methods: {
        submit() {
            let data = this.$store.state.signup;
            data.password = this.password;
            data.first_name = this.form.first_name;
            data.last_name = this.form.last_name;
            data.email = this.form.email;
            let loader = this.$loading.show();
            axios
                .post('/signup', data)
                .then((response) => {
                    this.$store.dispatch(
                        'login',
                        {'username': this.$store.state.signup.phone, 'password': this.password}
                    )
                        .then(() => {
                            this.$router.push({'name': data.role + '.onboarding.success'})
                        });
                })
                .finally(() => {loader.hide();});
        },
        changeType() {
            if (this.isPasswordType) {
                this.type = 'text';
                return;
            }
            this.type = 'password';
        },
        onScore({score, strength}) {
            //console.log(score); // from 0 to 4
            //console.log(strength); // one of : 'risky', 'guessable', 'weak', 'safe' , 'secure'
            this.$set(this.score, 'score', score);
            this.$set(this.score, 'strength', strength);
        }
    },
    computed: {
        isPasswordType() {
            return this.type === 'password';
        },
        strengthWidth() {
            return this.score.score === 0 ? 10 : this.score.score * 25;
        },
        isValid() {
            return this.password && (this.password === this.repeat_password);
        }
    },
    mounted() {
        this.$store.commit('changePageData', {title: 'Account information', back: this.$router.resolve({name: 'signup.role'}).href});
        if (!this.$store.state.signup.role) {
            this.$router.push({'name': 'signup.role'})
        }
    }
}
</script>