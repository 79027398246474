export const ServerErrors = {
    data () {
        return {
            server_errors: {}
        }
    },
    methods: {
        inpChanged(field) {
            this.$delete(this.server_errors, field);
        },
        getError(field) {
            return this.server_errors[this.toCamel(field)];
        },
        toCamel(s) {
            return s.replace(/([-_][a-z])/ig, ($1) => {
                return $1.toUpperCase()
                    .replace('-', '')
                    .replace('_', '');
            });
        }
    },
    mounted() {
        window.axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            this.server_errors = {};
            if (error.response && error.response.status === 422) {
                for (let key in error.response.data) {
                    this.$set(this.server_errors, key, error.response.data[key]);
                }
            }
            return Promise.reject(error);
        });
    }
};