<template>
    <div>
        <router-view v-if="loaded"></router-view>
    </div>
</template>

<script>

export default {
    name: "StaffLayout",
    data: function () {
        return {
            loaded: false
        }
    },
    methods: {
    },
    mounted() {
        let loader = this.$loading.show();
        this.$store.dispatch('updateStaffData')
            .then(() => {
                this.loaded = true;
            })
            .finally(() => {
                loader.hide();
            });
    }
}
</script>