<template>
    <div class="row staffsettings justify-content-center">
        <div class="col-md-12">
            <div class="cont" :class="(isMobile ? 'cont-rnApp' : '')" v-if="staff">
                <div class="row">
                    <div class="col-6">
                        <p class="change" v-b-modal.location>Change location</p>
                    </div>
                    <div class="col-6">
                        <av-list>

                        </av-list>
                    </div>
                </div>
                <div class="row">
                    <p class="change-desc text-center" :class="(isMobile ? 'mt-2' : 'mt-4')">To schedule recurring availability, click "Set Availability". To set availability on an individual day, click on the day and then click "Available on this day?".</p>
                </div>
                <b-modal id="location" size="sm" title="" :hide-footer="true">
                    <b-form-group label="Select location:" v-slot="{ ariaDescribedby }">
                        <b-form-radio
                            v-for="(address, index) in staff.addresses"
                            v-model="selectedAddress"
                            :aria-describedby="ariaDescribedby"
                            name="addresses"
                            :value="address.id"
                            :key="index"
                            @change="changeLocation()"
                        >{{ address.address }}</b-form-radio>
                    </b-form-group>
                    <hr/>
                    <a href="#" class="mb-2" @click.prevent="addNewForm = !addNewForm">{{ addNewForm ? 'X' : 'Add new' }}</a>
                    <div class="" v-if="addNewForm">
                        <div class="input-group">
                            <label class="form-label" v-if="form.address">Address</label>
                            <input type="text" v-model="form.address" class="form-control inp" placeholder="Address">
                            <div class="invalid-feedback" v-if="getError('address')" v-text="getError('address')"></div>
                        </div>
                        <div class="input-group">
                            <label class="form-label" v-if="form.city">City</label>
                            <input type="text" v-model="form.city" class="form-control inp" placeholder="City">
                            <div class="invalid-feedback" v-if="getError('city')" v-text="getError('city')"></div>
                        </div>
                        <div class="input-group">
                            <label class="form-label" v-if="form.state">State</label>
                            <b-form-select class="inp" v-model="form.state" :options="states">
                                <template #first>
                                    <b-form-select-option value="" disabled>State</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback" v-if="getError('state')" v-text="getError('state')"></div>
                        </div>
                        <div class="input-group">
                            <label class="form-label" v-if="form.zip">Zip</label>
                            <input type="text" v-model="form.zip" class="form-control inp" placeholder="Zip">
                            <div class="invalid-feedback" v-if="getError('zip')" v-text="getError('zip')"></div>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-primary blue" @click="addLocation()">Add</button>
                        </div>
                    </div>
                </b-modal>
                <div class="input-group search" v-if="!staff.availability" @click="activate">
                    <input type="text" readonly class="form-control inp" value="Shift Search Inactive" />
                    <span>Tap to activate</span>
                </div>
                <div class="input-group search blue" v-else @click="deactivate">
                    <input type="text" readonly class="form-control inp" value="Shift Search Active" />
                    <span>Tap to deactivate</span>
                </div>
            </div>
            <search-staff-map
                v-if="staff && staff.location"
                :lat="staff.location.lat"
                :lng="staff.location.lng"
                :pulse="pulse"
                class="map"
                :reduceHeightByPixels="(isMobile ? '186px' : null)"
            >
            </search-staff-map>
        </div>
    </div>
</template>

<script>
import SearchStaffMap from "@/components/Shift/SearchStaffMap";
import {ServerErrors} from "@/mixins/ServerErrors";
import {eventBus} from "@/main";
import {States} from "@/mixins/States";
import List from "@/components/Shift/Staff/Availability/List";

export default {
    name: "Settings",
    components: {'av-list': List, 'search-staff-map': SearchStaffMap},
    mixins: [ServerErrors, States],
    data () {
        return {
            staff: null,
            pulse: false,
            selectedAddress: '',
            addNewForm: false,
            form: {
                address: '',
                city: '',
                zip: '',
                state: ''
            },
            isMobile: false
        }
    },
    methods: {
        activate() {
            let loader = this.$loading.show();
            axios
                .post('/staff/activate-availability', {})
                .then((response) => {
                    this.staff.availability = true;
                    this.pulse = true;
                })
                .catch(error => {/*caught in the mixin*/})
                .finally(() => {loader.hide();});
        },
        deactivate() {
            let loader = this.$loading.show();
            axios
                .post('/staff/deactivate-availability', {})
                .then((response) => {
                    this.staff.availability = false;
                    this.pulse = false;
                })
                .catch(error => {/*caught in the mixin*/})
                .finally(() => {loader.hide();});
        },
        addLocation() {
            let loader = this.$loading.show();
            axios
                .post('/staff/attach-address', this.form)
                .then((response) => {
                    this.$store.dispatch('updateStaffData')
                        .then(() => {
                            this.staff = this.$store.state.staff;
                            loader.hide();
                        });
                    this.addNewForm = false;
                })
                .catch(error => {loader.hide();});
        },
        changeLocation() {
            let loader = this.$loading.show();
            axios
                .post('/staff/set-current-address', {'address_id': this.selectedAddress})
                .then((response) => {
                    this.$store.dispatch('updateStaffData')
                        .then(() => {
                            this.staff = this.$store.state.staff;
                            this.$bvModal.hide('location');
                            loader.hide();
                        });
                })
                .catch(error => {loader.hide();});
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
        console.log(this.isMobile);
    },
    mounted() {
        this.staff = this.$store.state.staff;
        let currentAddress = this.staff.addresses.find((elem) => elem.is_current === true);
        if (currentAddress) {
            this.selectedAddress = currentAddress.id;
        }
        this.pulse = this.staff.availability;
        eventBus.$emit('changeTop', {title: 'Let\'s find the perfect shift.', desc: ''});
    }
}
</script>