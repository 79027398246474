<template>
    <div class="row createshift">
        <div class="col-md-12">
            <div :class="(isMobile ? 'cont-rnApp' : 'cont')">
                <div class="input-group">
                    <div class="value-bubble" v-if="values.vacancies_number && !form.vacancies_number">{{ values.vacancies_number }}</div>
                    <input type="number" v-model="form.vacancies_number" @focus="onFocus('vacancies_number')" @blur="onBlur('vacancies_number')" class="form-control inp" placeholder="Number of vacancies">
                    <div class="invalid-feedback" v-if="getError('vacancies_number')" v-text="getError('vacancies_number')"></div>
                </div>
                <div class="input-group" v-if="values.vacancies_number || form.vacancies_number">
                    <div class="value-bubble" v-if="values.experience && !form.experience">{{ values.experience }}</div>
                    <b-form-select v-model="form.experience" :options="experiences" class="inp" @change="onBlur('experience')" :class="{'placeholder': !form.experience}" value-field="id" text-field="title">
                        <template #first>
                            <b-form-select-option :value="null" disabled>Experience</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback" v-if="getError('experience')" v-text="getError('experience')"></div>
                </div>
                <div class="input-group" v-if="values.experience || form.experience">
                    <div class="value-bubble" v-if="values.date && !form.date">{{ formattedDate }}</div>
                    <b-form-datepicker v-model="form.date" placeholder="Start date" class="mb-2 inp" @hidden="onBlur('date')" @shown="onShown('date')" :min="new Date()">
                        <template #button-content>
                            <div></div>
                        </template>
                    </b-form-datepicker>
                    <div class="invalid-feedback" v-if="getError('date')" v-text="getError('date')"></div>
                </div>
                <div class="input-group" v-if="values.date || form.date">
                    <div class="value-bubble" v-if="values.time && !form.time">{{ formattedTime }}</div>
                    <b-form-timepicker v-model="form.time" :minutes-step="10" @shown="onTimePickerShown" @hidden="onBlur('time')" placeholder="Start time" class="inp" hour12 locale="en" :no-close-button="true">
                        <template #button-content>
                            <div></div>
                        </template>
                    </b-form-timepicker>
                    <div class="invalid-feedback" v-if="getError('time')" v-text="getError('time')"></div>
                </div>
                <div class="input-group" v-if="values.time || form.time">
                    <div class="value-bubble" v-if="values.shift_length && !form.shift_length">{{ values.shift_length }} hours</div>
                    <input type="number" v-model="form.shift_length" @focus="onFocus('shift_length')" @blur="onBlur('shift_length')" class="form-control inp" placeholder="Shift length (hours)">
                    <div class="invalid-feedback" v-if="getError('shift_length')" v-text="getError('shift_length')"></div>
                </div>
                <div class="input-group" v-if="values.shift_length || form.shift_length">
                    <div class="value-bubble" v-if="values.cost && !form.cost">${{ values.cost }}</div>
                    <input type="text" v-model="form.cost" @focus="onFocus('cost')" @blur="onBlur('cost')" class="form-control inp" placeholder="What are you paying ($)">
                    <div class="invalid-feedback" v-if="getError('cost')" v-text="getError('cost')"></div>
                </div>
                <div class="input-group" v-if="values.cost || form.cost">
                    <div class="value-bubble" v-if="values.speciality && !form.speciality">{{ getSpecialityTitleById(values.speciality) }}</div>
                    <b-form-select v-model="form.speciality" @change="onBlur('speciality')" :options="specialities" value-field="id" :class="{'placeholder': !form.speciality}"  class="inp" text-field="title">
                        <template #first>
                            <b-form-select-option :value="null" disabled>Speciality</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback" v-if="getError('speciality')" v-text="getError('speciality')"></div>
                </div>
                <div class="text-center">
                    <button class="btn btn-primary blue" @click="submit()" v-if="showButton">Search</button>
                </div>
            </div>

            <search-staff-map
                v-if="office"
                :lat="office.address.lat"
                :lng="office.address.lng"
                :pulse="false"
                :class="[isMobile ? 'rNApp' : '', 'map']"
                :locations="locations"
                :reduceHeightByPixels="(isMobile ? '0' : '')"
            >
            </search-staff-map>
        </div>
    </div>
</template>

<script>
import SearchStaffMap from "@/components/Shift/SearchStaffMap";
import {ServerErrors} from "@/mixins/ServerErrors";
import {eventBus} from "@/main";

export default {
    name: "CreateBase",
    data () {
        return {
            restaurateur: null,
            office: null,
            form: {
                vacancies_number: null,
                experience: null,
                date: null,
                time: null,
                cost: null,
                shift_length: null,
                speciality: null
            },
            values: {
                vacancies_number: null,
                experience: null,
                date: null,
                time: null,
                cost: null,
                shift_length: null,
                speciality: null
            },
            experiences: {},
            specialities: {},
            shiftId: null,
            paid: false,
            locations: [],
            isMobile: false
        }
    },
    components: {'search-staff-map': SearchStaffMap},
    mixins: [ServerErrors],
    computed: {
        showButton() {
            return this.values.vacancies_number
                && this.values.experience
                && this.values.date
                && this.values.time
                && this.values.cost
                && this.values.shift_length
                && this.values.speciality
        },
        formattedTime() {
            if (!this.values.time) {
                return '';
            }
            return moment(this.values.time, 'HH:mm:ss').format('hh:mm A');
        },
        formattedDate() {
            if (!this.values.date) {
                return '';
            }
            return moment(this.values.date, 'YYYY-MM-DD').format('LL');
        }
    },
    methods: {
        onFocus(field) {
            this.form[field] = this.values[field];
        },
        onBlur(field) {
            this.values[field] = this.form[field];
            this.form[field] = null;
        },
        onShown(field) {
            this.form[field] = this.values[field];
        },
        onTimePickerShown() {
            if (this.values.time) {
                this.form.time = this.values.time;
                return;
            }
           this.form.time = moment().add(1, 'hour').format('HH:[00]:[00]');
        },
        submit() {
            let loader = this.$loading.show();
            if (!this.shiftId) {
                this.create(loader);
                return;
            }
            this.edit(loader);
        },
        create(loader) {
            this.$nextTick(() => {
                let form = this.values;
                form.office_id = this.office.id;
                form.restarateur_id = this.restaurateur.id;
                form.shift_length = parseInt(this.values.shift_length);
                form.vacancies_number = parseInt(this.values.vacancies_number);
                form.cost = parseFloat(this.values.cost);
                axios
                    .post('/shift/create/base', form)
                    .then((response) => {
                        this.shiftId = response.data.id;
                        this.pay(loader);
                    })
                    .catch(error => {/*caught in the mixin*/loader.hide();});
            });
        },
        edit(loader) {
            let form = this.values;
            form.shift_length = parseInt(this.values.shift_length);
            form.vacancies_number = parseInt(this.values.vacancies_number);
            form.cost = parseFloat(this.values.cost);
            axios
                .post('/shift/create/edit/' + this.shiftId, form)
                .then((response) => {
                    this.pay(loader);
                })
                .catch(error => {/*caught in the mixin*/loader.hide();});
        },
        pay(loader) {
            axios
                .post('/shift/create/pay/' + this.shiftId)
                .then((response) => {
                    this.paid = true;
                    this.startMatching(loader);
                })
                .catch(error => {/*caught in the mixin*/loader.hide();});
        },
        startMatching(loader) {
            axios
                .post('/shift/create/start-match/' + this.shiftId)
                .then((response) => {
                    this.$router.push({name: 'restaurant.shift.view', 'params': {'id': this.shiftId}});
                })
                .catch(error => {/*caught in the mixin*/})
                .finally(() => {loader.hide();});
        },
        getSpecialityTitleById(id) {
            let title = '';
            this.specialities.forEach(el => {
                if (el.id === id) {
                    title = el.title;
                }
            });
            return title;
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        this.restaurateur = this.$store.state.restaurateur;
        this.office = this.restaurateur.restaurant.offices[0];
        eventBus.$emit('changeTop', {title: 'Let\'s find the perfect fit.', desc: ''});
        axios
            .get('/data/experiences')
            .then((response) => {
                this.experiences = response.data;
            });
        axios
            .get('/data/specialities')
            .then((response) => {
                this.specialities = response.data;
            });
        axios
            .get('/restaurant/closest-staffs')
            .then((response) => {
                this.locations = response.data.locations;
            });
    }
}
</script>