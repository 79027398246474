<template>
    <div class="row home-dashboard">
        <div class="col-md-6" :class="(isMobile ? 'cont-rNApp' : 'cont')" >
            <div class="delete-confirmation mb-4" v-if="showDeleteConfirmation">
                <div class="row">
                    <div class="col alert alert-danger">
                        <p class="title">Are you sure you want to delete your account?</p>
                        <p class="title">Once you delete this account, you will not be able to create an account using the same details.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-md-6">
                        <router-link :to="{name: 'restaurant.home'}" class="btn btn-secondary">No</router-link>
                    </div>
                    <div class="col col-md-6">
                        <div class="btn btn-danger" @click="attemptDelete">Yes</div>
                    </div>
                </div>
            </div>
            <div class="mb-4" v-else>
                <div class="row">
                    <div class="col alert alert-warning">
                        <p class="title">Your account has been successfully deleted!<br />
                            You will need to create a new account with different details if you wish to use this app in the future.
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
    name: "Delete",
    data() {
        return {
            showDeleteConfirmation: true
        }
    },
    /* computed: {
        isApproved() {
            return this.$store.getters.isStaffApproved;
        }
    }, */
    methods: {
        attemptDelete() {
            axios
                .post('/restaurateur/delete')
                .then((response) => {
                    if (typeof response.status != 'undefined' && response.status) {
                        this.showDeleteConfirmation = false;
                        setTimeout(() => {
                            this.$router.push({'name': 'logout'});
                        }, 5000);
                    }
                })
                .catch(() => {});
        }
    },
    beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    mounted() {
        // console.log('---------------------', this.$store.state.staff)
        eventBus.$emit('changeTop', {'logo': true, 'topLogo': false, 
            'title': 'Delete Account?', 'desc': 'Are you sure you want to delete your account?'
        });
        this.hasTransferData = this.$store.state.staff.wallet.has_transfer_data;
    }
}
</script>