<template>
    <div class="row justify-content-center signupphone">
        <div class="col-md-6 cont">
            <b-tabs content-class="mt-3">
                <b-tab title="By SMS" active>
                    <b-form @submit="request">
                        <div class="input-group">
                            <vue-phone-number-input
                                :translations="{countrySelectorLabel: '', phoneNumberLabel: 'Phone number'}"
                                v-model="phone"
                                default-country-code="US"
                                :border-radius="15"
                                @update="updated($event)"
                                class="phoneinput"
                            />
                        </div>
                        <div class="invalid-feedback" v-if="getError('phone')" v-text="getError('phone')"></div>
                        <b-button type="submit" variant="primary">Request</b-button>
                    </b-form>
                </b-tab>
                <b-tab title="By Email">
                    <b-form @submit="request">
                        <div class="input-group">
                            <b-form-input class="inp" placeholder="Email" id="email" type="text" v-model="email" required></b-form-input>
                        </div>
                        <div class="invalid-feedback" v-if="getError('email')" v-text="getError('email')"></div>
                        <b-button type="submit" variant="primary">Request</b-button>
                    </b-form>
                </b-tab>
            </b-tabs>

        </div>
    </div>
</template>

<script>
import {ServerErrors} from "@/mixins/ServerErrors";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    name: "Request",
    data() {
        return {
            email: '',
            phone: ''
        }
    },
    components: {'vue-phone-number-input': VuePhoneNumberInput},
    mixins: [ServerErrors],
    methods: {
        updated(event) {
            this.formattedPhone = event.formattedNumber;
        },
        request(event) {
            event.preventDefault();
            let loader = this.$loading.show();
            axios
                .post('/reset', {email: this.email, phone: this.formattedPhone})
                .then((response) => {
                    Swal.fire({
                        title: 'Check ' + (this.email ? 'email' : 'sms'),
                        text: '',
                        icon: 'success'
                    })
                    this.$router.push({name: 'home'});
                })
                .catch(error => {
                    //caught in the mixin
                })
                .finally(() => {
                    loader.hide();
                });
        }
    },
    mounted() {
        this.$store.commit('changePageData', {title: 'Reset password'});
    }
}
</script>
