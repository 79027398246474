<template>
    <div class="row dl justify-content-center">
        <div class="col-md-6 cont">
            <div class="custom-fileinput-div">
                <img v-if="oldFile" :src="oldFile" />
                <div v-else>
                    <i class="fa fa-camera" aria-hidden="true"></i>
                    <p class="desc">Upload ID for verification</p>
                </div>
                <input ref="photo" type="file" name="photo" accept="image/*" class="custom-file-input" @change="onChange()">
                <button class="btn btn-primary blue upload">Upload ID</button>
            </div>
            <div class="invalid-feedback" v-if="server_errors.photo" v-text="server_errors.photo"></div>
            <div v-if="showForm">
                <div class="input-group">
                    <label class="form-label" v-if="form.address">Address</label>
                    <input type="text" v-model="form.address" class="form-control inp" placeholder="Address">
                    <div class="invalid-feedback" v-if="getError('address')" v-text="getError('address')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="form.city">City</label>
                    <input type="text" v-model="form.city" class="form-control inp" placeholder="City">
                    <div class="invalid-feedback" v-if="getError('city')" v-text="getError('city')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="form.state">State</label>
                    <b-form-select class="inp" v-model="form.state" :options="states">
                        <template #first>
                            <b-form-select-option :value="null" disabled>State</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback" v-if="getError('state')" v-text="getError('state')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="form.zip">Zip</label>
                    <input type="text" v-model="form.zip" class="form-control inp" placeholder="Zip">
                    <div class="invalid-feedback" v-if="getError('zip')" v-text="getError('zip')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="form.first_name">First name</label>
                    <input type="text" v-model="form.first_name" class="form-control inp" placeholder="First name">
                    <div class="invalid-feedback" v-if="getError('first_name')" v-text="getError('first_name')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="form.last_name">Last name</label>
                    <input type="text" v-model="form.last_name" class="form-control inp" placeholder="Last name">
                    <div class="invalid-feedback" v-if="getError('last_name')" v-text="getError('last_name')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="form.middle_name">Middle name</label>
                    <input type="text" v-model="form.middle_name" class="form-control inp" placeholder="Middle name">
                    <div class="invalid-feedback" v-if="getError('middle_name')" v-text="getError('middle_name')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="form.license_number">License number</label>
                    <input type="text" v-model="form.license_number" class="form-control inp" placeholder="License number">
                    <div class="invalid-feedback" v-if="getError('license')" v-text="getError('license')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="form.gender">Gender</label>
                    <b-form-select class="inp" v-model="form.gender" :options="[{value: null, text: 'Gender'},{value: 'M', text: 'Male' },{ value: 'F', text: 'Female'}]"></b-form-select>
                    <div class="invalid-feedback" v-if="getError('gender')" v-text="getError('gender')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="form.expiration_date">Expiration date</label>
                    <b-form-datepicker v-model="form.expiration_date" show-decade-nav placeholder="Expiration date" class="mb-2 inp"></b-form-datepicker>
                    <div class="invalid-feedback" v-if="getError('expiration_date')" v-text="getError('expiration_date')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="form.dob">Date of birth</label>
                    <!-- <b-form-datepicker v-model="form.dob" show-decade-nav placeholder="Date of birth 11" class="mb-2 inp"></b-form-datepicker> -->
                    <b-form-datepicker v-model="form.dob" show-decade-nav placeholder="Date of birth" class="mb-2 inp"></b-form-datepicker>
                    <!-- <datepicker format="yyyy-MM-dd" v-model="form.dob" name="Date of birth" class="form-control inp" input-class="border-0"></datepicker> -->
                    <div class="invalid-feedback" v-if="getError('date_of_birth')" v-text="getError('date_of_birth')"></div>
                </div>
                <div class="text-center">
                    <button class="btn btn-primary blue mb-50" @click="submit">Continue</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ServerErrors} from "@/mixins/ServerErrors";
import Compressor from "compressorjs";
import Swal from "sweetalert2";
import {States} from "@/mixins/States";
import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        Datepicker
    },
    name: "DriverLicense",
    data () {
        return {
            image: false,
            oldFile: '',
            showForm: false,
            form : {
                address: '',
                dob: '',
                city: '',
                state: '',
                zip: '',
                first_name: '',
                last_name: '',
                middle_name: '',
                expiration_date: '',
                license_number: '',
                gender: '',
            }
        }
    },
    mixins: [ServerErrors, States],
    methods: {
        onChange () {
            let loader = this.$loading.show();
            let waitPopup = this.wait();
            this.image = true;
            let file = this.$refs.photo.files[0];
            const self = this;
            new Compressor(file, {
                quality: 0.8,
                maxWidth: 500,
                success(result) {
                    const formData = new FormData();
                    formData.append('driver_license', result, result.name);
                    self.sendPhoto(formData, loader, waitPopup);
                },
                error(err) {
                    self.problems();
                },
            });
        },
        sendPhoto(formData, loader, waitPopup) {
            axios({
                method: 'POST',
                url: '/staff/dl/upload',
                data: formData
            })
                .then(response => {
                    this.$store.dispatch('updateStaffData')
                        .then(() => {
                            this.syncData()
                            console.log(this.form);
                        });
                    if (!(this.form.city || this.form.address || this.form.state || this.form.zip) || !(this.form.dob || this.form.expiration_date)) {
                        this.problems();
                    }
                })
                .catch(response => {
                    this.problems();
                })
                .finally(response => {
                    this.showForm = true;
                    waitPopup.close()
                    loader.hide()
                });
        },
        syncData() {
            let data = this.$store.state.staff;
            if (!data || Object.keys(data).length === 0 || !data.dl) {
                return;
            }
            data = data.dl;
            this.oldFile = data.photo
            this.form.first_name = data.name ? data.name.first : '';
            this.form.last_name = data.name ? data.name.last : '';
            this.form.middle_name = data.name ? data.name.middle_name : '';
            this.form.dob = data.birth_date;
            this.form.license_number = data.number;
            this.form.gender = data.gender;
            this.form.expiration_date = data.expiration_date;
            let address = data.address;
            if (address) {
                this.form.address = address.address;
                this.form.city = address.city;
                this.form.state = address.state;
                this.form.zip = address.zip;
            }
            this.showForm = true;
        },
        problems() {
            Swal.fire({
                imageUrl: "/img/loop.png",
                imageWidth: 90,
                imageHeight: 90,
                title: 'Still working...',
                text: 'Thank you for uploading your driver\'s license. It was a bit tricky for us to capture the data from your license automatically. If you could provide a few more details for verification, that would be great.'
            });
        },
        wait() {
            return Swal.fire({
                imageUrl: "/img/loop.png",
                imageWidth: 90,
                imageHeight: 90,
                title: 'Thank you for joining Ubar!',
                text: 'Thank you for your patience! Your license is being scanned into our system. ',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            });
        },
        submit() {
            let loader = this.$loading.show();
            axios({
                method: 'POST',
                url: '/staff/dl/edit',
                data: {
                    license: this.form.license_number ?? '',
                    first_name: this.form.first_name ?? '',
                    last_name: this.form.last_name ?? '',
                    middle_name: this.form.middle_name ?? '',
                    address: this.form.address ?? '',
                    city: this.form.city ?? '',
                    state: this.form.state ?? '',
                    zip: this.form.zip ?? '',
                    gender: this.form.gender ?? '',
                    expirationDate: this.form.expiration_date ?? '',
                    dateOfBirth: moment(this.form.dob).format('YYYY-MM-DD') ?? '',
                }
            })
            .then(response => {
                this.$store.dispatch('updateStaffData')
                    .then(() => {
                        this.askConfirmation()
                       // this.$router.push({name: 'staff.onboarding.ssn'})
                    });
            })
            .finally(response => {
                loader.hide()
            });
        },
        askConfirmation() {
            Swal.fire({
                title: 'Thank your for signing up for Ubar.',
                html: "<p>In order to accept shifts, we require a background check." +
                    " We respect your privacy per our privacy policy.</p>" +
                    "<p>Please click OK to continue.</p>",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0070ff',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            }).then((result) => {
                this.$router.push({name: 'staff.onboarding.ssn'})
            })
        },
    },
    mounted() {
       this.$store.commit('changePageData', {title: 'Account information', back: this.$router.resolve({name: 'staff.onboarding.profile'}).href});
       this.syncData();
    }
}
</script>