<template>
    <div class="row staff-profile justify-content-center">
        <div class="col-md-6 cont" :class="(isMobile ? 'cont-rnApp' : '')">
            <avatar-input
                :upload-url="uploadUrl"
                :photo-init="userAvatar"
            ></avatar-input>
            <p class="label">Tell us about yourself</p>
            <div class="input-group">
                <input type="text" v-model="form.first_name" class="form-control inp" placeholder="First name">
                <div class="invalid-feedback" v-if="getError('first_name')" v-text="getError('first_name')"></div>
            </div>
            <div class="input-group">
                <input type="text" v-model="form.last_name" class="form-control inp" placeholder="Last name">
                <div class="invalid-feedback" v-if="getError('last_name')" v-text="getError('last_name')"></div>
            </div>
            <div class="input-group">
                <input type="text" v-model="form.email" class="form-control inp" placeholder="Email">
                <div class="invalid-feedback" v-if="getError('email')" v-text="getError('email')"></div>
            </div>
            <p class="label">Business type</p>
            <div class="input-group">
                <v-select
                    v-if="industries.length"
                    label="title"
                    v-model="form.industries"
                    multiple
                    checkboxed
                    placeholder="Select type"
                    :options="industries"
                    :closeOnSelect=false
                >
                </v-select>
                <div class="invalid-feedback" v-if="getError('industries')" v-text="getError('industries')"></div>
            </div>
            <p class="label">What is the name of the business that you own or manage?</p>
            <div class="input-group">
                <input type="text" v-model="business_name" class="form-control inp" @input="inputName" placeholder="Business name">
                <div class="invalid-feedback" v-if="getError('business_name')" v-text="getError('business_name')"></div>
                <div class="autocomplete" v-if="Object.keys(names).length">
                    <div v-for="(value, key) in names">
                        <a
                            href="#"
                            @click.prevent="selectPlace(key, value)"
                            v-html="formattedName(value)"
                        ></a>
                        <hr/>
                    </div>
                </div>
            </div>
            <div v-if="showOfficeForm || getError('address')">
                <div class="input-group">
                    <label class="form-label" v-if="office.address">Address</label>
                    <input type="text" v-model="office.address" class="form-control inp" placeholder="Address">
                    <div class="invalid-feedback" v-if="getError('address')" v-text="getError('address')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="office.city">City</label>
                    <input type="text" v-model="office.city" class="form-control inp" placeholder="City">
                    <div class="invalid-feedback" v-if="getError('city')" v-text="getError('city')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="office.state">State</label>
                    <input type="text" v-model="office.state" class="form-control inp" placeholder="State">
                    <div class="invalid-feedback" v-if="getError('state')" v-text="getError('state')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="office.zip">Zip</label>
                    <input type="text" v-model="office.zip" class="form-control inp" placeholder="Zip">
                    <div class="invalid-feedback" v-if="getError('zip')" v-text="getError('zip')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="office.url">Web-site</label>
                    <input type="text" v-model="office.url" class="form-control inp" placeholder="Web-site">
                    <div class="invalid-feedback" v-if="getError('url')" v-text="getError('url')"></div>
                </div>
                <div class="input-group">
                    <label class="form-label" v-if="office.phone">Phone</label>
                    <input type="text" v-model="office.phone" class="form-control inp" placeholder="Phone">
                    <div class="invalid-feedback" v-if="getError('phone')" v-text="getError('phone')"></div>
                </div>
            </div>
            <div class="text-center">
                <button class="btn btn-primary blue mb-50" @click="submit">Continue</button>
            </div>
        </div>
    </div>
</template>

<script>
import {ServerErrors} from "@/mixins/ServerErrors";
import AvatarInput from "@/components/FormElement/AvatarInput";

export default {
    name: "Profile",
    data() {
        return {
            userAvatar: null,
            form: {
                first_name: '',
                last_name: '',
                email: '',
                industries: []
            },
            business_name: '',
            names: {},
            tz: '',
            lat: null,
            lng: null,
            office: {
                address: '',
                city: '',
                state: '',
                zip: '',
                url: '',
                phone: ''
            },
            block: false,
            showOfficeForm: false,
            industries: [],
            isMobile: false
        }
    },
    mixins: [ServerErrors],
    components: {'avatar-input': AvatarInput},
    computed: {
        uploadUrl() {
            return axios.defaults.baseURL + 'restaurant/onboarding/upload-avatar';
        }
    },
        beforeMount() {
        this.isMobile = this.$store.getters.isRNAppSession;
    },
    methods: {
        inputName(event) {
            if ((this.business_name).length < 3) {
                this.names = {};
                return;
            }
            this.autocomplete();
        },
        submit() {
            let loader = this.$loading.show();
            axios
                .post('/restaurant/onboarding/base', {
                    first_name: this.form.first_name ?? '',
                    last_name: this.form.last_name ?? '',
                    business_name: this.business_name ?? '',
                    email: this.form.email ?? '',
                    industries: this.form.industries.map((industry) => industry.id),
                    tz: this.tz ?? ''
                })
                .then((response) => {
                    this.assignOffice(loader);
                })
                .catch(error => {
                    //caught in the mixin
                })
                .finally(() => {
                    loader.hide();
                });
        },
        assignOffice(loader) {
            let data = this.office;
            data.is_main = true;
            data.name = this.business_name;
            axios
                .post('/restaurant/onboarding/assign-office', data)
                .then((response) => {
                    this.$store.dispatch('updateRestaurateurData')
                        .then(() => {
                            this.$router.push({'name': 'restaurant.profile.payment'});
                        });
                })
                .catch(error => {
                    //caught in the mixin
                })
                .finally(() => {
                    loader.hide();
                });
        },
        getPositions(position) {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
        },
        autocomplete() {
            let url = '/map/autocomplete/' + (this.business_name).replace(' ', '+');
            if (this.lat) {
                url = url + '/' + this.lat + '/' + this.lng;
            }
            axios({
                method: 'GET',
                url: url,
            })
                .then(response => {
                    this.names = response.data;
                })
                .catch(error => {
                    this.showOfficeForm = true;
                })
                .finally(response => {
                    this.block = false;
                });
        },
        formattedName(value) {
            let formatted = value.split(', ');
            return '<b>' + formatted.shift() + '</b>, ' + formatted.join(', ');
        },
        selectPlace(key, value) {
            this.business_name = value.split(',')[0];
            this.showOfficeForm = true;
            axios({
                method: 'GET',
                url: '/map/place-data/' + key,
            })
                .then(response => {
                    let data = response.data;
                    this.office.address = data.address;
                    this.office.city = data.city;
                    this.office.zip = data.zip;
                    this.office.state = data.state;
                    this.office.url = data.url;
                    this.office.phone = data.phone;
                    this.names = {};
                })
                .catch(error => {})
                .finally(response => {
                    this.block = false;
                });
        },
        syncData() {
            let data = this.$store.state.restaurateur;
            if (!data || Object.keys(data).length === 0) {
                return;
            }
            this.userAvatar = data.photo;
            this.form.email = data.email;
            this.form.first_name = data.name ? data.name.first : '';
            this.form.last_name = data.name ? data.name.last : '';
            let restaurant = data.restaurant;
            if (!restaurant) {
                return;
            }
            this.business_name = restaurant.name;
            this.form.industries = restaurant.industries;
            if (!restaurant.offices || !restaurant.offices[0]) {
                return;
            }
            let office = restaurant.offices[0];
            this.showOfficeForm = true;
            this.office.address = office.address.address;
            this.office.city = office.address.city;
            this.office.zip = office.address.zip;
            this.office.state = office.address.state;
            this.office.url = office.url;
            this.office.phone = office.phone;
        }
    },
beforeMount() {
    this.isMobile = this.$store.getters.isRNAppSession;
  },
    mounted() {
        this.syncData()
        this.$store.commit('changePageData', {title: 'Account information'});
        this.tz = moment.tz.guess();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getPositions);
        }
        axios
            .get('/data/industries')
            .then((response) => {
                this.industries = response.data;
            });
    }
}
</script>