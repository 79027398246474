<template>
</template>

<script>

export default {
    name: 'Logout',
    methods: {
        logout() {
            let loader = this.$loading.show();
            this.$store.dispatch("logout").then(() => {
                setTimeout(500);
                this.$router.push({ name: "home" });
            }).finally(() => {loader.hide();});
        }
    },
    mounted() {
        this.logout();
    }
}
</script>
