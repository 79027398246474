export const Offer = {
    data() {
        return {
            offer: null,
            now: null
        }
    },
    computed: {
        formattedStartDate() {
            return moment(this.offer.period.start).format('dddd, MMMM Do');
        },
        shiftStartsInMinutes() {
            return moment(this.offer.period.start).diff(this.now, 'minutes');
        },
        shiftStartsInHours() {
            return moment(this.offer.period.start).diff(this.now, 'hours', true);
        },
        shiftEndsInHours() {
            return moment(this.offer.period.end).diff(this.now, 'hours', true);
        },
        shiftEndsInMinutes() {
            return moment(this.offer.period.end).diff(this.now, 'minutes', true);
        },
        isMatchedStatus() {
            return this.offer.status === 'matched';
        },
        formattedLeftToStart() {
            let time = this.shiftStartsInMinutes;
            if (time < 0) {
                return 'started';
            }
            let date = new Date(time * 60 * 1000);
            return (date.getUTCDay() - 4 ? (date.getUTCDay() - 4) + 'd ' : '') + date.getUTCHours() + 'h ' + date.getUTCMinutes() + 'm';
        },
        formattedLeftToEnd() {
            let date = new Date(this.offer.period.length_minutes * 60 * 1000); //if ended
            if (!this.isEnded) {
                let time = this.offer.period.length_minutes - this.shiftEndsInMinutes;
                date = new Date(time * 60 * 1000);
            }
            return date.getUTCHours() + 'h ' + date.getUTCMinutes() + 'm';
        },
        formattedBreak() {
            if (this.isEnded) {
                return '0h 0m';
            }
            let date = new Date(this.shiftEndsInMinutes * 60 * 1000);
            return date.getUTCHours() + 'h ' + date.getUTCMinutes() + 'm';
        },
        isEnded() {
            return this.shiftEndsInHours <= 0;
        },
        isStarted() {
            return this.shiftStartsInHours < 0;
        }
    },
    methods: {
        getOffer() {
            return new Promise((resolve, reject) => {
                let loader = this.$loading.show();
                axios
                    .get('/shift/offer/' + this.id + '/view')
                    .then((response) => {
                        this.offer = response.data;
                        resolve();
                    })
                    .catch(() => {
                        this.$router.push({'name': 'staff.shift.map'});
                        reject();
                    })
                    .finally(() => {loader.hide()});
            });
        },
    },
    mounted() {
        this.now = moment();
    }
};